import { STATUS } from '../../constants/commonConstants';
import { getMyEquipmentVars } from '../../services/windowService';

const {
  showMyEquipmentWidget,
  isFilteredByEquipment,
  refreshPageAfterSelectEquipment,
  userId,
  userType
} = getMyEquipmentVars();

export const myequipmentInitialStore = {
  storeId: '',
  mccResponse: false,
  showMyEquipmentWidget,
  fitmentCheckStatus: STATUS.IDLE,
  isFilteredByEquipment,
  refreshPageAfterSelectEquipment,
  userId,
  userType,
  isLoading: false,
  initialLoading: true,
  error: null,
  equipments: {
    myEquipmentList: [],
    selectedEquipment: {}
  },
  filters: {
    text: '',
    sortBy: 'modelequipmentFamily',
    order: 'asc',
    itemsPerPage: 12,
    total: 1200,
    currentPage: 1
  },
  equipmentSaveSuccessful: false,
  serialNumValidationStatus: STATUS.IDLE,
  serialNumberError: '',
  equipmentSelectionStatus: {},
  serviceRecommendationCards: {},
  serviceRecommendationPrice: {},
  serviceRecommendationSubTotal: {},
  serviceRecommendationDetails: {}
};
