import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import {
  Drawer,
  DealerLocatorHeader,
  DealerLocatorForm,
  DealerLocatorSearchResults,
  DealerLocatorSearchResult,
  DealerLocatorStoreContactInfo,
  DealerLocatorServicesOffered,
  DealerLocatorStoreHoursInfo,
  DrawerBody,
  DrawerFooter
} from '@cat-ecom/pcc-components';
import { useMemo } from 'react';
import { normalizeUrl, replaceTokensInString } from '@app/utils';
import endpoints from '@app/constants/endpoints';
import { useSelector } from 'react-redux';
import Conditional from '../Conditional';
import { getFilteredAndOrderedDealerStores } from '@app/components/dealer-locator-react/utils';
import { CatButton } from 'blocks-react/bedrock/components/Button';
import styles from './DealerOtherBranchesDrawer.scss';

const DealerOtherBranchesModal = ({ drawer }) => {
  const [t] = useTranslation();

  const storeFormMethods = useForm();

  const storeId = useSelector(s => s.common?.storeId);
  const catalogId = useSelector(s => s.common?.catalogId);
  const langId = useSelector(s => s.common?.langId);
  const otherStores = useSelector(s => s.store?.otherStores);

  const returnContactUrl = url =>
    normalizeUrl(
      replaceTokensInString(
        `${url}${endpoints.CONTACT_US_DEALER}`,
        catalogId,
        langId,
        storeId
      )
    );

  const { filteredAndOrderedVisibleDealerStores } = useMemo(
    () =>
      getFilteredAndOrderedDealerStores(otherStores?.dealerStores?.slice(0, 5)), //Slicing First 5 array to display in the drawer
    [otherStores?.dealerStores]
  );

  const showStoresList = !!filteredAndOrderedVisibleDealerStores[0];

  return (
    <FormProvider {...storeFormMethods}>
      <DealerLocatorForm>
        <Drawer
          className="color-black"
          {...drawer.drawerProps}
          disableBackdropClick={drawer.drawerProps.disableBackdropClick}
          hideCloseButton={drawer.drawerProps.hideCloseButton}
          hasBackdrop={drawer.drawerProps.hasBackdrop}
        >
          <DealerLocatorHeader
            title={t('AVAILABLE_AT_OTHER_LOCATIONS')}
          ></DealerLocatorHeader>

          <DrawerBody className={`${styles['drawer_body_class']}`}>
            <Conditional test={showStoresList}>
              <DealerLocatorSearchResults
                visibleStores={filteredAndOrderedVisibleDealerStores}
              >
                {({ store }) => {
                  const { id } = store;
                  return (
                    <DealerLocatorSearchResult
                      key={id}
                      unitOfMeasure={otherStores?.uom || 'mi'}
                      closestLabel={t('FYD_RESULTS_CLOSEST')}
                      store={store}
                    >
                      <div className="d-flex flex-wrap flex-column flex-lg-row px-1 py-2">
                        <DealerLocatorStoreContactInfo
                          contactInfoDrivingDirectionsLabel={t(
                            'DRIVING_DIRECTIONS'
                          )}
                          contactInfoEmailLabel={t('MA_EMAIL')}
                          contactInfoTitle={`${t('FOOTER_CUSTOMER_SERVICE')}:`}
                          contactInfoWebsiteLabel={t('CU_WEBSITE')}
                          returnContactUrl={returnContactUrl}
                          store={store}
                        />
                        <DealerLocatorServicesOffered
                          servicesOfferedTitle={`${t('SERVICES_OFFERED')}:`}
                          store={store}
                        />
                        <DealerLocatorStoreHoursInfo
                          store={store}
                          storeHoursOpen24HrsTitle={t(
                            'OPEN_24_HOURS_7_DAYS_A_WEEK'
                          )}
                          storeHoursTitle={`${t('STORE_HOURS')}:`}
                        />
                      </div>
                    </DealerLocatorSearchResult>
                  );
                }}
              </DealerLocatorSearchResults>
            </Conditional>
          </DrawerBody>
          <DrawerFooter>
            <CatButton type="button" variant="primary">
              {t('CAT_SELECT_STORE')}
            </CatButton>
            <span className="mx-3">
              <CatButton
                type="button"
                variant="default"
                onClick={drawer.handleClose}
              >
                {t('CANCEL')}
              </CatButton>
            </span>
          </DrawerFooter>
        </Drawer>
      </DealerLocatorForm>
    </FormProvider>
  );
};

DealerOtherBranchesModal.propTypes = {
  heading: PropTypes.string,
  drawer: PropTypes.shape({
    initiatorProps: PropTypes.shape({
      'aria-controls': PropTypes.string,
      'aria-expanded': PropTypes.bool,
      onClick: PropTypes.func
    }),
    drawerProps: PropTypes.shape({
      id: PropTypes.string,
      isActive: PropTypes.bool,
      onBlClose: PropTypes.func,
      hasBackdrop: PropTypes.bool,
      hideCloseButton: PropTypes.bool,
      disableBackdropClick: PropTypes.bool,
      align: PropTypes.string
    }),
    drawerControl: PropTypes.shape({
      isActive: PropTypes.bool,
      open: PropTypes.func,
      close: PropTypes.func
    }),
    closeDrawer: PropTypes.func,
    handleOpen: PropTypes.func,
    handleClose: PropTypes.func
  })
};

export { DealerOtherBranchesModal };
