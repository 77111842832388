import { useSelector } from 'react-redux';
import { CONTROL_CENTER_BLOCK_FLAG } from '@app/constants/commonConstants';
import { isDev, replaceTokensInUrl } from '@app/utils';
import links from '@app/constants/links';

function useControlCenterUrl() {
  const BFF_PREFIX = isDev()
    ? 'http://localhost:4200'
    : `${window.location.origin}`;

  const isControlCenterBlocksEnabled = useSelector(
    s => s.featureFlag?.[CONTROL_CENTER_BLOCK_FLAG]
  );
  const catalogId = useSelector(s => s.common?.catalogId);
  const storeId = useSelector(s => s.common?.storeId);
  const langId = useSelector(s => s.common?.langId);
  const esiteURL = useSelector(s => s.common?.esiteURL);
  const controlCenterBlocksUrl = `${BFF_PREFIX}${esiteURL}/account/control-center`;
  return isControlCenterBlocksEnabled
    ? controlCenterBlocksUrl
    : replaceTokensInUrl(links.CONTROL_CENTER_URL, catalogId, langId, storeId);
}

export default useControlCenterUrl;
