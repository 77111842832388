import PropTypes from 'prop-types';
import Conditional from '@app/components/common/Conditional/Conditional';
import { CatPrimaryNavItem } from 'blocks-react/bedrock/components/PrimaryNavItem/PrimaryNavItem.js';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { replaceTokensInString } from '@app/utils';
import links from '@app/constants/links';
import {
  AFFILIATION_DEALER,
  USER_TYPE_GUEST
} from '@app/constants/commonConstants';
import { useRouteMatch } from 'react-router-dom';
import { SHOPPING_CART_ROUTE } from '@app/constants/routes';
import { MAIN_NAV_TRACK } from '@app/constants/analyticsConstants';
import cx from 'classnames';
import styles from '../Subheader.modules.scss';

const QuickOrderWrapper = ({ isMobile = false }) => {
  const { t } = useTranslation();
  const isCurrentPage = useRouteMatch(SHOPPING_CART_ROUTE);

  const userType = useSelector(s => s.common?.userType);
  const storeId = useSelector(s => s.common?.storeId);
  const langId = useSelector(s => s.common?.langId);
  const catalogId = useSelector(s => s.common?.catalogId);
  const isCSRUser = useSelector(s => s.common?.isCatCSR);
  const userAffiliation = useSelector(s => s.common?.userAffiliation);

  const isDealerUser = userAffiliation === AFFILIATION_DEALER;

  const displayQuickOrder = useSelector(
    s => s.shoppingPreferences?.shoppingPreferencesData?.displayQuickOrder
  );

  // create quickOrder href
  const quickOrderHref = replaceTokensInString(
    links.SHOPPING_CART_LINK,
    storeId,
    langId,
    catalogId
  );

  // check if user is logged in
  const isLoggedIn = userType && userType !== USER_TYPE_GUEST;

  // create a const to determine if NavItem should be display
  const displayQuickOrderNavItem =
    isLoggedIn && (displayQuickOrder || isCSRUser || isDealerUser);
  const text = t('FOOTER_QUICK_ORDER');
  return (
    <Conditional test={displayQuickOrderNavItem}>
      <CatPrimaryNavItem
        data-testid="quickOrderNavItem"
        href={quickOrderHref}
        text={text}
        isCurrent={!!isCurrentPage}
        data-track-event={MAIN_NAV_TRACK.EVENT}
        data-track-attr-nltext={MAIN_NAV_TRACK.QUICK_ORDER_TEXT}
        data-track-attr-context={MAIN_NAV_TRACK.TOP_MENU_CONTEXT}
        className={cx({ [styles['item-border-bottom']]: isMobile })}
      />
    </Conditional>
  );
};

QuickOrderWrapper.propTypes = {
  isMobile: PropTypes.bool
};

export default QuickOrderWrapper;
