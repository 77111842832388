import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './CSRBannerBlocksWhite.module.scss';

const CSRBannerStripWhite = ({ children }) => {
  return (
    <>
      <div className={cx(styles['CSRBannerStrip__hidden_height'])}></div>
      <div
        className={cx(
          'd-flex',
          'align-items-center',
          'justify-content-center',
          'py-2',
          styles['CSRBannerStrip']
        )}
        style={{ gap: '16px' }}
      >
        {children}
      </div>
    </>
  );
};

CSRBannerStripWhite.propTypes = {
  children: PropTypes.node
};

export default CSRBannerStripWhite;
