export const CATSAS_NOTIFICATION_PREFERENCES_eSpot_1 =
  'CATSAS_NOTIFICATION_PREFERENCES_eSpot-1';
export const CATSAS_NOTIFICATION_PREFERENCES_eSpot_2 =
  'CATSAS_NOTIFICATION_PREFERENCES_eSpot-2';

export const notificationPref = {
  IS_READY_FOR_PICKUP: 'isReadyForPickupNotificationPreferred',
  IS_READY_FOR_PICKUP_Web: 'isReadyForPickupNotificationPreferred_Web',
  IS_PARTIALLY_READY_FOR_PICKUP:
    'isReadyForPartiallyPickupNotificationPreferred',
  IS_PARTIALLY_READY_FOR_PICKUP_Web:
    'isReadyForPartiallyPickupNotificationPreferred_Web',
  IS_PICKED_UP: 'isPickedUpNotificationPreferred',
  IS_PICKED_UP_Web: 'isPickedUpNotificationPreferred_Web',
  IS_PARTIALLY_SHIPPED: 'isPartiallyShippedNotificationPreferred',
  IS_PARTIALLY_SHIPPED_Web: 'isPartiallyShippedNotificationPreferred_Web',
  IS_FULLY_SHIPPED: 'isFullyShippedNotificationPreferred',
  IS_FULLY_SHIPPED_Web: 'isFullyShippedNotificationPreferred_Web',
  IS_DELIVERED: 'isDeliveredNotificationPreferred',
  IS_DELIVERED_Web: 'isDeliveredNotificationPreferred_Web',
  IS_ORDER_PROCESSING: 'isOrdProcOrdProcessingPreferred',
  IS_ORDER_PROCESSING_Web: 'isOrdProcOrdProcessingPreferred_Web',
  ORDER_APPROVAL_LIMIT_RANGE: 'orderApprovalLimitRange',
  IS_WEEKLY_APPROVE_SUMMARY: 'isOrdApprWeeklyApprSummaryPreferred',
  IS_WEEKLY_APPROVE_SUMMARY_Web: 'isOrdApprWeeklyApprSummaryPreferred_Web',
  IS_ORDER_EXPIRED_APPROVAL_Web: 'isOrdApprOrderExpiredPreferred_Web',
  EXPIRING_SOON: 'isQuoteReminderExpiringSoonPreferred',
  EXPIRING_SOON_Web: 'isQuoteReminderExpiringSoonPreferred_Web',
  EXPIRIED: 'isQuoteReminderExpiredPreferred',
  EXPIRIED_Web: 'isQuoteReminderExpiredPreferred_Web',
  IS_ORDER_APPROVAL_REMINDER: 'isOrderApprovalReminderNotificationPreferred',
  IS_QUOTE_REMINDER: 'isQuoteReminderNotificationPreferred',
  IS_HOLD: 'isOrderOnHoldNotificationPreferred',
  IS_ORDER_EXPIRED_APPROVAL: 'isOrdApprOrderExpiredPreferred',
  IS_ORDER_PROBLEM: 'isOrderExceptionNotificationPreferred',
  IS_ORDER_PROBLEM_Web: 'isOrderExceptionNotificationPreferred_Web',
  EXPIRED_QUOTE: 'isQuoteReminderExpiredPreferred',
  EXPIRING_TODAY: 'isQuoteReminderExpiringTodayPreferred',
  EXPIRING_TODAY_Web: 'isQuoteReminderExpiringTodayPreferred_Web'
};

export const orderStatusUpdatesInputs = [
  notificationPref.IS_PARTIALLY_SHIPPED,
  notificationPref.IS_FULLY_SHIPPED,
  notificationPref.IS_DELIVERED,
  notificationPref.IS_READY_FOR_PICKUP,
  notificationPref.IS_PICKED_UP,
  notificationPref.IS_ORDER_PROBLEM,
  notificationPref.IS_PARTIALLY_READY_FOR_PICKUP
];

export const orderApprovalInputs = {
  ORDER_APPROVAL_LIMIT_RANGE: notificationPref.ORDER_APPROVAL_LIMIT_RANGE,
  IS_ORDER_APPROVAL_REMINDER: notificationPref.IS_ORDER_APPROVAL_REMINDER
};

export const baseInitialValues = {
  [notificationPref.IS_READY_FOR_PICKUP]: false,
  [notificationPref.IS_READY_FOR_PICKUP_Web]: false,
  [notificationPref.IS_PARTIALLY_READY_FOR_PICKUP]: false,
  [notificationPref.IS_PARTIALLY_READY_FOR_PICKUP_Web]: false,
  [notificationPref.IS_PICKED_UP]: false,
  [notificationPref.IS_PICKED_UP_Web]: false,
  [notificationPref.IS_PARTIALLY_SHIPPED]: false,
  [notificationPref.IS_PARTIALLY_SHIPPED_Web]: false,
  [notificationPref.IS_FULLY_SHIPPED]: false,
  [notificationPref.IS_FULLY_SHIPPED_Web]: false,
  [notificationPref.IS_DELIVERED]: false,
  [notificationPref.IS_DELIVERED_Web]: false,
  [notificationPref.IS_ORDER_PROBLEM]: false,
  [notificationPref.IS_ORDER_PROBLEM_Web]: false,
  [notificationPref.IS_ORDER_PROCESSING]: false,
  [notificationPref.IS_ORDER_PROCESSING_Web]: false,
  [notificationPref.ORDER_APPROVAL_LIMIT_RANGE]: '',
  [notificationPref.IS_WEEKLY_APPROVE_SUMMARY]: false,
  [notificationPref.IS_WEEKLY_APPROVE_SUMMARY_Web]: false,
  [notificationPref.EXPIRING_SOON]: false,
  [notificationPref.EXPIRING_SOON_Web]: false,
  [notificationPref.EXPIRING_TODAY_Web]: false,
  [notificationPref.EXPIRIED]: false,
  [notificationPref.EXPIRIED_Web]: false,
  [notificationPref.IS_ORDER_APPROVAL_REMINDER]: false,
  [notificationPref.IS_QUOTE_REMINDER]: false,
  [notificationPref.IS_ORDER_EXPIRED_APPROVAL_Web]: false
};

export const PREFERENCES_TYPE_HEADING = {
  CAT_SHOPPING_PREFERENCES_PICKUP: 'CAT_SHOPPING_PREFERENCES_PICKUP',
  DELIVERY: 'DELIVERY',
  SHIPPED_STATUS_ON: 'SHIPPED_STATUS_ON',
  ORDER_APPROVAL: 'NOTIFICATION_PREF_ORDER_APPROVAL_SUBHEADER',
  QUOTE_HEADER: 'NOTIFICATION_PREF_SUBHEADER'
};

export const PREFERENCES_TYPE_SUB_HEADING = {
  IS_READY_FOR_PICKUP: 'SHIPPED_STATUS_RP',
  IS_PICKED_UP: 'SHIPPED_STATUS_PU',
  IS_PARTIALLY_READY_FOR_PICKUP: 'PARTIALLY_READY_PICKUP',
  IS_PARTIALLY_SHIPPED: 'MO_OrderStatus_V',
  IS_FULLY_SHIPPED: 'SHIPPED_STATUS_FS',
  IS_DELIVERED: 'SHIPPED_STATUS_DL',
  IS_ORDER_CONFIRMATION: 'TITLE_ORDER_CONFIRMATION',
  IS_QUEUED_FOR_PROCESSING: 'QUEUED_FOR_PROCESSING',
  IS_QUEUED_ORDER_NOT_PROCESSED: 'QUEUED_ORDER_NOT_PROCESSED_HEADING',
  IS_QUEUED_ORDER_PROCESSING: 'QUEUED_ORDER_PROCESSING_HEADING',
  IS_CANCELLED: 'CANCELLED_STATE',
  IS_HOLD: 'SHIPPED_STATUS_OH',
  IS_ORDER_PROBLEM: 'ORDER_PROBLEM',
  IS_ORDER_PROCESSING: 'SHIPPED_STATUS_ON',
  IS_WEEKLY_APPROVE_SUMMARY: 'NOTIF_PREF_WEEKLY_APPROVAL_SUMMARY_TITLE',
  IS_SUBMIT_FOR_APPROVAL: 'SUBMITTED_FOR_APPROVAL',
  ORDER_APPROVED: 'ORDER_APPROVED',
  IS_ORDER_PLACED: 'SHIPPED_STATUS_OP',
  IS_ORDER_REJECTED_DESCRIPTION: 'ORG_ORDER_REJECTED',
  EXPIRING_TODAY: 'NOTIF_PREF_EXPIRING_TODAY_TITLE',
  EXPIRED_QUOTE: 'EXPIRED_STATE',
  IS_ORDER_DELETE_DESCRIPTION: 'CAT_ORD_STS_DELETED',
  IS_ORDER_EXPIRED_APPROVAL: 'NOTIF_PREF_EXPIRED_APPROVAL_TITLE',
  IS_TITLE_QUOTE_CONFIRMATION: 'TITLE_QUOTE_CONFIRMATION',
  EXPIRING_SOON: 'NOTIF_PREF_EXPIRING_SOON_TITLE'
};

export const PREFERENCES_TYPE_TEXT = {
  IS_READY_FOR_PICKUP: 'READY_FOR_PICKUP_PREF_DESCRIPTION',
  IS_PICKED_UP: 'PICKED_UP_PREF_DESCRIPTION',
  IS_PARTIALLY_READY_FOR_PICKUP: 'PARTIALLY_READY_PICKUP_PREF_DESCRIPTION',
  IS_PARTIALLY_SHIPPED: 'PARTIALLY_SHIPPED_PREF_DESCRIPTION',
  IS_FULLY_SHIPPED: 'FULLY_SHIPPED_PREF_DESCRIPTION',
  IS_DELIVERED: 'DELIVERED_PREF_DESCRIPTION',
  IS_ORDER_CONFIRMATION: 'ORDER_CONF_PREF_DESCRIPTION',
  IS_QUEUED_FOR_PROCESSING: 'QUEUED_FOR_PROC_PREF_DESCRIPTION',
  IS_QUEUED_ORDER_NOT_PROCESSED: 'QUEUED_ORDER_NOT_PROC_PREF_DESCRIPTION',
  IS_QUEUED_ORDER_PROCESSING: 'QUEUED_ORDER_PROC_PREF_DESCRIPTION',
  IS_CANCELLED: 'CANCELLED_PREF_DESCRIPTION',
  IS_HOLD: 'ON_HOLD_PREF_DESCRIPTION',
  IS_ORDER_PROBLEM: 'ORDER_PROBLEM_PREF_DESCRIPTION',
  IS_ORDER_PROCESSING: 'RELEASED_PREF_DESCRIPTION',
  IS_WEEKLY_APPROVE_SUMMARY: 'NOTIF_PREF_WEEKLY_APPROVAL_SUMMARY_DESCRIPTION',
  IS_SUBMIT_FOR_APPROVAL: 'NOTIF_PREF_SUBMITTED_FOR_APPROVAL_DESCRIPTION',
  ORDER_APPROVED: 'NOTIF_PREF_ORDER_APPROVED_DESCRIPTION',
  IS_ORDER_PLACED: 'NOTIF_PREF_ORDER_PLACED_DESCRIPTION',
  IS_ORDER_REJECTED_DESCRIPTION: 'NOTIF_PREF_ORDER_REJECTED_DESCRIPTION',
  EXPIRING_TODAY: 'NOTIF_PREF_EXPIRING_TODAY_DESCRIPTION',
  EXPIRED_QUOTE: 'NOTIF_PREF_EXPIRED_QUOTES_DESCRIPTION',
  IS_ORDER_DELETE_DESCRIPTION: 'NOTIF_PREF_ORDER_DELETED_DESCRIPTION',
  IS_ORDER_EXPIRED_DESCRIPTION: 'NOTIF_PREF_EXPIRED_APPROVAL_DESCRIPTION',
  IS_TITLE_QUOTE_CONFIRMATION: 'NOTIF_PREF_QUOTE_CONFIRMATION_DESCRIPTION',
  EXPIRING_SOON: 'NOTIF_PREF_QUOTES_REMINDER'
};
