import {
  GENERIC_USER_ID,
  USER_TYPE_GUEST
} from '@app/constants/commonConstants';
import { isEmpty } from '@app/utils';
import {
  catchDexieErrors,
  getExpireTime,
  getPriceAndAvailabilityKey
} from './utils';
import { db } from './db';

export const clearPriceAndAvailabilityCacheData = () =>
  catchDexieErrors(
    db.priceAndAvailability
      .where('expireTime')
      .below(new Date().getTime())
      .delete()
  );

/**
 * @param {string} userType - string about the user type in session.
 * @param {string} userId - string that identifies the user.
 * @param {string} dcn - string that identifies selectedCustomerNumber.
 * @param {string} endUseCode - string that identifies selectedEndUseCode.
 * @param {string} orderType - string that identifies selectedOrderType.
 * @param {string} storeId - string that identifies the store.
 * @param {string} langId - string that identifies the language.
 * @param {string} selectedStore - string that identifies the selected Store.
 * @param {Array} partList - array with the parts about to be cached.
 */
export async function getPriceAndAvailabilityCacheData({
  userType = '',
  userId = '',
  storeId = '',
  selectedStore = '',
  langId = '',
  dcn = '',
  endUseCode = '',
  orderType = '',
  partsList = []
}) {
  await clearPriceAndAvailabilityCacheData();

  const resolvedUserId =
    userType === USER_TYPE_GUEST ? GENERIC_USER_ID : userId;

  const priceAndAvailabilityArray = await catchDexieErrors(
    db.priceAndAvailability
      .where('key')
      .anyOf(
        partsList.map(part =>
          getPriceAndAvailabilityKey({
            userId: resolvedUserId,
            storeId,
            dcn,
            selectedStore,
            langId,
            endUseCode,
            orderType,
            part
          })
        )
      )
      .toArray()
  );

  return priceAndAvailabilityArray?.map(({ data }) => data) || [];
}

/**
 * @param {string} userType - string for the type of user in session.
 * @param {string} userId - string that identifies the user.
 * @param {string} storeId - string for the current storeId.
 * @param {string} langId - string for the current language Id.
 * @param {string} selectedStore - string that identifies the selected Store.
 * @param {string} dcn - string that identifies selectedCustomerNumber.
 * @param {string} endUseCode - string that identifies selectedEndUseCode.
 * @param {string} orderType - string that identifies selectedOrderType.
 * @param {Array} partsNotCached - array of objects with the parts we want to cache.
 * @param {Object} data - the data that is received after the request to the P&A endpoint is completed.
 */
export async function setPriceAndAvailabilityCacheData({
  userType = '',
  userId = '',
  storeId = '',
  selectedStore = '',
  langId = '',
  dcn = '',
  endUseCode = '',
  orderType = '',
  priceAndAvailability = []
}) {
  if (!isEmpty(priceAndAvailability)) {
    const resolvedUserId =
      userType === USER_TYPE_GUEST ? GENERIC_USER_ID : userId;

    const expireTime = getExpireTime();

    // Array to store in cache.
    const arrayCacheData = priceAndAvailability.map(data => ({
      key: getPriceAndAvailabilityKey({
        userId: resolvedUserId,
        storeId,
        dcn,
        selectedStore,
        langId,
        endUseCode,
        orderType,
        part: data
      }),
      data,
      expireTime
    }));

    await catchDexieErrors(db.priceAndAvailability.bulkPut(arrayCacheData));
  }
}
