import {
  KOREAN_CURRENCY,
  KOREAN_CURRENCY_SYMBOL
} from '@app/constants/commonConstants';
import {
  ALTERNATIVE_PAYMENT_VALUE,
  COL_COUNTRY_CODE,
  MX_COUNTRY_CODE,
  PER_COUNTRY_CODE
} from '../components/pages/summary-and-payment/commonConstants';

/**
 * a util function that validates if is an alternative payment
 * @param {string} billingMethod - that contains the billing method value
 * @returns {boolean} a boolean that determines the statement
 */
export const isAlternativePayment = billingMethod => {
  return billingMethod === ALTERNATIVE_PAYMENT_VALUE;
};

/**
 * a util function that indicates if is a mexican payment gateway
 * @param {string} countryCode - that contains the country code like "ARG", "MXN" and so on
 * @returns {boolean} boolean that determines the statement
 */
export const isMexicanPayment = countryCode => {
  return countryCode === MX_COUNTRY_CODE;
};

/**
 * a util function that indicates if is a peruvian payment gateway
 * @param {string} countryCode - that contains the country code like "ARG", "MXN" and so on
 * @returns {boolean} boolean that determines the statement
 */
export const isPeruPayment = countryCode => {
  return countryCode === PER_COUNTRY_CODE;
};

/**
 * a util function that indicates if is a colombian payment gateway
 * @param {string} countryCode - that contains the country code like "ARG", "MXN" and so on
 * @returns {boolean} boolean that determines the statement
 */
export const isColombianPayment = countryCode => {
  return countryCode === COL_COUNTRY_CODE;
};

export const shouldAddCurrencyInPrice = currency =>
  currency !== KOREAN_CURRENCY;

/**
 * a util function that removes the decimals for the given amount
 * Ex. 8,750.00원 => 8,750원
 * @param {string} amount string amount of the price
 * @returns {string} a formatted string amount without decimals
 */
export const removeDecimalsForWonCurrency = amount => {
  const lastIndexPosition = amount.lastIndexOf('.');
  const currencyData = amount[amount.length - 1];
  const amountWithoutDecimals = amount.slice(0, lastIndexPosition);

  if (lastIndexPosition === -1) {
    return amount;
  }

  if (amountWithoutDecimals.includes(currencyData)) {
    return amountWithoutDecimals;
  }

  return amountWithoutDecimals + currencyData;
};

/**
 * a util function that is currency is WON
 * @param {string} price string amount of the price
 * @param {string} currency string currency code like "USD", "EUR" and so on
 */

export const checkIsNotWON = (price, currency = '') => {
  return !!currency
    ? shouldAddCurrencyInPrice(currency)
    : !price?.includes(KOREAN_CURRENCY_SYMBOL);
};

/**
 * a util function that formats the displayed price for korean dealers
 * @param {string} price string amount of the price
 * @param {string} currency string currency code like "USD", "EUR" and so on
 * @param {?string} priceWrapperClasses classNames that wraps the price
 * @returns {string|React.Fragment} a formatted price string or a React.Fragment if
 * the wrapper classes were provided
 */
export const priceDisplayFormat = (
  price,
  currency = '',
  priceWrapperClasses = null,
  isNecessaryAddCurrency = true
) => {
  const isNotWON = checkIsNotWON(price, currency);

  if (priceWrapperClasses) {
    return isNotWON ? (
      <>
        <span className={priceWrapperClasses}>{price}</span>
        {isNecessaryAddCurrency && ` (${currency})`}
      </>
    ) : (
      <span className={priceWrapperClasses}>
        {removeDecimalsForWonCurrency(price)}
      </span>
    );
  }
  if (isNotWON) {
    return isNecessaryAddCurrency ? `${price} ${currency}` : price;
  } else {
    return `${removeDecimalsForWonCurrency(price)}`;
  }
};

/**
 * a util function that indicates if the country provided has
 * the alternative payment option enabled
 * @param {string} countryCode - that contains the country code like "ARG", "MXN" and so on
 * @returns {boolean} boolean that determines the statement
 */
const countriesWithAlternativePayment = new Set([
  MX_COUNTRY_CODE,
  PER_COUNTRY_CODE
]);
export const hasAlternativePaymentEnabled = countryCode => {
  return countriesWithAlternativePayment.has(countryCode);
};

/**
 * @typedef {Object} PendingPaymentTranslationObject
 * @property {string} message - Translation string for text
 * @property {string} buttonText - Translation string for button
 */

/**
 * a util function to get the translation strings for the provided country
 * @param {string} countryCode - that contains the country code like "ARG", "MXN" and so on
 * @returns {PendingPaymentTranslationObject} boolean that determines the statement
 */
const pendingPaymentTranslations = new Map([
  [
    MX_COUNTRY_CODE,
    {
      alertMessage: 'ALT_PAYMENT_ACTION_REQUIRED',
      modalDescription: 'COMPLETE_YOUR_PAYMENT_ALT_PAYMENT_DESC',
      buttonText: 'ALT_PAYMENT_CODE'
    }
  ],
  [
    PER_COUNTRY_CODE,
    {
      alertMessage: 'PAGOEFECTIVO_ACTION_REQUIRED',
      modalDescription: 'COMPLETE_YOUR_PAYMENT_PAGOEFECTIVO_DESC',
      buttonText: 'PAGOEFECTIVO_CODE'
    }
  ]
]);
export const getPendingPaymentTranslationsByCountry = countryCode => {
  return pendingPaymentTranslations.get(countryCode);
};

export const validateWonSymbol = (price, currency) => {
  const isWON = currency === KOREAN_CURRENCY;
  if (isWON) {
    return price?.includes(KOREAN_CURRENCY_SYMBOL)
      ? price
      : price + KOREAN_CURRENCY_SYMBOL;
  } else {
    return price;
  }
};
