import { STATUS } from '../../constants/commonConstants';
import * as types from './constants';
import { productsInitialStore } from './initialStore';

const productsReducer = (state = productsInitialStore, action) => {
  switch (action.type) {
    case types.PRODUCTS_GET_DETAILS_BEGIN: {
      return {
        ...state,
        getProductDetailsStatus: STATUS.PENDING,
        getProductDetailsError: undefined
      };
    }
    case types.PRODUCTS_GET_DETAILS_FAIL: {
      const { payload } = action;
      return {
        ...state,
        getProductDetailsStatus: STATUS.REJECTED,
        getProductDetailsError: payload
      };
    }
    case types.PRODUCTS_GET_DETAILS_SUCCESS: {
      const { payload } = action;
      const updatedPayload = payload.uniqueId
        ? {
            [payload.uniqueId]: payload
          }
        : payload;
      return {
        ...state,
        byId: { ...state.byId, ...updatedPayload },
        getProductDetailsStatus: STATUS.RESOLVED
      };
    }
    case types.KIT_PARTS_GET_DETAILS_BEGIN: {
      return {
        ...state,
        getKitPartsDetailsStatus: STATUS.PENDING,
        getKitPartsDetailsError: undefined
      };
    }
    case types.KIT_PARTS_GET_DETAILS_FAIL: {
      const { payload } = action;
      return {
        ...state,
        getKitPartsDetailsStatus: STATUS.REJECTED,
        getKitPartsDetailsError: payload
      };
    }
    case types.KIT_PARTS_GET_DETAILS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        kitPartsDetails: payload,
        getKitPartsDetailsStatus: STATUS.RESOLVED
      };
    }
    /*
     * We are saving packageQty as state below because of an issue with the backend service.
     * When we make the call to the price and availability service (P&A) that service
     * in turn makes a call to a dealer service to get details about P&A in addition to packageQty.
     * The dealer service does not have packageQty available as a separate field, so there is
     * currently a workaround for the backend wherein they call the dealer service with the
     * quantity and parse an error field for the packageQty. That error only occurs when the
     * quantity requested via P&A is not a multiple of the packageQty.
     *
     * So, for example requesting a quantity of 1 for a product 1R-1808 that has a packageQty of 12
     * will return the error allowing for P&A to parse the error and return packageQty: 12 to us,
     * but if we send over a quantity that is a multiple of 12, perhaps 24, P&A does not receive
     * the error and thus is not able to parse the packageQty, thus it returns to us an incorrect
     * packageQty of 1.
     *
     * The current solution is for the front-end to call P&A with an initial quantity of 1 for the
     * packageQty and to ignore subsequent packageQty's when figuring what to hand the QuantityPicker
     * component. We will hold packageQty within state for the product.
     */
    case types.PRODUCTS_SET_PRICE: {
      const { payload } = action;
      const { priceAndAvailability, namespace = 'priceAndAvailability' } =
        payload;

      return {
        ...state,
        [namespace]: {
          ...state[namespace],
          ...priceAndAvailability
        }
      };
    }
    case types.PRODUCTS_GET_PRICE_BEGIN: {
      return {
        ...state,
        getMarketingPriceStatus: STATUS.PENDING
      };
    }
    case types.PRODUCTS_GET_PRICE_SUCCESS: {
      return {
        ...state,
        getMarketingPriceStatus: STATUS.RESOLVED
      };
    }
    case types.PRODUCTS_GET_PRICE_FAIL: {
      return {
        ...state,
        getMarketingPriceStatus: STATUS.REJECTED
      };
    }
    case types.PRODUCTS_GET_URL_TOKEN_BEGIN: {
      return {
        ...state,
        getProductUrlTokenStatus: STATUS.PENDING,
        getProductUrlTokenError: undefined
      };
    }
    case types.PRODUCTS_GET_URL_TOKEN_FAIL: {
      const { payload } = action;
      return {
        ...state,
        getProductUrlTokenStatus: STATUS.REJECTED,
        getProductUrlTokenError: payload
      };
    }
    case types.PRODUCTS_GET_URL_TOKEN_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        byId: { ...state.byId, [payload.uniqueId]: payload },
        current: payload.uniqueId,
        getProductUrlTokenStatus: STATUS.RESOLVED
      };
    }
    case types.PRODUCTS_RESET: {
      return {
        ...state,
        current: undefined,
        getProductUrlTokenStatus: STATUS.IDLE,
        getProductDetailsStatus: STATUS.IDLE
      };
    }
    // This happens if the PDP_SEARCH_ROUTE is hit and we have a productId in the query string.
    // In this case, isMLP will always be false and we automatically set getProductUrlTokenStatus to resolved
    case types.PRODUCTS_SET_PDP_CURRENT: {
      const { payload } = action;
      return {
        ...state,
        byId: { ...state.byId, [payload.productId]: payload },
        current: payload.productId,
        getProductUrlTokenStatus: STATUS.RESOLVED
      };
    }
    case types.PRODUCTS_SET_PRODUCT_LIST: {
      const { payload } = action;

      return {
        ...state,
        byId: { ...payload.products }
      };
    }
    case types.PRODUCTS_UPDATE_PRODUCT_LIST: {
      const { payload } = action;

      return {
        ...state,
        byId: { ...state.byId, ...payload.products }
      };
    }
    case types.PRODUCTS_GET_RECENTLY_VIEWED_BEGIN: {
      return {
        ...state,
        productsRecentlyViewed: [],
        getProductRecentlyViewedStatus: STATUS.PENDING,
        getProductRecentlyViewedError: undefined
      };
    }
    case types.PRODUCTS_GET_RECENTLY_VIEWED_FAIL: {
      const { payload } = action;
      return {
        ...state,
        productsRecentlyViewed: [],
        getProductRecentlyViewedStatus: STATUS.REJECTED,
        getProductRecentlyViewedError: payload
      };
    }
    case types.PRODUCTS_GET_RECENTLY_VIEWED_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        byId: { ...state.byId, ...payload.productsList },
        productsRecentlyViewed: payload.productsRecentlyViewed,
        getProductRecentlyViewedStatus: STATUS.RESOLVED,
        viewMoreURL: payload.viewMoreURL,
        totalCount: payload.totalCount
      };
    }
    case types.GET_HOMEPAGE_PRODUCTS_BEGIN: {
      const { payload } = action;
      return {
        ...state,
        [payload.statusKey]: STATUS.PENDING,
        [payload.statusErrorKey]: undefined
      };
    }
    case types.GET_HOMEPAGE_PRODUCTS_FAIL: {
      const { payload } = action;
      return {
        ...state,
        [payload.statusKey]: STATUS.REJECTED,
        [payload.statusErrorKey]: payload.error
      };
    }
    case types.GET_HOMEPAGE_PRODUCTS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        byId: { ...state.byId, ...payload.productsList },
        [payload.statusKey]: STATUS.RESOLVED,
        [payload.statusErrorKey]: undefined
      };
    }
    case types.PRODUCTS_LIST_RESET: {
      const { partNumbers } = action.payload;
      const newById = state.byId;
      partNumbers?.split(',').forEach(part => {
        if (newById[part]) {
          delete newById[part];
        }
      });
      return {
        ...state,
        byId: newById
      };
    }
    case types.SAVED_PRICE_ORIGINAL_STATE: {
      return {
        ...state,
        priceAndAvailabilityOriginalStatus: {
          ...state.priceAndAvailability
        }
      };
    }
    case types.RESET_PRICE_AND_AVAILABILITY: {
      let newState = {
        ...state,
        priceAndAvailability: {
          ...state.priceAndAvailabilityOriginalStatus
        }
      };
      delete newState.priceAndAvailabilityOriginalStatus;
      return newState;
    }
    case types.FBT_TOTAL_PRICE_BEGIN:
      return {
        ...state,
        getFbtTotalStatus: STATUS.PENDING
      };
    case types.FBT_TOTAL_PRICE_SUCCESS:
      return {
        ...state,
        fbtTotal: action.payload,
        getFbtTotalStatus: STATUS.RESOLVED
      };
    case types.FBT_TOTAL_PRICE_FAIL:
      return {
        ...state,
        getFbtTotalStatus: STATUS.REJECTED,
        fbtTotalError: action.payload
      };
    case types.GET_FBT_ITEMS_BEGIN:
      return {
        ...state,
        getFbtItemStatus: STATUS.PENDING
      };
    case types.GET_FBT_ITEMS_SUCCESS:
      return {
        ...state,
        fbtItems: action.payload,
        getFbtItemStatus: STATUS.RESOLVED
      };
    case types.GET_FBT_ITEMS_FAIL:
      return {
        ...state,
        getFbtItemStatus: STATUS.REJECTED,
        fbtError: action.payload,
        fbtItems: []
      };
    case types.SUMMARY_ITEMS_BEGIN: {
      return {
        ...state,
        getSummaryItemsStatus: STATUS.PENDING,
        getSummaryItemsError: undefined
      };
    }
    case types.SUMMARY_ITEMS_SUCCESS: {
      return {
        ...state,
        getSummaryItemsStatus: STATUS.RESOLVED
      };
    }
    case types.SUMMARY_ITEMS_FAIL: {
      const { payload } = action;
      return {
        ...state,
        getSummaryItemsStatus: STATUS.REJECTED,
        getSummaryItemsError: payload
      };
    }
    case types.GET_JOBS_RECOMMENDATIONS_BEGIN:
      return {
        ...state,
        getJobsRecommendationsStatus: STATUS.PENDING
      };
    case types.GET_JOBS_RECOMMENDATIONS_SUCCESS:
      return {
        ...state,
        jobsRecommendations: action.payload,
        getJobsRecommendationsStatus: STATUS.RESOLVED
      };
    case types.GET_JOBS_RECOMMENDATIONS_FAIL:
      return {
        ...state,
        getJobsRecommendationsStatus: STATUS.REJECTED,
        jobsRecommendationsError: action.payload
      };
    default: {
      return state;
    }
  }
};

export { productsInitialStore };
export default productsReducer;
