import styles from '../EquipmentActions.module.scss';
import PropTypes from 'prop-types';
import cx from 'classnames';

const EquipmentActionCard = ({
  children,
  disabled,
  handleClick,
  icon,
  classname
}) => {
  return (
    <button
      className={cx(styles['equipment-action-card__button'], classname)}
      disabled={disabled}
      onClick={handleClick}
    >
      <div className={cx(styles['equipment-action-card__button-icon'], 'p-3')}>
        {icon}
      </div>
      {children}
    </button>
  );
};

EquipmentActionCard.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
  classname: PropTypes.string
};

export default EquipmentActionCard;
