import addMinutes from 'date-fns/addMinutes';

export const INVALIDATE_CACHE_MINUTES =
  +sessionStorage.getItem('INVALIDATE_CACHE_MINUTES') || 60 * 24;

export const getExpireTime = () =>
  addMinutes(new Date(), INVALIDATE_CACHE_MINUTES).getTime();

const getAttributeKey = (prefix, value) => (value ? `_${prefix}${value}` : '');

export function getStoreInfoKey({
  storeId,
  selectedStore,
  dcn,
  endUseCode,
  orderType
}) {
  const storeKey = getAttributeKey('str', selectedStore);
  const dcnKey = getAttributeKey('dcn', dcn);
  const endUseCodeKey = getAttributeKey('euc', endUseCode);
  const orderTypeKey = getAttributeKey('ot', orderType);

  return `${storeId}${storeKey}${dcnKey}${endUseCodeKey}${orderTypeKey}`;
}

export function getPriceAndAvailabilityKey({
  userId,
  storeId,
  dcn,
  selectedStore,
  langId,
  endUseCode,
  orderType,
  part
}) {
  const { partNumber, quantity } = part;
  const storeInfoKey = getStoreInfoKey({
    storeId,
    selectedStore,
    dcn,
    endUseCode,
    orderType
  });

  return `${partNumber}_${quantity}_${userId}_${langId}_${storeInfoKey}`;
}

/**
 * Utility to catch any errors with some Dexie methods like get, put or delete
 *    This need to be used in all cache functions that use dexie db in order to prevent
 *    uncaught errors in the page so the cache will be skipped without interrupt user flow.
 * @param {Promise} method Dexie method
 * @returns Dexie method with catch
 */
export const catchDexieErrors = method => {
  try {
    return method
      .catch('TransactionInactiveError', e => {
        // Failed with TransactionInactiveError
        console.error('[indexedDB] TransactionInactive error: ' + e.message);
      })
      .catch(Error, e => {
        // Any other error derived from standard Error
        console.error('[indexedDB] Error: ' + e.message);
      })
      .catch(e => {
        // Other error such as a string was thrown
        console.error('[indexedDB] Error: ' + e.message);
      });
  } catch (error) {
    // Other Generic errors
    console.error('[indexedDB] Error:', error);
  }
};
