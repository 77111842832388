import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { replaceTokensInString, replaceTokensInUrl } from '@app/utils';
import {
  LEGACY_AJAX_LOGON_FORM_ROUTE,
  CSR_LANDING_PAGE_ROUTE,
  MY_ACCOUNT_SEARCH
} from '@app/constants/routes';
import useControlCenterUrl from '@app/hooks/useControlCenterUrl';
import links, { headlessLinks } from '@app/constants/links';
import { CUSTOMER_CARE_LABEL } from '@app/components/header/constants';
import useCVRLabels from '../useCVRLabels';

const useMyAccountLinks = () => {
  const [t] = useTranslation();

  const isNotificationPreferenceHeadlessFlag = useSelector(
    s => s.featureFlag?.PCCHeadlessNotificationPreferencesFlag
  );
  const isAddressBookHeadlessFlag = useSelector(
    s => s?.featureFlag?.PCCHeadlessAddressBookFeature
  );
  const controlCenterUrl = useControlCenterUrl();
  const catalogId = useSelector(s => s.common?.catalogId);
  const storeId = useSelector(s => s.common?.storeId);
  const langId = useSelector(s => s.common?.langId);
  const seoURL = useSelector(s => s.dealer?.seoURL);
  const esiteURL = useSelector(s => s.common?.esiteURL);
  const ordersPendingToApproveNumber = useSelector(
    state =>
      state?.ordersToApprove?.ordersProperties?.ordersPendingToApproveNumber
  );
  const isHeadlessOrderStatusEnabled = useSelector(
    state => state.featureFlag?.PCCHeadlessOrderStatusPage
  );

  const { pageHeader } = useCVRLabels({ top: '0.2em', fontSize: '1.5em' });

  const ordersPendingToApproveNumberText = isNaN(ordersPendingToApproveNumber)
    ? ''
    : `(${ordersPendingToApproveNumber})`;

  const staticLinks = useMemo(
    () => ({
      HOME: {
        route: seoURL || '/',
        label: t('BCT_HOME'),
        url: seoURL || '/'
      },
      MY_ACCOUNT: {
        route: LEGACY_AJAX_LOGON_FORM_ROUTE,
        label: t('SUMMARY'),
        url: replaceTokensInUrl(
          links.MA_SUMMARY_URL,
          catalogId,
          langId,
          storeId
        )
      },
      CONTROL_CENTER: {
        route: MY_ACCOUNT_SEARCH.CONTROL_CENTER,
        label: t('CONTROL_CENTER'),
        url: controlCenterUrl
      },
      CUSTOMER_CARE: {
        route: CSR_LANDING_PAGE_ROUTE,
        label: CUSTOMER_CARE_LABEL,
        url: replaceTokensInUrl(
          links.CSR_LANDING_PAGE,
          catalogId,
          langId,
          storeId
        )
      },
      PERSONAL_INFO: {
        route: MY_ACCOUNT_SEARCH.PERSONAL_INFORMATION,
        label: t('PERSONAL_INFO'),
        url: replaceTokensInUrl(
          links.PERSONAL_INFORMATION_LINK,
          catalogId,
          langId,
          storeId
        )
      },
      SHOPPING_PREFERENCES: {
        route: MY_ACCOUNT_SEARCH.PREFERENCES,
        label: t('CAT_SHOPPING_PREFERENCES_TITLE'),
        url: replaceTokensInUrl(
          links.SHOPPING_PREFERENCES_URL,
          catalogId,
          storeId,
          langId
        )
      },
      NOTIFICATION_PREFERENCES: {
        route: MY_ACCOUNT_SEARCH.NOTIFICATION_PREFERENCES,
        label: t('CAT_NOTIFICATION_PREFERENCES_TITLE'),
        url: isNotificationPreferenceHeadlessFlag
          ? replaceTokensInString(
              links.NOTIFICATION_PREFERENCES_HEADLESS,
              esiteURL
            )
          : replaceTokensInUrl(
              links.NOTIFICATION_PREFERENCES_URL,
              catalogId,
              langId,
              storeId
            )
      },
      MY_EQUIPMENT: {
        route: MY_ACCOUNT_SEARCH.EQUIPMENT,
        label: t('CAT_SHOPPING_PREFERENCES_MY_EQUIPMENT'),
        url: replaceTokensInUrl(
          links.SIS_EQUIP_LIST_URL,
          catalogId,
          langId,
          storeId
        )
      },
      ADDRESS_BOOK: {
        route: MY_ACCOUNT_SEARCH.ADDRESS_BOOK,
        label: t('MA_MYADDRESS_BOOK'),
        url: isAddressBookHeadlessFlag
          ? replaceTokensInString(links.HEADLESS_ADDRESS_BOOK, esiteURL)
          : replaceTokensInUrl(links.ADDRESS_BOOK, catalogId, langId, storeId)
      },
      PAYMENT_METHODS: {
        route: MY_ACCOUNT_SEARCH.PAYMENT,
        label: t('STORED_PAYMENT_METHODS'),
        url: replaceTokensInUrl(
          links.PAYMENT_METHODS_URL,
          catalogId,
          langId,
          storeId
        )
      },
      CAT_VANTAGE_AWARDS: {
        route: MY_ACCOUNT_SEARCH.CAT_VANTAGE_REWARDS,
        label: pageHeader,
        url: replaceTokensInUrl(
          links.CAT_VANTAGE_REWARDS_URL,
          catalogId,
          langId,
          storeId
        )
      },
      ORDER_HISTORY: {
        route: MY_ACCOUNT_SEARCH.ORDERS,
        label: t('MA_ORDER_HISTORY'),
        url: replaceTokensInUrl(
          links.ORDER_HISTORY_URL,
          catalogId,
          langId,
          storeId
        )
      },
      ORDERS_TO_APPROVE: {
        route: MY_ACCOUNT_SEARCH.ORDERS_TO_APPROVE,
        label: `${t(
          'MYACCOUNT_ORDER_APPROVAL'
        )} ${ordersPendingToApproveNumberText}`,
        url: replaceTokensInUrl(
          links.ORDERS_TO_APPROVE_URL,
          catalogId,
          langId,
          storeId
        )
      },
      SAVED_LIST: {
        route: MY_ACCOUNT_SEARCH.LISTS,
        label: t('SAVED_LISTS'),
        url: replaceTokensInUrl(
          links.SAVED_LISTS_URL,
          catalogId,
          langId,
          storeId
        )
      },
      PENDING_QUOTES: {
        route: MY_ACCOUNT_SEARCH.PENDINGQUOTES,
        label: t('QUOTES'),
        url: replaceTokensInUrl(
          links.PENDING_QUOTES_URL,
          catalogId,
          langId,
          storeId
        )
      },
      CORE_TRACKING: {
        route: MY_ACCOUNT_SEARCH.CORE_TRACKING,
        label: t('CAT_CORE_TRACKING'),
        url: replaceTokensInUrl(
          links.CORE_TRACKING_URL,
          catalogId,
          langId,
          storeId
        )
      },
      ORGANIZATIONS_AND_USERS: {
        route: MY_ACCOUNT_SEARCH.ORGANIZATIONS_AND_USERS,
        label: t('ORGANIZATIONMANAGE_ORGS_AND_USERS'),
        url: replaceTokensInUrl(
          links.ORGANIZATIONS_AND_USERS,
          catalogId,
          langId,
          storeId
        )
      },
      INVOICES: {
        isLegacy: false,
        route: MY_ACCOUNT_SEARCH.INVOICES,
        label: t('INVOICE_MENU'),
        url: replaceTokensInUrl(links.INVOICES_URL, catalogId, langId, storeId)
      }
    }),
    [
      seoURL,
      catalogId,
      langId,
      storeId,
      controlCenterUrl,
      isNotificationPreferenceHeadlessFlag,
      esiteURL,
      pageHeader,
      ordersPendingToApproveNumberText
    ]
  );

  const dynamicLinks = useMemo(
    () => ({
      // Order History / Order Details
      ORDER_DETAIL: {
        route: MY_ACCOUNT_SEARCH.ORDER_DETAILS,
        getLabel: orderNumber =>
          `${t('2413_ORDER_SHIPMENT_ORDER_NUMBER')} ${orderNumber}`,
        getUrl: ({ dbsOrderId, orderId }) =>
          replaceTokensInUrl(
            links.CSR_ORDER_DETAIL_LINK,
            storeId,
            langId,
            orderId,
            dbsOrderId
          )
      },
      // Invoice List / Invoice Detail
      INVOICE_DETAIL: {
        isLegacy: false,
        route: MY_ACCOUNT_SEARCH.INVOICE_DETAILS,
        getLabel: invoiceNumber =>
          `${t('2413_INVOICE_NUMBER')} ${invoiceNumber}`,
        getUrl: ({ invoiceId }) =>
          replaceTokensInUrl(
            links.INVOICE_DETAIL_URL,
            catalogId,
            langId,
            storeId,
            invoiceId
          )
      },
      // Order History / Order Detail / Order Status
      ORDER_STATUS: {
        route: MY_ACCOUNT_SEARCH.ORDER_STATUS,
        getUrl: ({ orderId = '', dbsOrderId = '' }) =>
          isHeadlessOrderStatusEnabled
            ? replaceTokensInString(
                headlessLinks.HEADLESS_ORDER_STATUS_LINK,
                esiteURL,
                orderId
              )
            : replaceTokensInUrl(
                links.ORDER_STATUS_LINK,
                catalogId,
                langId,
                orderId,
                storeId,
                dbsOrderId
              )
      },
      // Order History / Order Details / Request Return
      RETURN_REQUEST: {
        route: MY_ACCOUNT_SEARCH.RETURN_FORM,
        getUrl: ({ orderId = '', dbsOrderId = '' }) =>
          replaceTokensInUrl(
            links.REQUEST_RETURN_URL,
            storeId,
            catalogId,
            langId,
            dbsOrderId,
            orderId
          )
      },
      // Saved List / Saved List Details
      SAVED_LIST_DETAIL: {
        route: MY_ACCOUNT_SEARCH.LIST_DETAILS,
        getUrl: ({ createdBy = '', name = '', orderId = '' }) =>
          replaceTokensInUrl(
            links.SAVED_LIST_DETAIL_URL,
            catalogId,
            langId,
            storeId,
            createdBy,
            orderId,
            name
          )
      },
      // Pending Quotes / Pending Quote Details
      PENDING_QUOTE_DETAIL: {
        route: MY_ACCOUNT_SEARCH.PENDING_QUOTE_DETAILS,
        getUrl: ({ orderId = '' }) =>
          replaceTokensInUrl(
            links.PENDING_QUOTES_DETAILS,
            catalogId,
            langId,
            storeId,
            orderId
          )
      },
      // Organization and users / User Details
      USER_DETAILS: {
        route: MY_ACCOUNT_SEARCH.ORGANIZATIONS_AND_USERS_DETAILS,
        getUrl: ({ userId = '' }) =>
          replaceTokensInUrl(
            links.MY_ACCOUNT_ORGANIZATION_USERS_LINK,
            catalogId,
            langId,
            storeId,
            userId
          )
      },
      PENDING_ORDER_DETAILS: {
        route: MY_ACCOUNT_SEARCH.PENDING_ORDER_DETAILS,
        getUrl: ({ orderId = '' }) =>
          replaceTokensInUrl(
            links.PENDING_ORDER_DETAILS,
            orderId,
            storeId,
            langId,
            catalogId
          )
      },
      SHOPPING_CART: {
        label: t('SHOPPINGCART_TITLE'),
        getUrl: ({ orderId }) =>
          `${replaceTokensInUrl(
            links.SHOPPING_CART_LINK,
            storeId,
            langId,
            catalogId
          )}&orderId=${orderId}`
      }
    }),
    [t, catalogId, langId, storeId]
  );

  return { staticLinks, dynamicLinks };
};

export default useMyAccountLinks;
