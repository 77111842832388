import * as types from './constants';
import endpoints from '../../constants/endpoints';
import { getHttpInstance } from '../../services/defaultHttpService';

import { isEmpty, replaceTokensInString } from '../../utils';
import { normalizeError } from '../exception/utils';
import { clearError, setError } from '../exception/actions';
import { processDealerStores } from '@app/components/dealer-locator/DealerLocatorModal/utils';
import {
  getDealerStoreInfo,
  setDealerStoreInfo
} from '@app/services/cacheService/dealerStoreInfo';
import { USER_TYPE_REGISTERED } from '@app/constants/commonConstants';
import { ERROR_DOMAIN, ERROR_PATH } from '../../constants/errorConstants';

export const getStoreLocationInfo =
  (errorInfo = {}, includeCutOff = false) =>
  (dispatch, getState) => {
    dispatch({ type: types.STORE_GET_INFO_BEGIN });
    const { langId, selectedStore, storeId } = getState().common;
    const http = getHttpInstance();
    const url = replaceTokensInString(
      endpoints.STORE_LOCATION_INFO,
      storeId,
      selectedStore,
      langId,
      includeCutOff
    );
    return http
      .get(url)
      .then(({ data: payload } = {}) => {
        dispatch({
          type: types.STORE_GET_INFO_SUCCESS,
          payload
        });
        dispatch(
          clearError(ERROR_DOMAIN.HEADER, ERROR_PATH.CURRENT_LOCATION_POPUP)
        );
      })
      .catch(error => {
        dispatch({
          type: types.STORE_GET_INFO_FAIL
        });
        const { domain, path } = errorInfo;
        if (domain && path) {
          dispatch(setError(domain, path, normalizeError(error)));
        }
        dispatch(
          setError(ERROR_DOMAIN.HEADER, ERROR_PATH.CURRENT_LOCATION_POPUP, {
            ...normalizeError(
              error,
              {
                severity: 'warning',
                message: 'UNKNOWN_ERROR_MESSAGE',
                title: undefined
              },
              true
            )
          })
        );
      });
  };
export const getOtherStoresInfo = stores => async (dispatch, getState) => {
  dispatch({ type: types.OTHER_STORES_INFO_BEGIN });
  const {
    langId,
    selectedStore,
    storeId,
    userId,
    selectedCustomerNumber,
    selectedEndUseCode,
    selectedOrderType,
    userType
  } = getState().common;
  const { dealerName, seoURL } = getState()?.dealer;
  const { latitude, longitude } = getState()?.store;
  const isLoggedInUser = userType === USER_TYPE_REGISTERED;
  let storeInfo = await getDealerStoreInfo({
    userId,
    langId,
    storeId,
    selectedStore,
    dcn: selectedCustomerNumber,
    endUseCode: selectedEndUseCode?.value,
    orderType: selectedOrderType?.value
  });
  const http = getHttpInstance();
  try {
    if (isEmpty(storeInfo)) {
      if (isLoggedInUser) {
        //Signed in User
        const locationData = [];
        for (const store of stores) {
          const locationId = store?.value;
          if (locationId !== selectedStore) {
            const url = replaceTokensInString(
              endpoints.STORE_LOCATION_INFO,
              storeId,
              locationId,
              langId,
              false
            );
            const { data: payload } = await http.get(url);
            locationData.push({
              ...payload,
              seoURL,
              dealerName,
              id: storeId + locationId,
              distance: 0.0 // hardcorded distance for now, future WCS will send a value for it.
            });
          }
        }
        storeInfo = processDealerStores({
          dealerStores: locationData
        });
      } else {
        //Guest User
        const url = replaceTokensInString(
          endpoints.GUEST_LOCATION_DEALER_INFORMATION,
          storeId,
          langId,
          latitude,
          longitude,
          false
        );
        const { data: payload } = await http.get(url);
        const dealerStores = payload?.dealerStores.filter(
          store =>
            store?.dealerStoreId === storeId &&
            store?.id !== storeId + selectedStore
        );
        storeInfo = processDealerStores({
          ...payload,
          dealerStores
        });
      }
      await setDealerStoreInfo({
        langId,
        userId,
        storeId,
        selectedStore,
        dcn: selectedCustomerNumber,
        storeInfo,
        endUseCode: selectedEndUseCode?.value,
        orderType: selectedOrderType?.value
      });
    }
    dispatch({
      type: types.OTHER_STORES_INFO_SUCCESS,
      payload: { otherStores: storeInfo }
    });
  } catch {
    dispatch({
      type: types.OTHER_STORES_INFO_FAIL
    });
  }
};
