import { getHttpInstance } from '../../services/defaultHttpService';
import endpoints from '../../constants/endpoints';
import { navigateToUrl } from '../../services/windowService';
import { format, parse } from 'date-fns';
import {
  getCookie,
  isEmpty,
  normalizeUrl,
  removeCookie,
  replaceTokensInString,
  replaceTokensInUrl,
  setSessionStorage
} from '../../utils';
import {
  ADDRESS_TYPE_SHIPPING,
  BILLING_METHOD_CAT_PREPAID_CERT,
  BILLING_METHOD_CAT_VANTAGE_REWARDS,
  BILLING_METHOD_NEW_CAT_CARD,
  BILLING_METHOD_SAVED_CAT_CARD,
  BILLING_METHOD_TYPE_PRIMARY,
  DISPLAY_DATE_US,
  HAS_RBD_UPDATED_IN_DB,
  LAST_FULFILLMENT_DATE_CHANGED,
  PAYMENT_GATEWAY_MIDTRANS,
  PAYMENT_GATEWAY_PAYURUSSIA,
  PAYMENT_GATEWAY_WESTPAC,
  STATUS,
  TIMEOUT_DOUBLED,
  TIMEOUT_EXTENDED,
  USER_TYPE_GUEST
} from '../../constants/commonConstants';
import {
  CAT_CREDIT_APPLIED_HEADER,
  CAT_CREDIT_EXPIRED_HEADER,
  CAT_CREDIT_VOID_FAILURE_ERROR,
  CERT_ERROR_MESSAGE,
  CERT_EXPIRED_ERROR,
  CERT_MISMATCH,
  CERT_MISMATCH_ERROR,
  CERT_NO_FUNDS_AVAILABLE,
  CERT_SAMECARD_ERROR,
  ERROR_CREDIT_CARD_FAILURE,
  ERROR_DBS_DOWN,
  ERROR_DOMAIN,
  ERROR_ONLINE_PAYMENT_FAILURE,
  ERROR_PATH,
  ERROR_PLACE_ORDER_FAILURE,
  ERROR_PLACE_ORDER_LOCKED_BY_CSR,
  NO_FUNDS_AVAILABLE,
  VOID_FAILURE_CAT_CREDIT_HEADER,
  UNKNOWNERROR,
  ERROR_DROPBOX_API_FAILURE,
  CVR_REWARDS_CERT_ERROR_MESSAGE,
  CVR_REWARDS_CERT_ERROR_MESSAGE_ERROR,
  INFO_SHOPPING_CART_CRITICAL_ERROR,
  UNKNOWN_ERROR_MESSAGE
} from '../../constants/errorConstants';
import {
  REQUEST_BY_DATE,
  SUMMARY_AND_PAYMENT
} from '@app/components/pages/checkout/PickUpAndDelivery/constants';
import { normalizeError } from '../exception/utils';
import { removeSessionStorage } from '@app/utils';
import {
  ADD_QO_TO_CART_BEGIN,
  ADD_QO_TO_CART_FAIL,
  ADD_QO_TO_CART_SUCCESS,
  ADD_TO_CART_MODIFICATIONS_RESET,
  ADD_TO_CART_RESET_PHASE,
  ADD_TO_CART_SET_PHASE,
  BUILD_SHARE_CART_LINK_BEGIN,
  BUILD_SHARE_CART_LINK_FAIL,
  BUILD_SHARE_CART_LINK_SUCCESS,
  CHECKOUT_ADD_TO_CART_FAIL,
  CHECKOUT_APPLY_PREPAID_CERTIFICATE_BEGIN,
  CHECKOUT_APPLY_PREPAID_CERTIFICATE_FAIL,
  CHECKOUT_APPLY_PREPAID_CERTIFICATE_SUCCESS,
  CHECKOUT_APPLY_PROMO_BEGIN,
  CHECKOUT_APPLY_PROMO_FAIL,
  CHECKOUT_APPLY_PROMO_SUCCESS,
  CHECKOUT_CALC_FREIGHT_BEGIN,
  CHECKOUT_CALC_FREIGHT_FAIL,
  CHECKOUT_CALC_FREIGHT_RESET,
  CHECKOUT_CALC_FREIGHT_SUCCESS,
  CHECKOUT_CART_FORM_SUBMIT_FAIL,
  CHECKOUT_CAT_CREDIT_TOOLTIP,
  CHECKOUT_CLEAR_PROMOTIONS,
  CHECKOUT_DELETE_PREPAID_CERTIFICATE_BEGIN,
  CHECKOUT_DELETE_PREPAID_CERTIFICATE_FAIL,
  CHECKOUT_GET_CAT_CARD_PROMOS_BEGIN,
  CHECKOUT_GET_CAT_CARD_PROMOS_SUCCESS,
  CHECKOUT_GET_CAT_CARD_PROMOS_FAIL,
  CHECKOUT_LOAD_CART_BEGIN,
  CHECKOUT_LOAD_CART_FAIL,
  CHECKOUT_LOAD_CART_SUCCESS,
  CHECKOUT_LOAD_PICKUP_DELIVERY_BEGIN,
  CHECKOUT_LOAD_PICKUP_DELIVERY_FAIL,
  CHECKOUT_LOAD_PICKUP_DELIVERY_SUCCESS,
  CHECKOUT_LOAD_REQUIRED_FIELDS_BEGIN,
  CHECKOUT_LOAD_REQUIRED_FIELDS_FAIL,
  CHECKOUT_LOAD_REQUIRED_FIELDS_SUCCESS,
  CHECKOUT_LOAD_SUMMARY_BEGIN,
  CHECKOUT_LOAD_SUMMARY_FAIL,
  CHECKOUT_LOAD_SUMMARY_SUCCESS,
  CHECKOUT_MIDTRANS_FINALIZE_TRANSACTION_BEGIN,
  CHECKOUT_MIDTRANS_FINALIZE_TRANSACTION_CANCEL,
  CHECKOUT_MIDTRANS_FINALIZE_TRANSACTION_FAIL,
  CHECKOUT_MIDTRANS_FINALIZE_TRANSACTION_SUCCESS,
  CHECKOUT_MIDTRANS_GET_TOKEN_BEGIN,
  CHECKOUT_MIDTRANS_GET_TOKEN_FAIL,
  CHECKOUT_MIDTRANS_GET_TOKEN_SUCCESS,
  CHECKOUT_PLACE_ORDER_BEGIN,
  CHECKOUT_PLACE_ORDER_END,
  CHECKOUT_PLACE_ORDER_FAIL,
  CHECKOUT_PLACE_ORDER_SUCCESS,
  CHECKOUT_REMOVE_PROMO_BEGIN,
  CHECKOUT_REMOVE_PROMO_FAIL,
  CHECKOUT_REMOVE_PROMO_SUCCESS,
  CHECKOUT_RESET,
  CHECKOUT_SAVE_ADDRESS_BEGIN,
  CHECKOUT_SAVE_ADDRESS_FAIL,
  CHECKOUT_SAVE_ADDRESS_SUCCESS,
  CHECKOUT_SAVE_PAYMENT_DETAILS_BEGIN,
  CHECKOUT_SAVE_PAYMENT_DETAILS_FAIL,
  CHECKOUT_SAVE_PAYMENT_DETAILS_SUCCESS,
  CHECKOUT_SAVE_PONUMBER_BEGIN,
  CHECKOUT_SAVE_PONUMBER_FAIL,
  CHECKOUT_SAVE_PONUMBER_SUCCESS,
  CHECKOUT_SELECT_CAT_CARD_PROMOTION,
  CHECKOUT_SET_ORDER_INFORMATION_BEGIN,
  CHECKOUT_SET_ORDER_INFORMATION_FAIL,
  CHECKOUT_SET_ORDER_INFORMATION_SUCCESS,
  CHECKOUT_SIS_TO_CART_FAIL,
  CHECKOUT_SUMMARY_SET_ADDITIONAL_BILLING_METHOD,
  CHECKOUT_SUMMARY_SET_BILLING_ADDRESS,
  CHECKOUT_SUMMARY_SET_BILLING_METHOD,
  CHECKOUT_VERIFY_ADDRESS_BEGIN,
  CHECKOUT_VERIFY_ADDRESS_FAIL,
  CHECKOUT_VERIFY_ADDRESS_SUCCESS,
  CHECKOUT_SUMMARY_SET_ALTERNATIVE_PAYMENT,
  DEFAULT_QUERY_PARAMETER,
  GET_DEALER_DCN_CUSTOMER_BEGIN,
  GET_DEALER_DCN_CUSTOMER_FAIL,
  GET_DEALER_DCN_CUSTOMER_SUCCESS,
  GET_DEALER_PRICE_AND_AVAILABILITY_BEGIN,
  GET_DEALER_PRICE_AND_AVAILABILITY_FAIL,
  GET_DEALER_PRICE_AND_AVAILABILITY_RESET,
  GET_DEALER_PRICE_AND_AVAILABILITY_SUCCESS,
  GET_SOS_DETAILS_BEGIN,
  GET_SOS_DETAILS_FAIL,
  GET_SOS_DETAILS_SUCCESS,
  INITIAL_RENDER,
  QUERY_PARAMETER_WITH_FILE_NAME,
  PICKUP_DELIVERY_EXPORT_COMPLIANCE_FAILS,
  PICKUP_DELIVERY_EXPORT_COMPLIANCE_SUCCESS,
  PICKUP_DELIVERY_REQUEST_BY_DATE_FAILS,
  PICKUP_DELIVERY_SAVE_ADDRESS_BEGIN,
  PICKUP_DELIVERY_SAVE_ADDRESS_FAILS,
  PICKUP_DELIVERY_SAVE_ADDRESS_SUCCESS,
  PICKUP_DELIVERY_SUBMIT_BEGIN,
  PICKUP_DELIVERY_SUBMIT_FAILS,
  PICKUP_DELIVERY_SUBMIT_SUCCESS,
  PIX_PAYMENT_STATUS_BEGIN,
  PIX_PAYMENT_STATUS_FAIL,
  PIX_PAYMENT_STATUS_SUCCESS,
  QUICK_ORDER_CHECKOUT,
  CHECK_SALESFORCE_ACCOUNT_BALANCE_BEGIN,
  CHECK_SALESFORCE_ACCOUNT_BALANCE_SUCCESS,
  CHECK_SALESFORCE_ACCOUNT_BALANCE_FAIL,
  CHECKOUT_APPLY_CAT_CREDITS_BEGIN,
  CHECKOUT_APPLY_CAT_CREDITS_SUCCESS,
  CHECKOUT_APPLY_CAT_CREDITS_FAIL,
  SAVE_CAT_CREDITS_ACCOUNT_GLOBAL_EXPANSION_CHECKBOX,
  SHARE_CONFIRMATION_EMAIL_BEGIN,
  SHARE_CONFIRMATION_EMAIL_FAIL,
  SHARE_CONFIRMATION_EMAIL_SUCCESS,
  CHECK_SALESFORCE_ACCOUNT_BALANCE_CLEAR,
  APPLY_CAT_VANTAGE_REWARDS_TO_ORDER_BEGIN,
  APPLY_CAT_VANTAGE_REWARDS_TO_ORDER_SUCCESS,
  APPLY_CAT_VANTAGE_REWARDS_TO_ORDER_FAIL,
  INITIAL_CART_ITEMS,
  SHOPPING_CART,
  IMPORT_MODAL_VISIBLE_CHECKOUT,
  CHECKOUT_GET_CAT_CARD_GLOBAL_ORDER_TOTAL_SUCCESS,
  CHECKOUT_GET_CAT_CARD_GLOBAL_ORDER_TOTAL_BEGIN,
  CHECKOUT_GET_CAT_CARD_GLOBAL_ORDER_TOTAL_FAIL,
  CAT_CREDITS_DELETE_ACCOUNT_SUCCESS,
  CAT_CREDITS_DELETE_ACCOUNT_FAIL,
  RESET_ADDITIONAL_BILLING_METHOD
} from './constants';
import {
  UPDATE_ORDER_REQUEST_BY_DATE,
  RESET_REPLACED_PARTS_INFO,
  SET_TOTAL_ITEMS_ADDED_TO_CART
} from '../orders/constants';
import { AFFILIATION_DEALER } from '@app/constants/commonConstants';
import { ADD_TO_CART_PHASES } from '../../components/pages/checkout/shopping-cart/ShoppingCartPage/constants';
import { setError, clearError } from '../exception/actions';
import {
  DROPBOX,
  PICKUP,
  SHOP_ONLINE
} from '../../components/pages/checkout/PickUpAndDelivery/constants';
import {
  PUDPayloadBuilder,
  arrangeAlternateReplacedOptions,
  arrangeSOSData,
  buildAddQOToCartBody,
  buildDealerPriceAndAvailabilityBody,
  buildDealerPriceAndAvailabilityWithExtraReplacementBody,
  createSOSServicePayload,
  filterUnmatchedElements,
  getAltParts,
  getSOSServicePayload,
  processDropBoxData,
  invalidErrorPartsHandler,
  itemsAddedToCartHelpers,
  normalizeEquipmentObject,
  orderItemsToObject,
  getRBDDateUpdate,
  formatCartLinkPayload,
  formatCartLinkParts
} from './utils';
import { removeLocalStorage } from '@app/utils/localStorageUtils';
import {
  updateSisFallbackCookie,
  setInvalidQuantityErrors
} from '@app/components/pages/checkout/shopping-cart/QuickOrder/utils';
import {
  getServiceResponseTime,
  gaTimeTracker
} from '@app/components/common/analytics/analyticsUtils';
import {
  SOURCE_OF_SUPPLY_API,
  STATUS_FAIL,
  STATUS_SUCCESS
} from '@app/constants/analyticsConstants';
import { getFulfillmentTypeFlag } from '@app/components/pages/checkout/RequestByDate/utils';
import { SHOPPING_CART_ROUTE } from '@app/constants/routes';
import { SIS_PARTS_LIST } from '@app/components/login/redirect/constants';

const {
  ADDRESS_SAVE,
  ADDRESS_VERIFICATION,
  APPLY_PREPAID_CERTIFICATE,
  CAT_CARD_PROMOTION,
  CAT_CARD_GLOBAL_ORDER_TOTAL,
  CHECKOUT_APPLY_PROMO,
  CHECKOUT_DELIVERY_OPTIONS,
  CHECKOUT_DROPBOX_OPTIONS,
  CHECKOUT_MIDTRANS_FINALIZE_PAYMENT,
  CHECKOUT_MIDTRANS_GETTOKEN,
  CHECKOUT_PICKUP_AND_DELIVERY_SAVE,
  CHECKOUT_PICKUP_OPTIONS,
  CHECKOUT_REMOVE_PROMO,
  CHECKOUT_SUMMARY_UPDATE_ORDER,
  CHECKOUT_SUPPORTED_PAYMENT_AND_BILLING_INFO,
  CHECKOUT_TAX_AND_FREIGHT,
  DEALER_CUSTOMER_DCN,
  DELETE_PREPAID_CERTIFICATE,
  ORDER_EXPORT_COMPLIANCE,
  PLACE_ORDER,
  REQUIRED_FIELDS,
  SHOPPING_CART_IMPORT_SUMMARY_MODAL,
  SUMMARY_AND_PAYMENT_PAGE,
  XORDER_PO_ATTACHMENT
} = endpoints;

const RESPONSE_FORMAT_JSON = 'json';
const REQUEST_TYPE_CREATE = 'create';
const ERR_CODE_DBS = '230';
const cancelMidtransTransaction = dispatch =>
  dispatch({
    type: CHECKOUT_MIDTRANS_FINALIZE_TRANSACTION_CANCEL
  });

const executeMidtransGetToken = ({
  orderId,
  paymentMethod,
  billingAddressId,
  langId,
  storeId
}) => {
  const endpointUrl = replaceTokensInUrl(CHECKOUT_MIDTRANS_GETTOKEN, storeId);
  const paymentGateway = PAYMENT_GATEWAY_MIDTRANS;
  return getHttpInstance(TIMEOUT_EXTENDED).post(endpointUrl, {
    orderId,
    paymentMethod,
    billingAddressId,
    paymentGateway,
    langId
  });
};

const executePlaceOrder = ({
  billingAddressId,
  catalogId,
  orderId,
  langId,
  paymentMethod,
  additionalPaymentMethod,
  paymentGateway,
  poNumber,
  quoteOrder,
  storeId,
  sendToQuoteEmailList,
  sendToQuote,
  selectedAlternativePayment,
  userId,
  isBuyOnBehalf = false,
  ...rest
}) => {
  const endpointUrl = replaceTokensInUrl(
    PLACE_ORDER,
    storeId,
    langId,
    quoteOrder
  );
  const newUrl = isBuyOnBehalf
    ? `${endpointUrl}&forUserId=${userId}`
    : endpointUrl;

  return getHttpInstance(TIMEOUT_EXTENDED).post(newUrl, {
    billingAddressId,
    catalogId,
    orderId,
    paymentMethod,
    additionalPaymentMethod,
    paymentGateway,
    poNumber,
    quoteOrder: quoteOrder ? 'true' : 'false',
    sendToQuoteEmailList,
    sendToQuote,
    vendor: selectedAlternativePayment?.value,
    ...rest
  });
};

const handleSavePoNumberSuccess = (
  { fileName, poNumber, poAttachment, purchaseOrderInfo },
  dispatch,
  successCallback,
  onPoSuccess,
  item,
  finalPOList
) => {
  const attachmentItemIndex = parseInt(item) + 1;
  if (
    successCallback &&
    (finalPOList.length === attachmentItemIndex || finalPOList.length === 0)
  ) {
    successCallback(poNumber, poAttachment, finalPOList);
  } else if (onPoSuccess) {
    onPoSuccess({
      poNumber: poNumber,
      poAttachmentName: fileName
    });
  }

  return dispatch({
    type: CHECKOUT_SAVE_PONUMBER_SUCCESS,
    payload: {
      poNumber,
      poAttachment,
      purchaseOrderInfo
    }
  });
};

const savePoAttachment = async (
  {
    langId,
    orderId,
    poAttachment,
    purchaseOrderInfo,
    poNumber,
    storeId,
    skipValidation = false
  },
  dispatch,
  successCb,
  onPoSuccess,
  finalPOList
) => {
  const isSkipPOAttachmentValidation = skipValidation;
  const http = getHttpInstance(TIMEOUT_EXTENDED);
  const poEndpoint = XORDER_PO_ATTACHMENT;
  const config = { headers: { 'content-type': 'application/octet-stream' } };
  const encodedPoNumber = encodeURIComponent(poNumber);

  let savePoSuccessResult;

  // make call to the endpoint when there is no attachment to delete or upload
  if (finalPOList.length === 0) {
    const queryParameters = replaceTokensInString(
      DEFAULT_QUERY_PARAMETER,
      orderId,
      storeId,
      langId,
      encodedPoNumber,
      isSkipPOAttachmentValidation
    );
    const url = replaceTokensInString(`${poEndpoint}?${queryParameters}`);
    try {
      await http.put(url);
      savePoSuccessResult = handleSavePoNumberSuccess(
        {
          poNumber,
          poAttachment,
          purchaseOrderInfo
        },
        dispatch,
        successCb,
        onPoSuccess,
        '',
        finalPOList
      );
    } catch (error) {
      throw new Error(error);
    }
  } else {
    for (let item in finalPOList) {
      const { file, fileName, attachmentRemovalFlag } = finalPOList[item] || {};
      const encodedFileName = encodeURIComponent(fileName);
      // If there's not a filename or a file, treat like a delete
      // Else if there is a file to update, add it
      if (!file && attachmentRemovalFlag) {
        const queryParametersWithEcodedFileName = replaceTokensInString(
          QUERY_PARAMETER_WITH_FILE_NAME,
          orderId,
          storeId,
          langId,
          encodedPoNumber,
          encodedFileName,
          isSkipPOAttachmentValidation
        );
        const url = replaceTokensInString(
          `${poEndpoint}?${queryParametersWithEcodedFileName}`
        );

        try {
          await http.delete(url);
          savePoSuccessResult = handleSavePoNumberSuccess(
            {
              fileName,
              poNumber,
              poAttachment,
              purchaseOrderInfo
            },
            dispatch,
            successCb,
            onPoSuccess,
            item,
            finalPOList
          );
        } catch (error) {
          /*
           When using Cat Card or Cat Credit the attachment is uploaded after you place 
           the order, therefore when the DELETE method is called the file does not exist 
           in the server so there is nothing to delete. The If statement checks for the
           '_NO_FILES_TO_DELETE_ERROR' to prevent an error from displaying when the user
           removes an attachment when paying with Cat Card or Cat Credit.
          */
          if (
            error.response.data.errors[0].errorKey ===
            '_NO_FILES_TO_DELETE_ERROR'
          ) {
            successCb(poNumber, poAttachment, finalPOList);
          } else {
            throw new Error(error);
          }
        }
      } else if (file) {
        const queryParametersWithEcodedFileName = replaceTokensInString(
          QUERY_PARAMETER_WITH_FILE_NAME,
          orderId,
          storeId,
          langId,
          encodedPoNumber,
          encodedFileName,
          isSkipPOAttachmentValidation
        );
        const url = replaceTokensInString(
          `${poEndpoint}?${queryParametersWithEcodedFileName}`
        );

        try {
          await http.put(url, file, config);
          savePoSuccessResult = handleSavePoNumberSuccess(
            {
              poNumber,
              poAttachment,
              purchaseOrderInfo
            },
            dispatch,
            successCb,
            onPoSuccess,
            item,
            finalPOList
          );
        } catch (error) {
          throw new Error(error);
        }
      } else {
        const queryParameters = replaceTokensInString(
          QUERY_PARAMETER_WITH_FILE_NAME,
          orderId,
          storeId,
          langId,
          encodedPoNumber,
          encodedFileName,
          isSkipPOAttachmentValidation
        );
        const url = replaceTokensInString(`${poEndpoint}?${queryParameters}`);

        try {
          await http.put(url);
          savePoSuccessResult = handleSavePoNumberSuccess(
            {
              poNumber,
              poAttachment,
              purchaseOrderInfo
            },
            dispatch,
            successCb,
            onPoSuccess,
            item,
            finalPOList
          );
        } catch (error) {
          throw new Error(error);
        }
      }
    }
  }
  return savePoSuccessResult;
};

const executeSavePoNumber = (
  {
    langId,
    orderId,
    poAttachment,
    purchaseOrderInfo,
    poNumber,
    storeId,
    skipValidation
  },
  dispatch,
  successCb,
  onPoSuccess,
  isDeleteOnly
) => {
  const { poAttachmentName } = purchaseOrderInfo;
  const poAttachmentItems = poAttachmentName ?? [];
  let finalPOList = [];
  if (isDeleteOnly) {
    finalPOList = poAttachment.map(item => ({
      ...item,
      attachmentRemovalFlag: true
    }));
  } else {
    const oldPoAttachment = poAttachmentItems.map(({ fileName, fileSize }) => ({
      fileName,
      fileSize,
      file: ''
    }));
    const addedPOList = filterUnmatchedElements(
      poAttachment,
      oldPoAttachment,
      'fileName'
    );
    const deletedPOList = filterUnmatchedElements(
      oldPoAttachment,
      poAttachment,
      'fileName'
    ).map(item => ({ ...item, attachmentRemovalFlag: true }));

    finalPOList =
      deletedPOList.length !== 0 || addedPOList.length !== 0
        ? [...deletedPOList, ...addedPOList]
        : poAttachment.map(item => ({
            ...item,
            file: ''
          }));
  }
  return savePoAttachment(
    {
      langId,
      orderId,
      poAttachment,
      purchaseOrderInfo,
      poNumber,
      storeId,
      skipValidation
    },
    dispatch,
    successCb,
    onPoSuccess,
    finalPOList
  );
};

const handleMidtransGetTokenFailure = (payload, dispatch) => {
  dispatch(
    setError(
      ERROR_DOMAIN.CHECKOUT,
      ERROR_PATH.MAIN,
      normalizeError(payload, ERROR_CREDIT_CARD_FAILURE)
    )
  );
  return dispatch({ type: CHECKOUT_MIDTRANS_GET_TOKEN_FAIL, payload });
};

const handleMidtransGetTokenSuccess = ({ token }, dispatch) => {
  return dispatch({
    type: CHECKOUT_MIDTRANS_GET_TOKEN_SUCCESS,
    payload: {
      token
    }
  });
};

const handlePlaceOrderFail = (payload, parsedError, dispatch, getState, t) => {
  let placeOrderFailExceptionError;

  const {
    featureFlag: { PCC_CatVantageRewardsEnhancementsPhase2 }
  } = getState();

  if (payload?.response?.data?.errors[0].errorCode === ERR_CODE_DBS) {
    placeOrderFailExceptionError = ERROR_DBS_DOWN;
  } else if (
    payload?.response?.data?.errors[0].errorKey ===
    'ONLINE_PAYMENT_FAILURE_HEADER'
  ) {
    placeOrderFailExceptionError = ERROR_ONLINE_PAYMENT_FAILURE;
  } else if (
    payload?.response?.data?.errors[0].errorKey === '_ERR_ORDER_IS_LOCKED'
  ) {
    placeOrderFailExceptionError = ERROR_PLACE_ORDER_LOCKED_BY_CSR;
  } else if (
    payload?.response?.data?.errors[0].errorKey ===
      'APPLIED_CREDITS_EXCEEDS_LIMIT_ERROR_MESSAGE' &&
    PCC_CatVantageRewardsEnhancementsPhase2
  ) {
    const {
      catVantage: {
        catVantageRewardsBalance: { catCreditsLimitAmount }
      },
      orders: { currencyNotation }
    } = getState();

    const message = replaceTokensInString(
      t('CAT_CREDITS_MAX_DOLLAR_NOTICE'),
      `${catCreditsLimitAmount} `,
      `${currencyNotation}`
    );

    placeOrderFailExceptionError = {
      title: 'ERROR_OCCURRED',
      message
    };
  } else {
    placeOrderFailExceptionError = parsedError;
  }
  dispatch(
    setError(
      ERROR_DOMAIN.CHECKOUT,
      ERROR_PATH.MAIN,
      normalizeError(payload, placeOrderFailExceptionError)
    )
  );
  return dispatch({ type: CHECKOUT_PLACE_ORDER_FAIL, payload });
};

const handlePlaceOrderSuccess = ({
  actionType: type,
  dispatch,
  payload,
  redirectUrl
}) => {
  dispatch({
    type,
    payload
  });
  removeLocalStorage(LAST_FULFILLMENT_DATE_CHANGED);
  removeLocalStorage(HAS_RBD_UPDATED_IN_DB);
  navigateToUrl(redirectUrl);
};

export const resetPlaceOrder = () => ({
  type: CHECKOUT_PLACE_ORDER_END
});

export const setCatCreditToolTip = isPrepaidCatCredit => ({
  type: CHECKOUT_CAT_CREDIT_TOOLTIP,
  isPrepaidCatCredit
});

const handleSavePoNumberFail = (error, dispatch, modalFlag) => {
  dispatch(
    setError(
      ERROR_DOMAIN.CHECKOUT,
      modalFlag ? ERROR_PATH.MODAL : ERROR_PATH.PURCHASEORDER,
      normalizeError(error)
    )
  );
  return dispatch({ type: CHECKOUT_SAVE_PONUMBER_FAIL, error });
};

const resolveAfter = timeout =>
  timeout
    ? new Promise(resolve => {
        setTimeout(resolve, timeout);
      })
    : Promise.resolve();

const validatePayuRussiaOrder = ([{ token, cvv, cvvUpdate }]) => {
  if (!cvv) {
    throw new Error('cvv is required');
  } else if (!cvvUpdate && !token) {
    // if cvvUpdate is false then we should have gotten a new token from payU, and token shoudln't be null
    throw new Error('token is required');
  }
};

const validateWestPacOrder = ([{ token, storedProfileId }]) => {
  if (!token && !storedProfileId) {
    throw new Error('token is required');
  }
};

const validatePaymentGateway = paymentGateway => {
  if (paymentGateway?.length > 0) {
    if (
      paymentGateway.find(
        gatewayObj => gatewayObj.name === PAYMENT_GATEWAY_PAYURUSSIA
      )
    ) {
      validatePayuRussiaOrder(paymentGateway);
    } else if (
      paymentGateway.find(
        gatewayObj => gatewayObj.name === PAYMENT_GATEWAY_WESTPAC
      )
    ) {
      validateWestPacOrder(paymentGateway);
    }
  }
};

export const getCheckoutRequiredFields =
  ({ errorInfo = {} }) =>
  (dispatch, getState) => {
    const { storeId, langId } = getState().common;
    const url = replaceTokensInUrl(
      REQUIRED_FIELDS,
      storeId,
      'checkout',
      langId
    );
    dispatch({ type: CHECKOUT_LOAD_REQUIRED_FIELDS_BEGIN });
    const http = getHttpInstance();
    return http
      .get(url)
      .then(({ data = {} }) => {
        dispatch({
          type: CHECKOUT_LOAD_REQUIRED_FIELDS_SUCCESS,
          payload: data.requiredFields || {}
        });
      })
      .catch(error => {
        const { domain, path } = errorInfo;
        if (domain && path) {
          dispatch(setError(domain, path, normalizeError(error)));
        } else {
          dispatch(
            setError(
              ERROR_DOMAIN.CHECKOUT,
              ERROR_PATH.ROOT,
              normalizeError(error)
            )
          );
        }
        dispatch({ type: CHECKOUT_LOAD_REQUIRED_FIELDS_FAIL });
      });
  };

export const getDropboxData = async ({ storeId, langId, dispatch }) => {
  const dropBoxUrl = replaceTokensInUrl(
    CHECKOUT_DROPBOX_OPTIONS,
    storeId,
    langId
  );
  const http = getHttpInstance();
  let dropbox = null;
  let isDropboxServiceFail = false;
  try {
    const { data } = await http.get(dropBoxUrl);
    const { address: addresses } = data;
    if (addresses && Array.isArray(addresses)) {
      dropbox = processDropBoxData(addresses, data);
    } else {
      dropbox = data;
    }
    // Dropbox could fail because AVI will take longer to return the address
    // but WCS will return a http 200
    if (!dropbox.address || (dropbox.address && !dropbox.address.length)) {
      dispatch(
        setError(
          ERROR_DOMAIN.CHECKOUT,
          ERROR_PATH.PUD_DROPBOX_SERVICE,
          ERROR_DROPBOX_API_FAILURE
        )
      );
      isDropboxServiceFail = true;
    }
  } catch (error) {
    isDropboxServiceFail = true;
    dispatch(
      setError(
        ERROR_DOMAIN.CHECKOUT,
        ERROR_PATH.PUD_DROPBOX_SERVICE,
        ERROR_DROPBOX_API_FAILURE
      )
    );
  }
  return { dropbox, isDropboxServiceFail };
};
const getDeliveryData = async ({ storeId, langId }) => {
  const http = getHttpInstance();
  const deliveryUrl = replaceTokensInUrl(
    CHECKOUT_DELIVERY_OPTIONS,
    storeId,
    langId
  );
  let delivery = null;
  let isDeliveryServiceFail = false;
  try {
    const { data } = await http.get(deliveryUrl);
    // setting phone1 fields - new phone field
    const addPhone1 = address => ({ ...address, phone1: address.phone });
    const newAddress =
      data.address && data.address.length ? data.address.map(addPhone1) : [];
    delivery = {
      ...data,
      address: newAddress
    };
  } catch (error) {
    isDeliveryServiceFail = true;
  }
  return { delivery, isDeliveryServiceFail };
};
const getPickupData = async ({ storeId, langId }) => {
  const http = getHttpInstance();
  const pickupUrl = replaceTokensInUrl(
    CHECKOUT_PICKUP_OPTIONS,
    storeId,
    langId
  );
  let pickup = null;
  let isPickupServiceFail = false;
  try {
    const { data } = await http.get(pickupUrl);
    pickup = data;
  } catch (error) {
    isPickupServiceFail = true;
  }
  return {
    pickup,
    isPickupServiceFail
  };
};

export const loadPickupAndDeliveryPage =
  ({
    isDeliveryEnabled,
    isPickupEnabled,
    isDropboxEnabled,
    availabilityMessage = '',
    isCallingFromCounter = false,
    fieldValueObj,
    showToasterFulFillment,
    pageType,
    isGuestAndDeliveryFullfilment
  } = {}) =>
  async (dispatch, getState) => {
    const { langId, storeId } = getState().common;
    let payload = {};
    payload.isCallingFromCounter = isCallingFromCounter;
    dispatch({ type: CHECKOUT_LOAD_PICKUP_DELIVERY_BEGIN, payload });
    try {
      if (isDropboxEnabled) {
        const { dropbox, isDropboxServiceFail } = await getDropboxData({
          storeId,
          langId,
          dispatch
        });
        payload[DROPBOX] = dropbox;
        payload.isDropboxServiceFail = isDropboxServiceFail;
        if (!isDropboxServiceFail && !!showToasterFulFillment) {
          getRBDDateUpdate(
            dropbox.estimatedDropBoxDate,
            fieldValueObj,
            showToasterFulFillment,
            availabilityMessage,
            dispatch,
            pageType
          );
        }
      }

      if (isPickupEnabled) {
        const { pickup, isPickupServiceFail } = await getPickupData({
          storeId,
          langId
        });
        payload[PICKUP] = pickup;

        payload.isPickupServiceFail = isPickupServiceFail;
      }

      if (isDeliveryEnabled) {
        const { delivery, isDeliveryServiceFail } = await getDeliveryData({
          storeId,
          langId
        });
        payload[SHOP_ONLINE] = delivery;
        payload.isDeliveryServiceFail = isDeliveryServiceFail;
        if (!isDeliveryServiceFail && !!showToasterFulFillment) {
          getRBDDateUpdate(
            delivery[
              isGuestAndDeliveryFullfilment ? 'guestShippingMethods' : 'methods'
            ].find(fnd => fnd.code === fieldValueObj.shipmentCode)
              .estimatedDeliveryDate,
            fieldValueObj,
            showToasterFulFillment,
            availabilityMessage,
            dispatch,
            pageType
          );
        }
      }
      if (
        payload.isDeliveryServiceFail &&
        payload.isPickupServiceFail &&
        payload.isDropboxServiceFail
      ) {
        dispatch({ type: CHECKOUT_LOAD_PICKUP_DELIVERY_FAIL });
        dispatch(
          setError(
            ERROR_DOMAIN.CHECKOUT,
            ERROR_PATH.PICKUP_DELIVERY_LOAD,
            UNKNOWNERROR
          )
        );
      } else {
        dispatch({
          type: CHECKOUT_LOAD_PICKUP_DELIVERY_SUCCESS,
          payload
        });
      }
    } catch (error) {
      dispatch({ type: CHECKOUT_LOAD_PICKUP_DELIVERY_FAIL });
      dispatch(
        setError(
          ERROR_DOMAIN.CHECKOUT,
          ERROR_PATH.PICKUP_DELIVERY_MAIN,
          normalizeError(error)
        )
      );
    }
  };

export const loadSummaryPage = initialOption => {
  const http = getHttpInstance();
  return async (dispatch, getState) => {
    dispatch({ type: CHECKOUT_LOAD_SUMMARY_BEGIN });
    const {
      common: { storeId, catalogId, langId },
      orders: { current: currentOrderId },
      dealer: { isDropboxEnabled }
    } = getState();

    const endpointUrl = replaceTokensInUrl(
      CHECKOUT_SUPPORTED_PAYMENT_AND_BILLING_INFO,
      storeId,
      langId,
      catalogId,
      currentOrderId
    );
    return http
      .get(endpointUrl)
      .then(paymentResponse => ({
        ...paymentResponse.data
      }))
      .then(payload => {
        const { supportedPaymentMethods } = payload;
        let defaultBillingMethod;
        if (supportedPaymentMethods.length === 1) {
          const [firstOption] = supportedPaymentMethods;
          defaultBillingMethod = firstOption;
        } else {
          defaultBillingMethod =
            supportedPaymentMethods.find(
              ({ isDefault }) => isDefault === true || isDefault === 'true'
            ) || {};
        }
        dispatch(clearError(ERROR_DOMAIN.CHECKOUT));
        dispatch({
          type: CHECKOUT_LOAD_SUMMARY_SUCCESS,
          payload: {
            ...payload,
            selectedBillingMethod: defaultBillingMethod,
            selectedAdditionalBillingMethod: initialOption,
            prepaidCertificates: { showToolTipStatus: '' }
          }
        });
      })
      .catch(payload => {
        dispatch({ type: CHECKOUT_LOAD_SUMMARY_FAIL });
        dispatch(
          setError(
            ERROR_DOMAIN.CHECKOUT,
            ERROR_PATH.MAIN,
            normalizeError(payload)
          )
        );
      });
  };
};

export const saveAddress = ({
  addAddressType,
  aviAddress = {},
  aviOptionSelected,
  errorPath,
  onFail,
  onSuccess
}) => {
  const http = getHttpInstance();
  return (dispatch, getState) => {
    dispatch({ type: CHECKOUT_SAVE_ADDRESS_BEGIN });

    const {
      common: {
        storeId,
        userAffiliation,
        userType,
        profileAddress: { addressId: profileAddressId }
      },
      checkout: {
        addresses: { options, new: newAddress }
      }
    } = getState();
    const isUserTypeGuest = userType === USER_TYPE_GUEST;
    const timestamp = new Date().getTime();
    const isDealerUser = userAffiliation === AFFILIATION_DEALER;
    if (isDealerUser) {
      newAddress.nickName = 'DACUSTBILLTO_' + timestamp;
    }
    const profileAddressNickName = options.find(
      ({ addressId }) => addressId === profileAddressId
    )?.nickName;
    const { nickName: existingNickname, addressId: existingAddressId } =
      newAddress;
    const isSelfAddressForNonDealer =
      !isUserTypeGuest &&
      !isDealerUser &&
      (profileAddressNickName === existingNickname ||
        profileAddressId === existingAddressId);
    if ((isSelfAddressForNonDealer || isDealerUser) && existingAddressId) {
      delete newAddress.addressId;
    }

    const existing =
      existingNickname && !isSelfAddressForNonDealer
        ? options.find(({ nickName }) => nickName === existingNickname)
        : {};

    const isEdit = !isEmpty(existing);

    const combined = {
      ...existing,
      ...newAddress,
      ...aviAddress,
      addressType: isEdit ? existing.addressType : addAddressType
    };

    const {
      address1 = '',
      address2 = '',
      addressType,
      email: email1 = '',
      phone: phone1 = '',
      ...restOfAddress
    } = combined;

    const endpointBase = replaceTokensInUrl(ADDRESS_SAVE, storeId);
    const endpointURL = isEdit
      ? `${endpointBase}/${encodeURIComponent(combined.nickName)}`
      : endpointBase;
    const method = isEdit ? 'put' : 'post';
    return http
      .request({
        url: endpointURL,
        method,
        data: {
          addressLine: [address1, address2],
          addressType,
          email1,
          phone1,
          ...restOfAddress
        }
      })
      .then(({ data }) => data)
      .then(({ addressId, userId }) => {
        if (onSuccess) {
          onSuccess(aviOptionSelected, isEdit);
        }
        removeCookie('mico');
        dispatch({
          type: CHECKOUT_SAVE_ADDRESS_SUCCESS,
          payload: {
            ...combined,
            addressId,
            userId
          }
        });
      })
      .catch(payload => {
        const error = normalizeError(payload);
        if (onFail) {
          onFail(error, isEdit);
        }
        dispatch({ type: CHECKOUT_SAVE_ADDRESS_FAIL, payload });
        dispatch(setError(ERROR_DOMAIN.CHECKOUT, errorPath, error));
      });
  };
};

export const savePaymentDetails = (orderId, enabled = false) => {
  const http = getHttpInstance();
  return (dispatch, getState) => {
    dispatch({ type: CHECKOUT_SAVE_PAYMENT_DETAILS_BEGIN });
    const {
      common: { storeId }
    } = getState();
    const endpointURL = replaceTokensInUrl(
      CHECKOUT_SUMMARY_UPDATE_ORDER,
      storeId
    );

    return http
      .request({
        url: endpointURL,
        method: 'put',
        params: {
          responseFormat: RESPONSE_FORMAT_JSON
        },
        data: {
          orderExtendAttribute: [
            {
              attributeName: 'saveCreditCard',
              attributeValue: enabled ? 'true' : 'false'
            }
          ],
          orderId
        }
      })
      .then(() =>
        dispatch({
          type: CHECKOUT_SAVE_PAYMENT_DETAILS_SUCCESS,
          payload: {
            enabled
          }
        })
      )
      .catch(payload =>
        dispatch({ type: CHECKOUT_SAVE_PAYMENT_DETAILS_FAIL, payload })
      );
  };
};

export const verifyAddress = ({
  address1 = '',
  address2 = '',
  city = '',
  country = '',
  state = '',
  zipCode = '',
  poNumber = '',
  poAttachment = '',
  onSuccess,
  onFail,
  ...rest
}) => {
  const http = getHttpInstance();
  return (dispatch, getState) => {
    const newAddress = { address1, address2, city, state, country, zipCode };

    dispatch({
      type: CHECKOUT_VERIFY_ADDRESS_BEGIN,
      payload: {
        ...newAddress,
        ...rest
      }
    });

    const {
      common: { storeId }
    } = getState();
    const endpointURL = replaceTokensInUrl(ADDRESS_VERIFICATION, storeId);
    return http
      .request({
        url: endpointURL,
        method: 'post',
        params: {
          responseFormat: 'json'
        },
        data: {
          ...newAddress,
          requestType: REQUEST_TYPE_CREATE
        }
      })
      .then(({ data }) => data)
      .then(({ aviCheckReq, selection = '', formattedAddress = {} }) => {
        const {
          formattedAddress2,
          primaryPostalCode,
          city: formattedCity,
          state: formattedState,
          country: formattedCountry
        } = formattedAddress || {};
        if (onSuccess) {
          onSuccess();
        }
        return dispatch({
          type: CHECKOUT_VERIFY_ADDRESS_SUCCESS,
          payload: {
            // handling both string or boolean. The API implementation differed from the design
            enabled: aviCheckReq === true || aviCheckReq === 'true',
            required: selection === 'required',
            address1: formattedAddress2, // IIB returns address1 and address2 concatenated together so setting that to address1
            address2: '',
            zipCode: primaryPostalCode,
            city: formattedCity,
            state: formattedState,
            country: formattedCountry,
            aviSelection: selection
          }
        });
      })
      .catch(payload => {
        if (onFail) {
          onFail(payload);
        }
        dispatch({ type: CHECKOUT_VERIFY_ADDRESS_FAIL, payload });
      });
  };
};

export const setSelectedBillingMethod = (
  billingMethod,
  selectedAdditionalBillingMethod,
  dispatchAction
) => {
  const action = {
    type: CHECKOUT_SUMMARY_SET_BILLING_METHOD,
    payload: { billingMethod, selectedAdditionalBillingMethod }
  };
  if (dispatchAction) {
    dispatchAction(action);
  } else {
    return dispatch => {
      dispatch(action);
    };
  }
};

export const checkOrderTotalsToSetBillingMethod = ({
  billingMethodOptions,
  unformattedOrderTotal,
  selectedBillingMethod,
  userAmount,
  dispatchAction
}) => {
  if (!unformattedOrderTotal && !userAmount) {
    return;
  }
  const userAmountCoversTotalOrder = userAmount >= unformattedOrderTotal;
  if (userAmountCoversTotalOrder) {
    setSelectedBillingMethod(
      {
        displayName: 'Select Method',
        value: BILLING_METHOD_CAT_VANTAGE_REWARDS
      },
      BILLING_METHOD_TYPE_PRIMARY,
      dispatchAction
    );
  } else {
    // If Cat Vantage was covering the order total and user reduce the rewards
    // amount, We want to enable the BillingMethod dropdown again and set it
    // to the default method or the first in the list if not default found.
    const isCatVantageMethodSelected =
      selectedBillingMethod?.value === BILLING_METHOD_CAT_VANTAGE_REWARDS;

    if (isCatVantageMethodSelected) {
      const defaultBillingMethod =
        billingMethodOptions?.filter(method => method.isDefault)[0] ||
        billingMethodOptions[0][0];
      setSelectedBillingMethod(
        defaultBillingMethod,
        BILLING_METHOD_TYPE_PRIMARY,
        dispatchAction
      );
    }
  }
};

export const applyPromoCode = (promoCode, onSuccess, onFail, failCallback) => {
  const http = getHttpInstance(TIMEOUT_EXTENDED);
  return async (dispatch, getState) => {
    dispatch({ type: CHECKOUT_APPLY_PROMO_BEGIN });
    const {
      common: { storeId, langId },
      checkout: {
        billingMethodOptions,
        selectedBillingMethod,
        selectedAdditionalBillingMethod,
        catCardGlobalOrderTotalResponseStatus,
        catCardGlobalOrderTotalPayload: { accountNumber, postalZipCode },
        applyCatCreditsGlobal
      },
      featureFlag: { PCC_CatVantageRewardsFlag, PCC_CatCreditsPromoCodeCR }
    } = getState();

    const endpointURL = replaceTokensInUrl(
      CHECKOUT_APPLY_PROMO,
      storeId,
      langId
    );
    return http
      .request({
        url: endpointURL,
        method: 'post',
        data: {
          promoCode,
          storeId,
          langId,
          taskType: 'A'
        }
      })
      .then(({ data }) => {
        if (onSuccess) {
          onSuccess(data, promoCode);
        }
        dispatch({
          type: CHECKOUT_APPLY_PROMO_SUCCESS,
          payload: {
            promoCode,
            orderTotals: {
              ...data,
              ...(PCC_CatVantageRewardsFlag
                ? {
                    orderRemainingPaymentAmount:
                      data.orderRemainingRewardsOnlyPaymentAmount,
                    orderCatPrepaidCertificatesTotal:
                      data.rewardsAppliedToOrder,
                    unformattedOrderCatPrepaidCertificatesTotal:
                      data.unformattedRewardsAppliedToOrder
                  }
                : {}),
              ...(PCC_CatCreditsPromoCodeCR &&
              !!applyCatCreditsGlobal.applyCatCreditsGlobalBalance
                .amountUsedCatCreditsGlobal
                ? {
                    amountUsedCatCreditsGlobal: data?.usedCatCreditsAmount,
                    usedAmountForeignCurrency:
                      data?.usedCatCreditsAmountForeignCurrency,
                    remainingCatCreditsGlobal: data?.remainingOrderAmount,
                    remainingAmountForeignCurrency:
                      data?.remainingOrderAmountForeignCurrency
                  }
                : {})
            }
          }
        });
        // When a Promo code is applied, the back end could reduce the amount
        // of rewards applied to the order if it exceed the new order total
        if (PCC_CatVantageRewardsFlag) {
          checkOrderTotalsToSetBillingMethod({
            billingMethodOptions,
            unformattedOrderTotal: data.unformattedOrderTotal,
            selectedBillingMethod,
            userAmount: data.unformattedRewardsAppliedToOrder,
            dispatchAction: dispatch
          });
        }
        if (
          PCC_CatCreditsPromoCodeCR &&
          data?.unformattedUsedCatCreditsAmount >= data?.unformattedOrderTotal
        ) {
          dispatch({ type: RESET_ADDITIONAL_BILLING_METHOD });
        }

        // Call catCardGlobal if Cat Card was applied before and it's still selected
        const isCatCardBillingMethodSelected =
          selectedBillingMethod?.value?.includes(BILLING_METHOD_NEW_CAT_CARD) ||
          selectedAdditionalBillingMethod?.value?.includes(
            BILLING_METHOD_NEW_CAT_CARD
          );
        if (
          isCatCardBillingMethodSelected &&
          catCardGlobalOrderTotalResponseStatus === STATUS.RESOLVED
        ) {
          dispatch(getCatCardGlobalOrderTotal(accountNumber, postalZipCode));
        }
      })
      .catch(payload => {
        if (onFail) {
          onFail(payload);
        }
        try {
          failCallback(payload);
        } catch {
          const error = normalizeError(payload, {});
          if (error) {
            dispatch(
              setError(ERROR_DOMAIN.CHECKOUT, ERROR_PATH.PROMOCODE, error)
            );
          }
        }

        return dispatch({ type: CHECKOUT_APPLY_PROMO_FAIL, payload });
      });
  };
};

export const removePromoCode = (promoCodeEnCoded, successCallback) => {
  const http = getHttpInstance(TIMEOUT_EXTENDED);
  return async (dispatch, getState) => {
    dispatch({ type: CHECKOUT_REMOVE_PROMO_BEGIN });
    const {
      common: { storeId },
      checkout: {
        billingMethodOptions,
        selectedBillingMethod,
        selectedAdditionalBillingMethod,
        catCardGlobalOrderTotalResponseStatus,
        catCardGlobalOrderTotalPayload: { accountNumber, postalZipCode },
        applyCatCreditsGlobal
      },
      featureFlag: { PCC_CatVantageRewardsFlag, PCC_CatCreditsPromoCodeCR }
    } = getState();

    const endpointURL = replaceTokensInUrl(
      CHECKOUT_REMOVE_PROMO,
      storeId,
      promoCodeEnCoded
    );
    return http
      .request({
        url: endpointURL,
        method: 'DELETE',
        data: promoCodeEnCoded
      })
      .then(({ data }) => {
        if (successCallback) {
          successCallback();
        }
        dispatch({
          type: CHECKOUT_REMOVE_PROMO_SUCCESS,
          payload: {
            orderTotals: {
              ...data,
              ...(PCC_CatVantageRewardsFlag
                ? {
                    orderRemainingPaymentAmount:
                      data.orderRemainingRewardsOnlyPaymentAmount,
                    orderCatPrepaidCertificatesTotal:
                      data.rewardsAppliedToOrder,
                    unformattedOrderCatPrepaidCertificatesTotal:
                      data.unformattedRewardsAppliedToOrder
                  }
                : {}),
              ...(PCC_CatCreditsPromoCodeCR &&
              !!applyCatCreditsGlobal.applyCatCreditsGlobalBalance
                .amountUsedCatCreditsGlobal
                ? {
                    amountUsedCatCreditsGlobal: data?.usedCatCreditsAmount,
                    usedAmountForeignCurrency:
                      data?.usedCatCreditsAmountForeignCurrency,
                    remainingCatCreditsGlobal: data?.remainingOrderAmount,
                    remainingAmountForeignCurrency:
                      data?.remainingOrderAmountForeignCurrency
                  }
                : {})
            }
          }
        });
        // When the Promo code removed was applied, the back end could've reduced the amount
        // of rewards applied to the order if it exceed the new order total
        // so, we need to re enable the billing method if needed
        if (PCC_CatVantageRewardsFlag) {
          checkOrderTotalsToSetBillingMethod({
            billingMethodOptions,
            unformattedOrderTotal: data.unformattedOrderTotal,
            selectedBillingMethod,
            userAmount: data.unformattedRewardsAppliedToOrder,
            dispatchAction: dispatch
          });
        }

        if (
          PCC_CatCreditsPromoCodeCR &&
          data?.unformattedUsedCatCreditsAmount >= data?.unformattedOrderTotal
        ) {
          dispatch({ type: RESET_ADDITIONAL_BILLING_METHOD });
        }

        // Call catCardGlobal if Cat Card was applied before and it's still selected
        const isCatCardBillingMethodSelected =
          selectedBillingMethod?.value?.includes(BILLING_METHOD_NEW_CAT_CARD) ||
          selectedAdditionalBillingMethod?.value?.includes(
            BILLING_METHOD_NEW_CAT_CARD
          );
        if (
          isCatCardBillingMethodSelected &&
          catCardGlobalOrderTotalResponseStatus === STATUS.RESOLVED
        ) {
          dispatch(getCatCardGlobalOrderTotal(accountNumber, postalZipCode));
        }
      })
      .catch(payload => {
        dispatch(
          setError(
            ERROR_DOMAIN.CHECKOUT,
            ERROR_PATH.PROMOCODE,
            normalizeError(payload)
          )
        );
        return dispatch({ type: CHECKOUT_REMOVE_PROMO_FAIL, payload });
      });
  };
};

export const savePoNumber = ({
  modalFlag,
  onPoFail,
  onPoSuccess,
  orderId,
  poAttachment,
  poNumber,
  successCallback,
  isDeleteOnly
} = {}) => {
  return async (dispatch, getState) => {
    dispatch(clearError(ERROR_DOMAIN.CHECKOUT));
    dispatch({ type: CHECKOUT_SAVE_PONUMBER_BEGIN });
    const {
      common: { storeId, langId, userType },
      orders
    } = getState();
    const isGuest = userType === USER_TYPE_GUEST;
    const purchaseOrderInfo =
      orders.byId[orderId || orders.current].purchaseOrderInfo;
    try {
      // Per Val and Alex - The backend order API skips PO number and PO attachment flags for guest users so we should never call this API for guests.
      if (!isGuest) {
        await executeSavePoNumber(
          {
            langId,
            orderId: orderId || orders.current,
            poAttachment,
            poNumber,
            storeId,
            purchaseOrderInfo
          },
          dispatch,
          successCallback,
          onPoSuccess,
          isDeleteOnly
        );
      }
    } catch (error) {
      if (onPoFail) {
        onPoFail(error);
      }
      handleSavePoNumberFail(error, dispatch, modalFlag);
    }
  };
};

export const getMidtransToken = (
  { billingAddressId, paymentMethod, poAttachment, poNumber, orderId },
  failCallback
) => {
  return async (dispatch, getState) => {
    dispatch(clearError(ERROR_DOMAIN.CHECKOUT));
    const {
      common: { langId, storeId, userType },
      orders
    } = getState();
    const isGuest = userType === USER_TYPE_GUEST;
    const purchaseOrderInfo = orders.byId[orders.current].purchaseOrderInfo;
    try {
      // Per Val and Alex - The backend order API skips PO number and PO attachment flags for guest users so we should never call this API for guests.
      if (!isGuest) {
        await executeSavePoNumber(
          {
            langId,
            orderId,
            poAttachment,
            purchaseOrderInfo,
            poNumber,
            storeId
          },
          dispatch
        );
      }
      try {
        dispatch({
          type: CHECKOUT_MIDTRANS_GET_TOKEN_BEGIN
        });
        const result = await executeMidtransGetToken({
          orderId,
          paymentMethod,
          billingAddressId,
          langId,
          storeId
        });
        if (result.data) {
          handleMidtransGetTokenSuccess(result.data, dispatch);
        }
      } catch (error) {
        if (failCallback) {
          failCallback();
        }
        handleMidtransGetTokenFailure(error, dispatch);
      }
    } catch (error) {
      handleSavePoNumberFail(error, dispatch, false);
    }
  };
};

export const midtransPostback = ({ action, orderId }, failCallback) => {
  const http = getHttpInstance(TIMEOUT_EXTENDED);
  return (dispatch, getState) => {
    dispatch({ type: CHECKOUT_MIDTRANS_FINALIZE_TRANSACTION_BEGIN });
    const {
      common: { catalogId, langId, storeId }
    } = getState();
    const endpointUrl = replaceTokensInUrl(
      CHECKOUT_MIDTRANS_FINALIZE_PAYMENT,
      storeId
    );
    // wait for 30 seconds on a success to make sure midtrans has already called commerce.
    // TODO: Followup on a better way for commerce to do this
    const timeout = action === 'onSuccess' ? 30000 : 0;
    return resolveAfter(timeout)
      .then(() =>
        http.post(endpointUrl, {
          orderId,
          action,
          langId,
          catalogId
        })
      )
      .then(({ data: payload }) => {
        if (['onPending', 'onSuccess'].includes(action)) {
          handlePlaceOrderSuccess({
            actionType: CHECKOUT_MIDTRANS_FINALIZE_TRANSACTION_SUCCESS,
            dispatch,
            payload,
            redirectUrl: payload.url
          });
        } else {
          cancelMidtransTransaction(dispatch);
        }
      })
      .catch(payload => {
        if (failCallback) {
          failCallback();
        }
        return dispatch({
          type: CHECKOUT_MIDTRANS_FINALIZE_TRANSACTION_FAIL,
          payload
        });
      });
  };
};

export const placeOrder = (
  {
    billingAddressId,
    error = ERROR_PLACE_ORDER_FAILURE,
    orderId,
    paymentGateway,
    paymentMethod,
    additionalPaymentMethod: latestPaymentMethod,
    poAttachment,
    poNumber = '',
    quoteOrder = false,
    sendToQuoteEmailList,
    sendToQuote,
    fireFormSubmitGAEvent,
    ...rest
  },
  failCallback,
  t
) => {
  return async (dispatch, getState) => {
    dispatch(clearError(ERROR_DOMAIN.CHECKOUT));
    dispatch({ type: CHECKOUT_PLACE_ORDER_BEGIN });
    try {
      validatePaymentGateway(paymentGateway);
    } catch (e) {
      if (failCallback) {
        failCallback();
      }
      dispatch(setError(ERROR_DOMAIN.CHECKOUT, ERROR_PATH.MAIN, error));
      //Return a rejected promise so .finally() can still be called to hide loaders
      return Promise.reject(error);
    }
    const {
      common: { catalogId, langId, storeId, userId, isBuyOnBehalf, userType },
      orders,
      checkout: { selectedAlternativePayment }
    } = getState();
    const isGuest = userType === USER_TYPE_GUEST;
    const purchaseOrderInfo = orders.byId[orders.current].purchaseOrderInfo;
    const additionalPaymentMethod =
      latestPaymentMethod === BILLING_METHOD_CAT_PREPAID_CERT
        ? ''
        : latestPaymentMethod;
    try {
      // Per Val and Alex - The backend order API skips PO number and PO attachment flags for guest users so we should never call this API for guests.
      if (!isGuest) {
        await executeSavePoNumber(
          {
            langId,
            orderId,
            poAttachment,
            purchaseOrderInfo,
            poNumber,
            storeId,
            skipValidation: quoteOrder
          },
          dispatch
        );
      }
      try {
        const placeOrderResult = await executePlaceOrder({
          billingAddressId,
          catalogId,
          orderId,
          langId,
          paymentGateway,
          paymentMethod,
          additionalPaymentMethod,
          poNumber,
          quoteOrder,
          storeId,
          sendToQuoteEmailList,
          sendToQuote,
          selectedAlternativePayment,
          userId,
          isBuyOnBehalf,
          ...rest
        });
        const { data: payload } = placeOrderResult;
        fireFormSubmitGAEvent && fireFormSubmitGAEvent();
        handlePlaceOrderSuccess({
          actionType: CHECKOUT_PLACE_ORDER_SUCCESS,
          dispatch,
          payload,
          redirectUrl: payload.redirectUrl
        });
      } catch (payloadError) {
        handlePlaceOrderFail(payloadError, error, dispatch, getState, t);
        if (failCallback) {
          failCallback();
        }
      }
    } catch (error) {
      fireFormSubmitGAEvent && fireFormSubmitGAEvent(true);
      if (failCallback) {
        failCallback();
      }
      dispatch(resetPlaceOrder());
      handleSavePoNumberFail(error, dispatch, false);
    }
  };
};

export const getTaxAndFreight =
  ({
    orderId,
    fulfillmentType,
    requestByDate,
    clientTime,
    promoCode,
    isGuestEnhancedShippingOptionsEnabled,
    physicalStoreId,
    shipModeId,
    shippingMethod,
    shippingAddress,
    handleFreightCalcGAEvent,
    showToasterFulFillment,
    fieldValueObj,
    availabilityMessage,
    pageType,
    isCallingFromCounter
  }) =>
  async (dispatch, getState) => {
    dispatch({
      type: CHECKOUT_CALC_FREIGHT_BEGIN
    });
    const { storeId, langId } = getState().common;
    const http = getHttpInstance(TIMEOUT_EXTENDED);
    const url = replaceTokensInUrl(
      CHECKOUT_TAX_AND_FREIGHT,
      storeId,
      langId,
      orderId
    );
    let formattedDate = '';
    if (requestByDate) {
      const unformattedDate = new Date(requestByDate);
      formattedDate = format(unformattedDate, DISPLAY_DATE_US);
    }

    try {
      const { data, config } = await http.post(url, {
        fulfillmentType,
        requestByDate: formattedDate,
        clientTime,
        promoCode,
        isGuestEnhancedShippingOptionsEnabled,
        physicalStoreId,
        shipModeId,
        shippingMethod,
        shippingAddress
      });
      //We track the time that the services take in response
      const serviceResponseTime = getServiceResponseTime(config);

      dispatch({
        type: CHECKOUT_CALC_FREIGHT_SUCCESS,
        payload: { data, isCallingFromCounter }
      });
      if (!!showToasterFulFillment) {
        getRBDDateUpdate(
          data.taxAndFreight.find(fnd =>
            pageType === SUMMARY_AND_PAYMENT
              ? fnd
              : fnd?.shipViaCode === fieldValueObj?.shipmentCode
          ).estimatedDeliveryDate,
          fieldValueObj,
          showToasterFulFillment,
          availabilityMessage,
          dispatch,
          pageType
        );
      }
      handleFreightCalcGAEvent &&
        handleFreightCalcGAEvent(data.taxAndFreight, serviceResponseTime);
    } catch (error) {
      dispatch(
        setError(
          ERROR_DOMAIN.CHECKOUT,
          ERROR_PATH.FREIGHT_AND_TAX,
          normalizeError(error)
        )
      );
      dispatch({
        type: CHECKOUT_CALC_FREIGHT_FAIL,
        error
      });
    }
  };
export const resetTaxAndFreight = () => ({
  type: CHECKOUT_CALC_FREIGHT_RESET
});

export const clearTaxAndFreightError = () =>
  clearError(ERROR_DOMAIN.CHECKOUT, ERROR_PATH.FREIGHT_AND_TAX);

export const clearPickupAndDeliveryError = () =>
  clearError(ERROR_DOMAIN.CHECKOUT, ERROR_PATH.PICKUP_DELIVERY_MAIN);

export const resetCheckout = () => ({
  type: CHECKOUT_RESET
});
export const loadShoppingCartPage = setCartImportMessage => {
  const http = getHttpInstance();
  return (dispatch, getState) => {
    dispatch({ type: CHECKOUT_LOAD_CART_BEGIN });

    const {
      common: { storeId, langId }
    } = getState();

    const shoppingCartImportModalEndpointURL = replaceTokensInUrl(
      SHOPPING_CART_IMPORT_SUMMARY_MODAL,
      storeId,
      langId
    );
    return http
      .get(shoppingCartImportModalEndpointURL)
      .then(({ data: { shoppingCartImportModel = {} } }) => {
        dispatch(clearError(ERROR_DOMAIN.CART, ERROR_PATH.MAIN));
        if (shoppingCartImportModel?.systemFeedBack?.length > 0) {
          setCartImportMessage(shoppingCartImportModel);
        }
        dispatch({
          type: CHECKOUT_LOAD_CART_SUCCESS,
          payload: isEmpty(shoppingCartImportModel)
            ? {
                isShoppingCartImportModalDisplay: null,
                systemFeedBack: null
              }
            : shoppingCartImportModel
        });
      })
      .catch(payload => {
        dispatch({ type: CHECKOUT_LOAD_CART_FAIL, payload });
        dispatch(
          setError(ERROR_DOMAIN.CART, ERROR_PATH.MAIN, normalizeError(payload))
        );
      });
  };
};

export const setSelectedBillingAddress = (billingAddress = {}) => {
  return dispatch => {
    dispatch({
      type: CHECKOUT_SUMMARY_SET_BILLING_ADDRESS,
      payload: billingAddress
    });
  };
};

export const setSelectedAlternativePayment = (alternativePayment = {}) => {
  return dispatch => {
    dispatch({
      type: CHECKOUT_SUMMARY_SET_ALTERNATIVE_PAYMENT,
      payload: { alternativePayment }
    });
  };
};

export const getDealerCustomerDcn =
  ({ store, customerNumber, isQuoteEnabled = false }) =>
  (dispatch, getState) => {
    const { storeId } = getState().common;
    const storeNumber = store ?? storeId;
    let url = replaceTokensInUrl(DEALER_CUSTOMER_DCN, storeNumber);
    if (isQuoteEnabled) {
      url = url + `?quoteEnabled=${isQuoteEnabled}`;
    } else if (customerNumber) {
      url = url + `?dealerCustNum=${customerNumber}`;
    }
    dispatch({ type: GET_DEALER_DCN_CUSTOMER_BEGIN });
    const http = getHttpInstance();
    return http
      .get(url)
      .then(({ data = {} }) => {
        dispatch({
          type: GET_DEALER_DCN_CUSTOMER_SUCCESS,
          payload: data.dealerDCNAssociations
        });
      })
      .catch(error => {
        dispatch({
          type: GET_DEALER_DCN_CUSTOMER_FAIL
        });
      });
  };

export const setOrderInformation =
  ({
    customerNumber,
    endUseCode,
    errorInfo = {},
    orderType,
    storeLocation,
    requestedShippingDate = '',
    equipmentInfo = {},
    isOrderInfoUpdate = false,
    updateRequestByDateOnly,
    calledFrom
  }) =>
  async (dispatch, getState) => {
    //Dispatch action to stay the import successful modal until navigates to PUD page
    if (
      calledFrom !== IMPORT_MODAL_VISIBLE_CHECKOUT &&
      calledFrom !== SHOPPING_CART
    ) {
      dispatch(clearError(ERROR_DOMAIN.CART));
    }
    dispatch({ type: CHECKOUT_SET_ORDER_INFORMATION_BEGIN });

    const {
      common: { storeId, langId },
      featureFlag: { PCCDeliveryDropboxRBDByAvailability },
      orders: { requestByDate: { requestByDateEnabled } } = {}
    } = getState();

    const orderInformationUpdateEndpointURL = replaceTokensInUrl(
      SHOPPING_CART_IMPORT_SUMMARY_MODAL,
      storeId,
      langId
    );
    const { dealerMakeCode, serialNumber, model, assetId } =
      equipmentInfo ?? {};

    const getModelOrSerialNumber = (input, valueIndex) => {
      const slicedInput = input
        .replace(/^[^\(]*\(/, '')
        .replace(/\)[^\)]*$/, '')
        .trim();

      if (slicedInput.includes('-')) {
        const valueInput = slicedInput.split('-');
        return valueInput[valueIndex] ? valueInput[valueIndex].trim() : '';
      } else {
        return valueIndex === 1 ? slicedInput.trim() : '';
      }
    };

    let isSendRBDValue = true;
    if (
      !requestByDateEnabled ||
      (PCCDeliveryDropboxRBDByAvailability && calledFrom === SHOPPING_CART)
    ) {
      isSendRBDValue = false;
    }
    const normalizeEquipmentObject = equipmentInfo =>
      typeof equipmentInfo === 'object'
        ? {
            dealerMakeCode,
            serialNumber,
            model,
            assetId
          }
        : {
            dealerMakeCode: '',
            serialNumber: getModelOrSerialNumber(equipmentInfo, 1),
            model: getModelOrSerialNumber(equipmentInfo, 0),
            assetId: equipmentInfo.includes('(')
              ? equipmentInfo.slice(0, equipmentInfo.indexOf('(')).trim()
              : ''
          };
    const body = {
      dcnNumber: customerNumber,
      physicalStoreId: storeLocation,
      orderType: orderType?.value,
      orderTypeLabel: orderType?.label,
      ...(isSendRBDValue && { requestedShippingDate }),
      equipment: !updateRequestByDateOnly
        ? normalizeEquipmentObject(equipmentInfo)
        : null,
      isOrderInfoUpdate,
      ...(endUseCode ? { endUseCode } : {})
    };

    try {
      const http = getHttpInstance(TIMEOUT_EXTENDED);
      const { data } = await http.put(orderInformationUpdateEndpointURL, body);
      const orgSetInSessionUrl = replaceTokensInUrl(
        endpoints.DEALER_CUSTOMER_ORG_SET_IN_SESSION,
        storeId
      );
      await http.get(orgSetInSessionUrl);

      const { isPartsRemovedByInvalidSOS = false, removedParts = [] } = data;
      dispatch({
        type: CHECKOUT_SET_ORDER_INFORMATION_SUCCESS,
        payload: { isPartsRemovedByInvalidSOS, removedParts }
      });
    } catch (error) {
      dispatch({ type: CHECKOUT_SET_ORDER_INFORMATION_FAIL });
      dispatch({ type: CHECKOUT_CART_FORM_SUBMIT_FAIL });
      const { domain, path } = errorInfo;
      if (domain && path) {
        dispatch(setError(domain, path, normalizeError(error)));
      }
      throw error;
    }
  };

export const updateDateAndEquipment =
  ({
    requestByDate,
    equipment,
    updateRequestByDateOnly = false,
    calledFrom = INITIAL_RENDER
  }) =>
  (dispatch, getState) => {
    const {
      common: {
        selectedCustomerNumber,
        selectedCustomerName,
        selectedEndUseCode,
        selectedOrderType,
        selectedStore
      },
      orders: {
        requestByDate: { format: requestByDateFormat }
      }
    } = getState();
    const errorInfo = {
      domain: ERROR_DOMAIN.CART,
      path: ERROR_PATH.MAIN
    };
    let formattedDate;
    if (!!requestByDate) {
      if (requestByDate instanceof Date && !isNaN(requestByDate)) {
        formattedDate = format(new Date(requestByDate), 'M/dd/yyyy');
      } else {
        formattedDate = format(
          parse(requestByDate, requestByDateFormat, new Date()),
          'M/dd/yyyy'
        );
      }
    }
    const formattedValues = {
      customerNumber: `${selectedCustomerNumber}-${selectedCustomerName}`,
      endUseCode: selectedEndUseCode?.value
        ? `${selectedEndUseCode.value}:${selectedEndUseCode.label}`
        : null,
      errorInfo,
      orderType: selectedOrderType,
      storeLocation: selectedStore,
      requestedShippingDate: formattedDate,
      equipmentInfo: equipment,
      updateRequestByDateOnly,
      calledFrom
    };

    return dispatch(setOrderInformation(formattedValues));
  };

export const callSOSService = async ({
  items,
  oneClickBuy,
  storeId,
  catalogId,
  langId,
  currentOrderId,
  skipSB,
  dispatch
}) => {
  const fromPage = 'shoppingCart';
  const defaultPayload = {
    storeId,
    langId,
    catalogId,
    cartProdList: '',
    requestType: 'ajax',
    noOfItems: items.length,
    calculationUsage: '-1,-4,-5,-6,-7',
    oneClickBuy,
    fromPage,
    skipSBPage: !!skipSB,
    orderId: currentOrderId == null ? '' : currentOrderId
  };

  const reqPayload = getSOSServicePayload(defaultPayload, items);
  const http = getHttpInstance(TIMEOUT_EXTENDED);
  const url = normalizeUrl('CATQuickOrderValidatePartsCmdNewSOSFragment');
  const config = {
    headers: { 'content-type': 'application/x-www-form-urlencoded' }
  };
  try {
    const { data } = await http.post(url, reqPayload, config);
    return data;
  } catch (error) {
    dispatch(
      setError(ERROR_DOMAIN.CHECKOUT, ERROR_PATH.MAIN, normalizeError(error))
    );
    return dispatch({ type: CHECKOUT_ADD_TO_CART_FAIL });
  }
};

export const addPartsToCart =
  (callback, setErrors, validateItemQuantity, t, errorDomainPath = {}) =>
  async (dispatch, getState) => {
    const { domain, path, modalError } = errorDomainPath;
    const {
      currentAddToCartHasSOS,
      currentAddToCartHasAlternate,
      currentAddToCartHasReplacement
    } = getState().checkout;
    const noSOSReplacementAlternate =
      !currentAddToCartHasSOS &&
      !currentAddToCartHasReplacement &&
      !currentAddToCartHasAlternate;
    const { currentAddToCartModifications } = getState().checkout;
    if (noSOSReplacementAlternate) {
      const quantityErrors =
        currentAddToCartModifications.items &&
        Object.values(currentAddToCartModifications.items).reduce(
          (acc, curr) => {
            const isItemQuantityError = validateItemQuantity(
              curr,
              curr.requestedQuantity
            );
            isItemQuantityError && acc.push(curr.referenceId);
            return acc;
          },
          []
        );
      if (quantityErrors?.length) {
        dispatch({ type: GET_DEALER_PRICE_AND_AVAILABILITY_RESET });
        dispatch({ type: ADD_TO_CART_MODIFICATIONS_RESET });
        setInvalidQuantityErrors(
          quantityErrors,
          {
            items: Object.values(currentAddToCartModifications.items)
          },
          setErrors,
          t
        );
        dispatch(
          setError(ERROR_DOMAIN.QUICK_ORDER, ERROR_PATH.MAIN, {
            title: t('UNKNOWN_ERROR_TITLE'),
            message: t('QO_MAXIMUM_QUANTITY_UPDATED_ERROR')
          })
        );
        return;
      }
    }
    try {
      if (domain && path) {
        dispatch(clearError(domain, path));
      } else {
        dispatch(clearError(ERROR_DOMAIN.QUICK_ORDER, ERROR_PATH.MAIN));
      }
      const http = getHttpInstance(TIMEOUT_EXTENDED);
      const { currentAddToCartModifications } = getState().checkout;
      const {
        itemsAndQuantityAddedIntoCartObject = [],
        numberOfItemsAddedToCart = 0
      } = itemsAddedToCartHelpers(currentAddToCartModifications?.items) ?? {};
      const { replacedParts } = getState().orders;
      const body = buildAddQOToCartBody(
        currentAddToCartModifications?.items,
        replacedParts
      );
      const { storeId } = getState().common;
      const requestURL = replaceTokensInString(
        endpoints.ADD_QO_TO_CART,
        storeId
      );
      dispatch({
        type: ADD_QO_TO_CART_BEGIN
      });
      const { data } = await http.post(requestURL, body);
      dispatch({
        type: ADD_QO_TO_CART_SUCCESS,
        payload: {
          data
        }
      });
      dispatch({
        type: SET_TOTAL_ITEMS_ADDED_TO_CART,
        payload: numberOfItemsAddedToCart
      });
      callback(itemsAndQuantityAddedIntoCartObject, data);
    } catch (error) {
      let errorMsg = normalizeError(error, modalError);
      const quantityErrorKey =
        error.response?.data?.errors[0]?.errorKey ===
        '_ERR_THRESHOLD_SHOPPING_CART_QUANTITY';
      if (quantityErrorKey) {
        errorMsg = {
          title: t('UNKNOWN_ERROR_TITLE'),
          message: t('QO_MAXIMUM_QUANTITY_UPDATED_ERROR')
        };
      }
      dispatch({
        type: ADD_QO_TO_CART_FAIL,
        error
      });
      /**
       * If going through regular flow and no modals open, reset everything
       * otherwise, only reset phase
       */
      if (noSOSReplacementAlternate) {
        dispatch({ type: ADD_TO_CART_MODIFICATIONS_RESET });
      } else {
        dispatch({
          type: ADD_TO_CART_RESET_PHASE
        });
      }
      if (domain && path) {
        dispatch(setError(domain, path, errorMsg));
      } else {
        dispatch(setError(ERROR_DOMAIN.QUICK_ORDER, ERROR_PATH.MAIN, errorMsg));
      }
    }
  };

export const getDealerPriceAndAvailability =
  (errorDomainPath = {}, callback = () => false) =>
  async (dispatch, getState) => {
    const { domain, path, modalError } = errorDomainPath;
    try {
      if (domain && path) {
        dispatch(clearError(domain, path));
      } else {
        dispatch(clearError(ERROR_DOMAIN.QUICK_ORDER, ERROR_PATH.MAIN));
      }

      const http = getHttpInstance(TIMEOUT_EXTENDED);
      const { currentAddToCartModifications, currentAddToCartHasSOS } =
        getState().checkout;
      const { storeId, locale } = getState().common;

      const requestURL = replaceTokensInString(
        endpoints.PRICE_AVAILABILITY_DEALER_URL,
        storeId,
        locale
      );
      const body = buildDealerPriceAndAvailabilityBody(
        currentAddToCartModifications.items
      );

      dispatch({
        type: GET_DEALER_PRICE_AND_AVAILABILITY_BEGIN
      });

      const { data } = await http.post(requestURL, body);
      const alternateParts = data.items.reduce((acc, item) => {
        const altParts = getAltParts(item, item.referenceId);
        if (altParts?.length) {
          return acc.concat(altParts);
        }
        return acc;
      }, []);

      const replacementParts = data.items.filter(
        p => p.replacementParts.length
      );
      const { items, ...newData } = data;
      const newItems = arrangeAlternateReplacedOptions(
        items,
        currentAddToCartModifications.items
      );
      dispatch({
        type: GET_DEALER_PRICE_AND_AVAILABILITY_SUCCESS,
        payload: {
          hasAlternate: !!alternateParts?.length,
          hasReplacement: !!replacementParts?.length,
          modifications: newData,
          items: newItems
        }
      });
      const hasOnlySOSPartsQuantityError = callback?.({
        replacementParts,
        alternateParts
      });
      if (replacementParts?.length || alternateParts?.length) {
        // Only open replacement or alternate modal as parent if sos or replacement modal is not already open
        if (!currentAddToCartHasSOS) {
          dispatch({
            type: ADD_TO_CART_SET_PHASE,
            payload: replacementParts.length
              ? ADD_TO_CART_PHASES.REPLACEMENT_MODAL
              : ADD_TO_CART_PHASES.ALTERNATE_MODAL
          });
        }
      } else if (!hasOnlySOSPartsQuantityError) {
        dispatch({
          type: ADD_TO_CART_SET_PHASE,
          payload: ADD_TO_CART_PHASES.ADD_PARTS_TO_CART
        });
      }
    } catch (error) {
      const errorMsg = normalizeError(error, modalError);
      dispatch({
        type: GET_DEALER_PRICE_AND_AVAILABILITY_FAIL,
        payload: error
      });
      const { currentAddToCartHasSOS } = getState().checkout;
      /**
       * If sos modal is not open, reset everything
       * otherwise, only reset phase
       */
      if (!currentAddToCartHasSOS) {
        dispatch({ type: ADD_TO_CART_MODIFICATIONS_RESET });
      } else {
        dispatch({
          type: ADD_TO_CART_RESET_PHASE
        });
      }
      dispatch({ type: RESET_REPLACED_PARTS_INFO });
      if (domain && path) {
        dispatch(setError(domain, path, errorMsg));
      } else {
        dispatch(setError(ERROR_DOMAIN.QUICK_ORDER, ERROR_PATH.MAIN, errorMsg));
      }
    }
  };

export const getDealerPriceAndAvailabilityReplacementWithReplacement =
  () => async (dispatch, getState) => {
    try {
      dispatch(clearError(ERROR_DOMAIN.QUICK_ORDER, ERROR_PATH.MAIN));
      dispatch({ type: GET_DEALER_PRICE_AND_AVAILABILITY_BEGIN });

      const http = getHttpInstance(TIMEOUT_EXTENDED);
      const { storeId, locale } = getState().common;
      const { currentAddToCartModifications } = getState().checkout;
      const { current, byId, replacedParts } = getState().orders;

      const items = isEmpty(currentAddToCartModifications?.items)
        ? orderItemsToObject(byId?.[current]?.ascendingOrderItems)
        : currentAddToCartModifications.items;

      const requestURL = replaceTokensInString(
        endpoints.PRICE_AVAILABILITY_DEALER_URL,
        storeId,
        locale
      );
      const body = buildDealerPriceAndAvailabilityWithExtraReplacementBody(
        replacedParts,
        items
      );

      const { data } = await http.post(requestURL, body);
      const hasAlternate = data.items.some(p => !!getAltParts(p)?.length);
      const hasReplacement = data.items.some(p => !!p.replacementParts?.length);
      const { items: dataItems, ...newData } = data;
      const newItems = arrangeAlternateReplacedOptions(dataItems, items);
      dispatch({
        type: GET_DEALER_PRICE_AND_AVAILABILITY_SUCCESS,
        payload: {
          hasAlternate,
          hasReplacement: false,
          modifications: newData,
          items: newItems
        }
      });
      if (hasReplacement) {
        dispatch({
          type: ADD_TO_CART_SET_PHASE,
          payload: ADD_TO_CART_PHASES.REPLACEMENT_MODAL
        });
      }
    } catch (error) {
      const errorMsg = normalizeError(error, {});
      dispatch({ type: GET_DEALER_PRICE_AND_AVAILABILITY_FAIL });
      dispatch({ type: ADD_TO_CART_MODIFICATIONS_RESET });
      dispatch({ type: RESET_REPLACED_PARTS_INFO });
      dispatch(setError(ERROR_DOMAIN.QUICK_ORDER, ERROR_PATH.MAIN, errorMsg));
    }
  };

export const getDealerSOSDetails =
  ({ items, invalidPartsHandler, sisFlow = false, t }) =>
  async (dispatch, getState) => {
    try {
      dispatch(clearError(ERROR_DOMAIN.QUICK_ORDER, ERROR_PATH.MAIN));
      const http = getHttpInstance(TIMEOUT_EXTENDED);
      const body = createSOSServicePayload(items);
      const { storeId, locale } = getState().common;
      const requestURL = replaceTokensInString(
        endpoints.DEALER_SOS_OPTIONS_URL,
        storeId,
        locale
      );
      dispatch({
        type: GET_SOS_DETAILS_BEGIN
      });
      const { data, config } = await http.post(requestURL, body);
      const serviceResponseTime = getServiceResponseTime(config);
      gaTimeTracker(serviceResponseTime, SOURCE_OF_SUPPLY_API);
      const errors = data?.items?.reduce((acc, item) => {
        if (item.sosList?.[0].errors.errorMessage) {
          return sisFlow
            ? invalidErrorPartsHandler(data)
            : { ...acc, [item.referenceId]: item.sosList?.[0].errors };
        } else {
          return acc;
        }
      }, {});
      if (!isEmpty(errors)) {
        dispatch({
          type: GET_SOS_DETAILS_FAIL
        });
        dispatch(
          setError(ERROR_DOMAIN.QUICK_ORDER, ERROR_PATH.MAIN, {
            title: t('UNKNOWN_ERROR_TITLE'),
            message: t('UNKNOWN_ERROR_MESSAGE')
          })
        );
        if (!sisFlow) {
          dispatch({ type: ADD_TO_CART_MODIFICATIONS_RESET });
        } else {
          dispatch({
            type: CHECKOUT_SIS_TO_CART_FAIL,
            payload: {
              errors,
              isError: true,
              items
            }
          });
        }
        invalidPartsHandler && invalidPartsHandler(errors);
      } else {
        const itemsWithSOSAndQuickOrderValues = arrangeSOSData(data, items);
        const hasSOS = data.items.some(item => item.sosList?.length > 1);
        dispatch({
          type: GET_SOS_DETAILS_SUCCESS,
          payload: { ...data, items: itemsWithSOSAndQuickOrderValues, hasSOS }
        });
        const current = getState().orders.current;
        const orderInformation = getState().orders?.byId?.[current] || {};
        const { orderItem } = orderInformation;
        updateSisFallbackCookie(items);
        const totalQuantity = orderItem?.reduce((total, item) => {
          return total + +item.quantity;
        }, 0);
        const sisCookie = getCookie(SIS_PARTS_LIST) || '';
        sisCookie &&
          setSessionStorage(INITIAL_CART_ITEMS, JSON.stringify(totalQuantity));
        if (!hasSOS) {
          dispatch({
            type: ADD_TO_CART_SET_PHASE,
            payload: ADD_TO_CART_PHASES.CALLING_PA_SERVICE
          });
        } else {
          dispatch({
            type: ADD_TO_CART_SET_PHASE,
            payload: ADD_TO_CART_PHASES.SOS_MODAL
          });
        }
      }
      return errors;
    } catch (err) {
      dispatch({ type: GET_SOS_DETAILS_FAIL, payload: err });
      dispatch(
        setError(ERROR_DOMAIN.QUICK_ORDER, ERROR_PATH.MAIN, {
          title: t('UNKNOWN_ERROR_TITLE'),
          message: t('UNKNOWN_ERROR_MESSAGE')
        })
      );
      dispatch({ type: ADD_TO_CART_MODIFICATIONS_RESET });
      throw err;
    }
  };

export const setSISValueEmpty = () => async dispatch => {
  dispatch({
    type: CHECKOUT_SIS_TO_CART_FAIL,
    payload: {
      errors: [],
      isError: false,
      items: []
    }
  });
};
export const setSelectedAdditionalBillingMethod = (billingMethod = {}) => {
  return dispatch => {
    dispatch({
      type: CHECKOUT_SUMMARY_SET_ADDITIONAL_BILLING_METHOD,
      payload: billingMethod
    });
  };
};
export const getCertificates = successCb => async (dispatch, getState) => {
  const storeId = getState().common.storeId;
  const applycertificateEndpoint = replaceTokensInUrl(
    APPLY_PREPAID_CERTIFICATE,
    storeId
  );
  const http = getHttpInstance(TIMEOUT_DOUBLED);

  try {
    const { data = {} } = await http.get(applycertificateEndpoint);

    if (successCb) {
      successCb();
    }
    return dispatch({
      type: CHECKOUT_APPLY_PREPAID_CERTIFICATE_SUCCESS,
      payload: { ...data }
    });
  } catch (error) {
    const { errorKey, errorMessage } = error?.response?.data?.errors[0];
    dispatch(
      setError(
        ERROR_DOMAIN.CHECKOUT,
        ERROR_PATH.CAT_CREDIT,
        normalizeError(error, { title: errorKey, message: errorMessage }, true)
      )
    );
    dispatch({ type: CHECKOUT_APPLY_PREPAID_CERTIFICATE_FAIL });
  }
};
const getErrorParameters = errorParam => {
  switch (errorParam) {
    case CERT_ERROR_MESSAGE:
    case CERT_MISMATCH:
      return CERT_MISMATCH_ERROR;
    case NO_FUNDS_AVAILABLE:
      return CERT_NO_FUNDS_AVAILABLE;
    case CAT_CREDIT_EXPIRED_HEADER:
      return CERT_EXPIRED_ERROR;
    case CAT_CREDIT_APPLIED_HEADER:
      return CERT_SAMECARD_ERROR;
    case VOID_FAILURE_CAT_CREDIT_HEADER:
      return CAT_CREDIT_VOID_FAILURE_ERROR;
    case CVR_REWARDS_CERT_ERROR_MESSAGE:
      return CVR_REWARDS_CERT_ERROR_MESSAGE_ERROR;
    default:
      return;
  }
};
export const applyPrepaidCertificate =
  (
    { cardNumber, expDate },
    successCb,
    failureCb,
    fireEnterECertficateSubmitEvent
  ) =>
  async (dispatch, getState) => {
    let applyPrepaidCertificateResult;
    const { storeId } = getState().common;
    const applycertificateEndpoint = replaceTokensInUrl(
      APPLY_PREPAID_CERTIFICATE,
      storeId
    );
    dispatch({ type: CHECKOUT_APPLY_PREPAID_CERTIFICATE_BEGIN });
    const http = getHttpInstance(TIMEOUT_DOUBLED);
    const cardExpirationDate = format(expDate, 'MM/dd/yyyy');
    try {
      const { data } = await http.post(applycertificateEndpoint, {
        cardNumber,
        cardExpirationDate
      });
      if (successCb && data?.success) {
        fireEnterECertficateSubmitEvent(STATUS_SUCCESS);
        return dispatch(getCertificates(successCb));
      }
    } catch (error) {
      const { errorKey } = error?.response?.data?.errors[0];
      const certErrorConstants = getErrorParameters(errorKey);
      const fieldError = certErrorConstants?.fieldError;
      dispatch(
        setError(
          ERROR_DOMAIN.CHECKOUT,
          ERROR_PATH.CAT_CREDIT,
          normalizeError(error, certErrorConstants, true)
        )
      );

      if (!!fieldError) {
        failureCb(fieldError);
      }
      fireEnterECertficateSubmitEvent(STATUS_FAIL, fieldError);
      dispatch({ type: CHECKOUT_APPLY_PREPAID_CERTIFICATE_FAIL });
    }
    return applyPrepaidCertificateResult;
  };

export const deletePrepaidCertificate =
  (cardToken, successCb, failureCb) => async (dispatch, getState) => {
    const { storeId } = getState().common;
    dispatch(setCatCreditToolTip(false));
    dispatch({ type: CHECKOUT_DELETE_PREPAID_CERTIFICATE_BEGIN });
    let deletePrepaidCertificateResult;
    const removecertificateEndpoint = replaceTokensInUrl(
      DELETE_PREPAID_CERTIFICATE,
      storeId,
      cardToken
    );
    const http = getHttpInstance(TIMEOUT_DOUBLED);

    try {
      const { data } = await http.delete(removecertificateEndpoint);

      if (successCb && data?.success) {
        return dispatch(getCertificates(successCb));
      }
    } catch (error) {
      const { errorKey, errorMessage } =
        error?.response?.data?.errors?.[0] || {};

      dispatch(
        setError(
          ERROR_DOMAIN.CHECKOUT,
          ERROR_PATH.CAT_CREDIT,
          normalizeError(
            error,
            { title: errorKey, message: errorMessage },
            true
          )
        )
      );
      dispatch({ type: CHECKOUT_DELETE_PREPAID_CERTIFICATE_FAIL });
    }
    return deletePrepaidCertificateResult;
  };

export const savedDeliveryAddress = ({ selectedAddress, closeModal }) => {
  const http = getHttpInstance();
  return async (dispatch, getState) => {
    dispatch({ type: PICKUP_DELIVERY_SAVE_ADDRESS_BEGIN });

    const {
      common: { storeId },
      checkout: {
        [SHOP_ONLINE]: { address }
      }
    } = getState();

    // Check if the nickname exist in the original Address array
    const { nickName: existingNickname } = selectedAddress;
    const existing = existingNickname
      ? address.find(({ nickName }) => nickName === existingNickname)
      : {};
    const isNewAddress = isEmpty(existing);

    // Updating all the properties that the user change or create the new object for new address
    const finalAddress = {
      ...existing,
      ...selectedAddress,
      phone: selectedAddress.phone1,
      addressType: isNewAddress ? ADDRESS_TYPE_SHIPPING : existing.addressType
    };

    // is a new address we put the new value at the end
    // but if the address exist we keep the same index in the new array of addresses
    // will pass the new array to the reducer
    let newAddresses = [];
    if (isNewAddress) {
      newAddresses = [...address, finalAddress];
    } else {
      newAddresses = address.map(oldAddress =>
        oldAddress.nickName === finalAddress.nickName
          ? finalAddress
          : oldAddress
      );
    }
    const {
      address1 = '',
      address2 = '',
      email: email1 = '',
      phone: phone1 = '',
      ...restOfAddress
    } = finalAddress;

    const endpointBase = replaceTokensInUrl(ADDRESS_SAVE, storeId);
    const endpointURL = !isNewAddress
      ? `${endpointBase}/${encodeURIComponent(finalAddress.nickName)}`
      : endpointBase;
    const method = !isNewAddress ? 'put' : 'post';
    try {
      const { data } = await http.request({
        url: endpointURL,
        method,
        data: {
          addressLine: [address1, address2],
          email1,
          phone1,
          ...restOfAddress
        }
      });
      removeCookie('mico');

      //Update the address with the new addressId that backend returns
      const updatedAddresses = newAddresses.map(address => {
        let addressToUpdate = { ...address };
        if (address.nickName === finalAddress.nickName) {
          addressToUpdate.addressId = data.addressId;
        }
        return addressToUpdate;
      });

      dispatch({
        type: PICKUP_DELIVERY_SAVE_ADDRESS_SUCCESS,
        payload: {
          newAddresses: updatedAddresses
        }
      });
      closeModal();
    } catch (error) {
      dispatch({ type: PICKUP_DELIVERY_SAVE_ADDRESS_FAILS, error });
      dispatch(
        setError(
          ERROR_DOMAIN.CHECKOUT,
          ERROR_PATH.PICKUP_DELIVERY_MAIN,
          normalizeError(error)
        )
      );
      closeModal();
    }
  };
};

export const exportComplianceValidation = async ({
  storeId,
  langId,
  countryCode,
  orderId,
  dispatch
}) => {
  const http = getHttpInstance(TIMEOUT_EXTENDED);
  const exportComplianceEndpoint = replaceTokensInUrl(
    ORDER_EXPORT_COMPLIANCE,
    storeId,
    langId,
    countryCode,
    orderId
  );
  let exportComplianceFail = false;
  try {
    await http.get(exportComplianceEndpoint);
    dispatch({ type: PICKUP_DELIVERY_EXPORT_COMPLIANCE_SUCCESS });
  } catch (error) {
    exportComplianceFail = true;
    const errorMessage = error?.response?.data?.errors[0].errorMessage;
    dispatch({
      type: PICKUP_DELIVERY_EXPORT_COMPLIANCE_FAILS,
      payload: { errorMessage: [errorMessage] }
    });
  }

  return { exportComplianceFail };
};

export const continueToSummary = ({
  formData,
  deliveryAddress,
  closeModal
}) => {
  return async (dispatch, getState) => {
    const isShoppingCartPage =
      window.location.pathname.includes(SHOPPING_CART_ROUTE);
    const http = getHttpInstance(TIMEOUT_EXTENDED);
    dispatch({ type: PICKUP_DELIVERY_SUBMIT_BEGIN });
    const {
      common: { catalogId, storeId, langId },
      dealer: { countryInfo: countries = [] }
    } = getState();
    const { setFieldValue } = deliveryAddress;
    const fulfillment = deliveryAddress.selectedFulfillmentMethod;
    const isDelivery = fulfillment === SHOP_ONLINE;
    const isLoggedUser = deliveryAddress.isLoggedUser;
    const isRequiredRBDValidation = deliveryAddress.isRequiredRBDValidation;

    const addressNickname = formData[SHOP_ONLINE].address.nickName;
    const countryCode = isLoggedUser
      ? formData[SHOP_ONLINE].address.country
      : deliveryAddress.country;

    const endpoint = replaceTokensInUrl(
      CHECKOUT_PICKUP_AND_DELIVERY_SAVE,
      storeId,
      langId
    );

    const xorderRequestByDateEndoint = replaceTokensInString(
      endpoints.CHECKOUT_ORDER_DETAILS,
      storeId,
      langId,
      false
    );

    const payload = PUDPayloadBuilder({ deliveryAddress, formData, countries });
    let continueToSummaryPage = true;
    let isCutOffTimePassed = false;

    try {
      if (isRequiredRBDValidation) {
        // Call xorder for check if the cutoff time has passed
        const { data } = await http.get(
          `${xorderRequestByDateEndoint}&includeRequestByDateOnly=true`
        );

        const { cutOffTimes, cutOffEnabled } = data.requestByDate;

        const cutOffTimeFlag = getFulfillmentTypeFlag(fulfillment);
        isCutOffTimePassed = cutOffTimes[cutOffTimeFlag] && cutOffEnabled;
        // the cut off time already passed PUD will update the requestByDate value in the order
        // an throw error in the RBD component.
        if (isCutOffTimePassed) {
          dispatch({
            type: UPDATE_ORDER_REQUEST_BY_DATE,
            payload: {
              requestByDate: data.requestByDate
            }
          });
          dispatch({ type: PICKUP_DELIVERY_REQUEST_BY_DATE_FAILS });
          closeModal();
        }
      }

      if (
        isDelivery &&
        !isCutOffTimePassed &&
        !addressNickname?.includes('JOBSITE')
      ) {
        const { exportComplianceFail } = await exportComplianceValidation({
          storeId,
          langId,
          countryCode,
          orderId: formData.orderId,
          dispatch
        });
        continueToSummaryPage = !exportComplianceFail;
        closeModal();
      }

      if (continueToSummaryPage && !isCutOffTimePassed) {
        const { data } = await http.request({
          url: endpoint,
          method: 'post',
          data: {
            ...payload
          }
        });
        dispatch({
          type: PICKUP_DELIVERY_SUBMIT_SUCCESS,
          payload: { ...data }
        });
        removeCookie('mico');
        !isDelivery && closeModal();
        const summaryAndPaymentPageURL = replaceTokensInUrl(
          normalizeUrl(SUMMARY_AND_PAYMENT_PAGE),
          langId,
          storeId,
          catalogId
        );
        removeSessionStorage(REQUEST_BY_DATE);
        // When a guest user with a dealer with Tax Credit clicks the register link
        // or submits the form, we save PUD form values to prepopulate registration form.
        // When user successfully comes back to PUD from registration, they should be logged in,
        // so we remove registration session storage on form submit
        if (isLoggedUser) {
          removeSessionStorage('REGISTRATION_FORM_VALUES');
        }
        navigateToUrl(summaryAndPaymentPageURL);
      }
    } catch (error) {
      if (isShoppingCartPage) {
        dispatch(
          setError(
            ERROR_DOMAIN.CART,
            ERROR_PATH.SHOPPING_CART_CRITICAL_ERROR,
            INFO_SHOPPING_CART_CRITICAL_ERROR
          )
        );
      }
      dispatch({ type: PICKUP_DELIVERY_SUBMIT_FAILS, error });
      dispatch(
        setError(
          ERROR_DOMAIN.CHECKOUT,
          ERROR_PATH.PICKUP_DELIVERY_MAIN,
          normalizeError(error)
        )
      );
      const errorMessage =
        error?.response?.data?.errors[0]?.errorParameters?.[0] ?? '';
      setFieldValue('weightLimitErrorDesc', errorMessage);
      closeModal();
    }
  };
};
export const pixPaymentStatusCall =
  (orderId, handlePixPaymentError) => async (dispatch, getState) => {
    const storeId = getState().common.storeId;
    const langId = getState().common.langId;
    const url = replaceTokensInUrl(
      endpoints.PIX_PAYMENT_STATUS,
      storeId,
      orderId,
      langId
    );
    const http = getHttpInstance();
    dispatch({
      type: PIX_PAYMENT_STATUS_BEGIN
    });
    return http
      .get(url)
      .then(response => {
        dispatch({
          type: PIX_PAYMENT_STATUS_SUCCESS,
          payload: response.data
        });
      })
      .catch(() => {
        dispatch({
          type: PIX_PAYMENT_STATUS_FAIL
        });
        handlePixPaymentError();
      });
  };

export const setQuickOrderFlow = (isFromQuickOrder = false) => ({
  type: QUICK_ORDER_CHECKOUT,
  payload: { isFromQuickOrder }
});
export const buildShareCartLink =
  (values, setError, message) => async (dispatch, getState) => {
    const storeId = getState().common.storeId;
    const locale = getState().common.locale;
    const storeLocation = getState().common.selectedStore;
    const dealerCode = getState().dealer.dealerCode;
    const current = getState().orders.current;
    const orderInformation = getState().orders?.byId?.[current] || {};
    const endUseCode = orderInformation.endUseCode;
    let parts;
    const isCSR = getState().common.isCatCSR;
    if (!isCSR) {
      parts = orderInformation.ascendingOrderItems;
    } else {
      const currentId = getState().savedListDetailUI?.currentId;
      parts = getState().orders?.byId[currentId]?.orderInformation?.orderItem;
    }

    const partsList = parts?.reduce((prev, current) => {
      const currentObj = {
        sourceOfSupply: current.sourceOfSupply,
        partNumber: current.partNumber,
        quantity: current.quantity,
        ssroBundle: current.orderItemExtendAttribute.ssroBundle
      };
      return [...prev, currentObj];
    }, []);
    const requestValue = {
      dealerCode,
      storeLocation,
      locale,
      endUseCode: endUseCode || '',
      serialNumber: '',
      assetId: '',
      orderType: '',
      partsList: partsList || [],
      equipment: normalizeEquipmentObject(values.equipment),
      campaignId: values.campaignId,
      promoCode: values.promoCode,
      appName: values.appName
    };
    const requestURL = endpoints.CART_IMPORT_BUILD_URL;
    const DEEPLINK_URL = replaceTokensInUrl(requestURL, storeId);
    const http = getHttpInstance();
    dispatch({
      type: BUILD_SHARE_CART_LINK_BEGIN
    });
    return http
      .post(DEEPLINK_URL, requestValue)
      .then(response => {
        dispatch({
          type: BUILD_SHARE_CART_LINK_SUCCESS,
          payload: response.data
        });
        return response.data.redirectUrl;
      })
      .catch(() => {
        dispatch(
          setError(ERROR_DOMAIN.CHECKOUT, ERROR_PATH.SHARE_CART_MODAL, message)
        );
        dispatch({
          type: BUILD_SHARE_CART_LINK_FAIL
        });
      });
  };

export const buildShareCartLinkCSR =
  (values, setError, message) => async (dispatch, getState) => {
    const isCSR = getState().common.isCatCSR;
    if (!isCSR) {
      return;
    }
    const state = getState();

    const storeId = values?.storeId || state.common?.storeId;
    const locale = values?.locale || state.common?.locale;
    const storeLocation = values?.storeLocation || state.common?.selectedStore;
    const equipment = values?.equipment || {};

    const dealerCode = state.dealer?.dealerCode;

    const currentId = state.savedListDetailUI?.currentId;
    const orderInformation =
      state.orders?.byId[currentId]?.orderInformation?.orderItem || {};

    const requestValue = formatCartLinkPayload(
      locale,
      normalizeEquipmentObject(equipment),
      values.campaignId,
      values.promoCode,
      values.appName,
      {
        dealerCode,
        storeLocation,
        endUseCode: orderInformation?.endUseCode,
        partsList: formatCartLinkParts(orderInformation)
      }
    );

    dispatch({
      type: BUILD_SHARE_CART_LINK_BEGIN
    });

    const http = getHttpInstance();
    const url = replaceTokensInUrl(endpoints.CART_IMPORT_BUILD_URL, storeId);
    return http
      .post(url, requestValue)
      .then(response => {
        dispatch({
          type: BUILD_SHARE_CART_LINK_SUCCESS,
          payload: response.data
        });
        return response.data.redirectUrl;
      })
      .catch(() => {
        dispatch(
          setError(ERROR_DOMAIN.CHECKOUT, ERROR_PATH.SHARE_CART_MODAL, message)
        );
        dispatch({
          type: BUILD_SHARE_CART_LINK_FAIL
        });
      });
  };

export const getCatCardPromotions =
  (catCardNumber, catCardZipcode) => async (dispatch, getState) => {
    dispatch(clearError(ERROR_DOMAIN.CHECKOUT, ERROR_PATH.CONVERSION_ERROR));
    const {
      common: { storeId, langId },
      checkout: { selectedBillingMethod, selectedAdditionalBillingMethod }
    } = getState();
    dispatch({ type: CHECKOUT_GET_CAT_CARD_PROMOS_BEGIN });

    const catCardBillingMethod = selectedAdditionalBillingMethod.value.includes(
      BILLING_METHOD_NEW_CAT_CARD
    )
      ? selectedAdditionalBillingMethod
      : selectedBillingMethod;

    const isSavedCatCard = catCardBillingMethod.value.includes(
      BILLING_METHOD_SAVED_CAT_CARD
    );
    const catCardStoredProfileId = isSavedCatCard
      ? catCardBillingMethod?.value.split(BILLING_METHOD_SAVED_CAT_CARD)[1]
      : '';

    const endpointUrl = replaceTokensInUrl(CAT_CARD_PROMOTION, storeId, langId);
    const http = getHttpInstance();
    return http
      .post(endpointUrl, {
        ...(isSavedCatCard && { storedProfileId: catCardStoredProfileId }),
        ...(!isSavedCatCard && {
          accountNumber: catCardNumber,
          postalZipCode: catCardZipcode
        })
      })
      .then(response => {
        const { data } = response;

        dispatch({
          type: CHECKOUT_GET_CAT_CARD_PROMOS_SUCCESS,
          payload: data
        });
      })
      .catch(e => {
        const errorKey =
          e?.response?.data?.errors && e.response.data.errors[0].errorKey;

        dispatch({
          type: CHECKOUT_GET_CAT_CARD_PROMOS_FAIL
        });

        if (errorKey === 'CAT_CREDITS_CURRENCY_CONVERSION_ERROR_MESSAGE') {
          dispatch(
            setError(ERROR_DOMAIN.CHECKOUT, ERROR_PATH.CONVERSION_ERROR, {
              title: 'ERROR_OCCURRED',
              message: 'API_CONVERSION_ERROR_MSG',
              severity: 'error'
            })
          );
        }
      });
  };

export const getCatCardGlobalOrderTotal =
  (catCardNumber, catCardZipcode) => async (dispatch, getState) => {
    const {
      common: { storeId },
      checkout: { selectedBillingMethod, selectedAdditionalBillingMethod }
    } = getState();

    dispatch(clearError(ERROR_DOMAIN.CHECKOUT, ERROR_PATH.CONVERSION_ERROR));

    dispatch({ type: CHECKOUT_GET_CAT_CARD_GLOBAL_ORDER_TOTAL_BEGIN });

    const catCardBillingMethod = selectedAdditionalBillingMethod.value.includes(
      BILLING_METHOD_NEW_CAT_CARD
    )
      ? selectedAdditionalBillingMethod
      : selectedBillingMethod;

    const isSavedCatCard = catCardBillingMethod.value.includes(
      BILLING_METHOD_SAVED_CAT_CARD
    );
    const catCardStoredProfileId = isSavedCatCard
      ? catCardBillingMethod?.value.split(BILLING_METHOD_SAVED_CAT_CARD)[1]
      : '';

    const endpointUrl = replaceTokensInUrl(
      CAT_CARD_GLOBAL_ORDER_TOTAL,
      storeId
    );
    const http = getHttpInstance();
    return http
      .post(endpointUrl, {
        ...(isSavedCatCard && { storedProfileId: catCardStoredProfileId }),
        ...(!isSavedCatCard && {
          accountNumber: catCardNumber,
          postalZipCode: catCardZipcode
        })
      })
      .then(response => {
        dispatch({
          type: CHECKOUT_GET_CAT_CARD_GLOBAL_ORDER_TOTAL_SUCCESS,
          payload: {
            ...response?.data,
            catCardGlobalOrderTotalPayload: {
              ...(isSavedCatCard
                ? {
                    storedProfileId: catCardStoredProfileId
                  }
                : {
                    accountNumber: catCardNumber,
                    postalZipCode: catCardZipcode
                  })
            }
          }
        });
      })
      .catch(e => {
        const errorKey =
          e?.response?.data?.errors && e.response.data.errors[0].errorKey;

        dispatch({
          type: CHECKOUT_GET_CAT_CARD_GLOBAL_ORDER_TOTAL_FAIL
        });

        if (errorKey === 'CAT_CREDITS_CURRENCY_CONVERSION_ERROR_MESSAGE') {
          dispatch(
            setError(ERROR_DOMAIN.CHECKOUT, ERROR_PATH.CONVERSION_ERROR, {
              title: 'ERROR_OCCURRED',
              message: 'API_CONVERSION_ERROR_MSG',
              severity: 'error'
            })
          );
        }
        if (errorKey === 'CAT_CARD_INTL_NOT_ALLOWED') {
          dispatch(
            setError(ERROR_DOMAIN.CHECKOUT, ERROR_PATH.CAT_CARD, {
              key: 'CAT_CARD_INTL_NOT_ALLOWED'
            })
          );
        }
      });
  };

export const clearPromotions = () => ({ type: CHECKOUT_CLEAR_PROMOTIONS });
export const selectCatCardPromotion = (isPromoActive, value = '') => ({
  type: CHECKOUT_SELECT_CAT_CARD_PROMOTION,
  payload: {
    isPromoActive,
    value
  }
});

export const clearCatCreditsBalance = () => ({
  type: CHECK_SALESFORCE_ACCOUNT_BALANCE_CLEAR
});

export const deleteCatCreditsAccount =
  cardNumber => async (dispatch, getState) => {
    const http = getHttpInstance();

    const {
      common: { storeId }
    } = getState();

    const endpointUrl = replaceTokensInUrl(
      endpoints.CAT_CREDITS_DELETE_ACCOUNT,
      storeId
    );

    const requestBody = {
      cardNumber
    };

    dispatch({ type: CHECKOUT_APPLY_CAT_CREDITS_BEGIN });

    return http
      .delete(endpointUrl, { data: requestBody })
      .then(response => {
        dispatch({
          type: CAT_CREDITS_DELETE_ACCOUNT_SUCCESS
        });
        if (response?.data?.success) {
          dispatch(clearCatCreditsBalance());
        }
      })
      .catch(e => {
        dispatch({
          type: CAT_CREDITS_DELETE_ACCOUNT_FAIL
        });

        dispatch(
          setError(
            ERROR_DOMAIN.CHECKOUT,
            ERROR_PATH.CAT_CREDIT_INTERNATIONAL,
            normalizeError(e, {
              key: UNKNOWN_ERROR_MESSAGE,
              message: UNKNOWN_ERROR_MESSAGE
            })
          )
        );
      });
  };

export const getCATSalesForceAccountBalanceData =
  (accountId, savedAccount = false) =>
  async (dispatch, getState) => {
    const storeId = getState().common.storeId;

    const requestURL = endpoints.CAT_SALESFORCE_ACCOUNT_BALANCE;
    const endpointUrl = replaceTokensInUrl(requestURL, storeId);

    const requestBody = {
      accountNumber: accountId,
      savedAccount: savedAccount.toString()
    };
    const http = getHttpInstance();

    dispatch({
      type: CHECK_SALESFORCE_ACCOUNT_BALANCE_BEGIN
    });

    const NO_BALANCE_ERROR = 'CAT_CREDITS_INSUFFICIENT_BALANCE_ERROR_TITLE';

    return http
      .post(endpointUrl, requestBody)
      .then(response => {
        dispatch({
          type: CHECK_SALESFORCE_ACCOUNT_BALANCE_SUCCESS,
          payload: { ...response?.data, cardNumber: accountId }
        });
        return response?.data?.redirectUrl;
      })
      .catch(e => {
        const errorKey =
          e?.response?.data?.errors && e.response.data.errors[0].errorKey;
        const errorMessage =
          e?.response?.data?.errors && e.response.data.errors[0].errorMessage;

        /** The zero balance is handled in the service exception
         * we need to validate the errorKey to make sure the error is about the
         * balance and then we set this to zero '0' to trigger feedback
         */
        const isBalanceZero = errorKey === NO_BALANCE_ERROR;
        const balance = isBalanceZero ? 0 : undefined;

        dispatch({
          type: CHECK_SALESFORCE_ACCOUNT_BALANCE_FAIL,
          payload: {
            balance
          }
        });

        dispatch(
          setError(
            ERROR_DOMAIN.CHECKOUT,
            ERROR_PATH.CAT_CREDIT_INTERNATIONAL,
            normalizeError(e, {
              key: errorKey,
              message: errorMessage
            })
          )
        );
      });
  };

export const applyCatCreditsGlobal = values => async (dispatch, getState) => {
  const http = getHttpInstance(TIMEOUT_EXTENDED);

  const {
    common: { storeId }
  } = getState();

  const url = replaceTokensInUrl(endpoints.CAT_CREDITS_APPLY, storeId);

  dispatch({ type: CHECKOUT_APPLY_CAT_CREDITS_BEGIN });
  return http
    .post(url, values)
    .then(response => {
      const {
        remainingAmount,
        creditAvailable,
        usedAmount,
        remainingAmountForeignCurrency,
        usedAmountForeignCurrency
      } = response.data?.catCreditGlobalAccountResponse || {};
      dispatch({
        type: CHECKOUT_APPLY_CAT_CREDITS_SUCCESS,
        payload: {
          remainingCatCreditsGlobal: remainingAmount,
          totalCatCreditsGlobal: creditAvailable,
          amountUsedCatCreditsGlobal: usedAmount,
          remainingAmountForeignCurrency: remainingAmountForeignCurrency,
          usedAmountForeignCurrency: usedAmountForeignCurrency
        }
      });
    })
    .catch(e => {
      dispatch({
        type: CHECKOUT_APPLY_CAT_CREDITS_FAIL
      });
    });
};

export const saveCatCreditsAccountGlobalExpansion = payload => dispatch => {
  dispatch({
    type: SAVE_CAT_CREDITS_ACCOUNT_GLOBAL_EXPANSION_CHECKBOX,
    payload
  });
};

export const sendConfirmationEmail =
  ({ orderId, registeredEmailList, guestEmailList }) =>
  (dispatch, getState) => {
    const { storeId, langId } = getState().common;
    const http = getHttpInstance();
    dispatch({
      type: SHARE_CONFIRMATION_EMAIL_BEGIN
    });

    const url = replaceTokensInString(
      endpoints.ORDER_CONFIRMATION_EMAIL_NOTIFICATION,
      storeId,
      langId
    );

    return http
      .post(url, { orderId, registeredEmailList, guestEmailList })
      .then(() => {
        dispatch({
          type: SHARE_CONFIRMATION_EMAIL_SUCCESS
        });
      })
      .catch(() => {
        dispatch({
          type: SHARE_CONFIRMATION_EMAIL_FAIL
        });
      });
  };
export const applyCatVantageRewardsToOrder =
  ({
    handleApplyCatVantageRewardsSuccess,
    handleApplyCatVantageRewardsFail,
    amount
  }) =>
  async (dispatch, getState) => {
    const { storeId, langId } = getState().common;
    const http = getHttpInstance();
    dispatch({ type: APPLY_CAT_VANTAGE_REWARDS_TO_ORDER_BEGIN });
    try {
      const response = await http.post(
        replaceTokensInString(
          endpoints.APPLY_CAT_CREDITS_TO_ORDER,
          storeId,
          langId
        ),
        { amount: amount }
      );
      if (response?.data?.orderTotals) {
        dispatch({
          type: APPLY_CAT_VANTAGE_REWARDS_TO_ORDER_SUCCESS,
          payload: {
            orderTotals: response.data.orderTotals
          }
        });
        handleApplyCatVantageRewardsSuccess(amount);
      }
    } catch (error) {
      dispatch({ type: APPLY_CAT_VANTAGE_REWARDS_TO_ORDER_FAIL });
      handleApplyCatVantageRewardsFail();
    }
  };

export default {
  applyCatCreditsGlobal,
  applyCatVantageRewardsToOrder,
  applyPrepaidCertificate,
  applyPromoCode,
  cancelMidtransTransaction,
  midtransPostback,
  getMidtransToken,
  loadPickupAndDeliveryPage,
  loadSummaryPage,
  placeOrder,
  setOrderInformation,
  setSelectedBillingAddress,
  setSelectedAdditionalBillingMethod,
  setSelectedBillingMethod,
  setSelectedAlternativePayment,
  removePromoCode,
  saveAddress,
  savePaymentDetails,
  savePoNumber,
  verifyAddress,
  loadShoppingCartPage,
  setSISValueEmpty,
  getCertificates,
  deletePrepaidCertificate,
  setCatCreditToolTip,
  savedDeliveryAddress,
  pixPaymentStatusCall,
  getCatCardPromotions,
  clearPromotions,
  clearCatCreditsBalance,
  selectCatCardPromotion,
  buildShareCartLink,
  getCATSalesForceAccountBalanceData,
  checkOrderTotalsToSetBillingMethod,
  getCatCardGlobalOrderTotal
};
