import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  getAvailabilityPickupText,
  getAvailabilityTitle,
  getFormattedDate
} from '../Common/util';
import { availability as availabilityPropTypes } from './StoreAvailability.proptypes';
import { useSelector } from 'react-redux';
import TooltipDisclaimer from '../TooltipDisclaimer';
import { AlloyButton, InlineLoader, Popup } from 'cat-ecommerce-alloy';
import ContactDealerPopup from '../../ContactDealerPopup';
import { replaceTokensInString } from '@app/utils';
import { DATE_FNS_LOCALES } from '@app/constants/languageConstants';
import styles from './StoreAvailability.module.scss';
import { useBreakpoint } from '@app/hooks';
import { PAGE_BREAKPOINTS } from '@app/constants/commonConstants';

const AvailabilityNoIntermStock = ({
  hideLabel = false,
  showAdditionalInfo = false,
  className,
  availabilityInformation,
  hoseAssemblyDetails,
  isLoading,
  catEntryId,
  hideHeaderAndDeliveryOptions,
  hideDeliveryCheckoutMessage
}) => {
  const [t] = useTranslation();

  const { inStock, contactDealer, byDate } = availabilityInformation;
  const isMD = useBreakpoint(PAGE_BREAKPOINTS.MD);
  const cellStyles = 'text-start m-0 ps-2 ps-lg-1';
  const availability_cell_styles = `text-start m-0 ${
    hideHeaderAndDeliveryOptions ? 'ps-2' : 'ps-0'
  } ps-lg-1`;
  const cellStylesBold = 'fw-bold';
  const isDeliveryEnabled = useSelector(
    state => state.common.isDeliveryEnabled
  );
  const locale = useSelector(state => state.common.locale);
  const isQuantityMergeEnabled = useSelector(
    s => s?.common?.isQuantityMergeEnabled
  );
  const isPickUpEnabled = useSelector(state => state.dealer.isPickupEnabled);
  const availabilityPickupTextClassName =
    !hideHeaderAndDeliveryOptions && !isMD
      ? `${styles['availability_delivery_message']}`
      : '';
  const popperOptions = {
    placement: 'bottom',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top', 'bottom'],
          rootBoundary: 'document'
        }
      }
    ]
  };
  const getLabel = () =>
    !hideLabel &&
    (hideHeaderAndDeliveryOptions || !isMD) && (
      <div className="d-flex align-items-center justify-content-start mb-2">
        <span
          className={`text-sm ${hideHeaderAndDeliveryOptions && 'text-uppercase'} text-univers--bold u-color--ExtraDarkGray m-0`}
        >
          {getAvailabilityTitle(isDeliveryEnabled, isPickUpEnabled, t)}
          {!hideHeaderAndDeliveryOptions && !isQuantityMergeEnabled && (
            <sup>&dagger;</sup>
          )}
        </span>
        <TooltipDisclaimer />
      </div>
    );
  const getHoseAssemblyDetails = () => {
    if (hoseAssemblyDetails.hoseAssemblyIndicator) {
      const hoseLabel = hoseAssemblyDetails.hoseAvailabilityLabel?.trim();
      const hoseDetails = hoseLabel || t('HOSE_DEFAULT_MESSAGE');
      return (
        <span className={'cat-u-theme-typography-body-sm text-sans-serif'}>
          {hoseDetails}
        </span>
      );
    }
  };

  const normalizeString = str => (str ?? '')?.toString().trim();

  const msg = contactDealer && normalizeString(contactDealer.message);

  const getDealerInitiator = msg => (
    <AlloyButton buttonType="link" className="p-0 text-start">
      {msg || t('CONTACT_YOUR_DEALER')}
    </AlloyButton>
  );
  const getPopupMessage = (
    showContactDealerOnly,
    msg,
    catEntryId,
    popperOptions
  ) => {
    return showContactDealerOnly || !msg ? (
      <>
        <div className="d-flex h-100">
          <Popup
            contents={<ContactDealerPopup catEntryId={catEntryId} />}
            initiator={getDealerInitiator(msg)}
            className={`${styles['availability-contact-dealer__popup']}`}
            popperOptions={popperOptions}
          />
        </div>
        <div
          className={`${styles['print__availability-text']} d-none d-print-block`}
        >
          {msg || t('CONTACT_YOUR_DEALER')}
        </div>
      </>
    ) : (
      <div className={`${styles['print__availability-text']}`}>{msg}</div>
    );
  };
  const getAvailabilityTable = () =>
    (byDate || contactDealer || inStock) && (
      <>
        {getLabel()}
        {hoseAssemblyDetails.hoseAssemblyIndicator ? (
          getHoseAssemblyDetails()
        ) : (
          <table data-testId="store__availability-table">
            <tbody className="cat-u-theme-typography-body-sm text-sans-serif">
              {inStock && (
                <tr>
                  <td
                    className={cx(
                      inStock && hideHeaderAndDeliveryOptions && cellStylesBold,
                      'd-flex h-100'
                    )}
                  >
                    <div
                      className={cx(
                        availability_cell_styles,
                        styles['print__availability-text'],
                        !hideHeaderAndDeliveryOptions &&
                          `${styles['availability_inStock_message']} pb-lg-1`,
                        !isMD && 'pb-1'
                      )}
                    >
                      {inStock.quantity}
                    </div>
                    <div
                      className={cx(
                        cellStyles,
                        styles['contact-button'],
                        styles['print__availability-text']
                      )}
                    >
                      <div className={cx(styles['print__availability-text'])}>
                        {inStock.message}
                      </div>
                    </div>
                  </td>
                </tr>
              )}
              {byDate &&
                byDate.map((item, index) => (
                  <tr key={`${item.quantity}-${item.date || 'contact-dealer'}`}>
                    <td
                      className={cx(!inStock && cellStylesBold, 'd-flex h-100')}
                    >
                      <div
                        className={cx(
                          availability_cell_styles,
                          styles['contact-button'],
                          styles['print__availability-text'],
                          !isMD && 'pb-1'
                        )}
                      >
                        {item.date && (
                          <div
                            className={cx(
                              styles['print__availability-text'],
                              !hideHeaderAndDeliveryOptions &&
                                styles['availability_message']
                            )}
                          >
                            {contactDealer === null
                              ? replaceTokensInString(
                                  t('AVAIL_STANDARD_ALL_PICKUP'),
                                  item.quantity,
                                  getFormattedDate(item.date, 'MMM', {
                                    locale: DATE_FNS_LOCALES[locale]
                                  }),
                                  getFormattedDate(item.date, 'dd')
                                )
                              : replaceTokensInString(
                                  t('AVAIL_STANDARD_SOME_PICKUP'),
                                  item.quantity,
                                  getFormattedDate(item.date, 'MMM', {
                                    locale: DATE_FNS_LOCALES[locale]
                                  }),
                                  getFormattedDate(item.date, 'dd')
                                )}
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              {contactDealer && (
                <tr>
                  <td className="d-flex h-100">
                    <div
                      className={cx(
                        availability_cell_styles,
                        styles['print__availability-text'],
                        !hideHeaderAndDeliveryOptions &&
                          styles['availability_message']
                      )}
                    >
                      {!(
                        contactDealer.message === 'Replaced Contact Dealer' ||
                        contactDealer.message === 'Indirect Replacement'
                      ) && contactDealer.quantity}
                    </div>
                    <div
                      className={cx(
                        cellStyles,
                        styles['contact-button'],
                        styles['print__availability-text']
                      )}
                    >
                      {getPopupMessage(
                        contactDealer,
                        msg,
                        catEntryId,
                        popperOptions
                      )}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </>
    );

  return (
    <div className={className}>
      {isLoading ? (
        <>
          {getLabel()}
          <InlineLoader />
        </>
      ) : (
        getAvailabilityTable()
      )}
    </div>
  );
};

AvailabilityNoIntermStock.propTypes = {
  className: PropTypes.string,
  catEntryId: PropTypes.string,
  hideLabel: PropTypes.bool,
  isLoading: PropTypes.bool,
  availabilityInformation: availabilityPropTypes,
  hoseAssemblyDetails: PropTypes.shape({
    hoseAssemblyIndicator: PropTypes.bool,
    hoseAvailabilityLabel: PropTypes.string
  }),
  showAdditionalInfo: PropTypes.bool
};

export default AvailabilityNoIntermStock;
