import useBuyOnBehalf from '../../../pages/csr-landing/hooks/useBuyOnBehalf';
import endpoints from '@app/constants/endpoints';
import links from '@app/constants/links';
import queryString from 'query-string';
import * as React from 'react';
import { getHttpInstance } from '@app/services/defaultHttpService';
import { isDev, replaceTokensInString } from '@app/utils';
import { useLocation } from 'react-router';

import { useSelector } from 'react-redux';
import { idleTimer } from '../CSRBanner/utils';
import {
  TIMEOUT_EXTENDED,
  LOCALHOST_CONTEXT_PREFIX,
  USER_TYPE_GUEST
} from '@app/constants/commonConstants';
import CSRBannerStripWhite from './CSRBannerStripWhite';
import { CatButton } from '@blocks-react/components/Button';
import { CSR_TEXTS } from '@app/components/pages/csr-landing/texts';
import cx from 'classnames';
import styles from './CSRBannerBlocksWhite.module.scss';
import { CatIconWarningTriangle } from '@blocks-react/components/Icons/WarningTriangle';
import { AssociatedDealerModal } from '@app/components/associated-dealer-block';
import { MODALS_ROOT } from '@app/constants/targets';
import { Portal } from '@cat-ecom/pcc-components';

const CSRBannerBlocksWhite = () => {
  const searchParams = useLocation().search;
  const { orderId } = queryString.parse(searchParams);
  const { firstName, lastName, catalogId, langId, storeId } = useSelector(
    s => s.common
  );
  const isInstantAccess = useSelector(s => s.common.isInstantAccess) || false;
  const isBuyOnBehalf = useSelector(s => s.common.isBuyOnBehalf) || false;
  const isPCCCheckoutForCSR =
    useSelector(s => s.featureFlag?.PCCCheckoutForCSR) ?? true;
  const isCSPCustomer = useSelector(s => s.common.isCSPCustomer) || false;

  const CSR_USER_TYPE_TEXT = isCSPCustomer
    ? CSR_TEXTS.CSR_BANNER_VERIFIED_USER
    : isInstantAccess
      ? CSR_TEXTS.CSR_BANNER_INSTANT_ACCESS_USER
      : null;

  const userType = useSelector(s => s?.orders?.byId[orderId]?.userType);
  const orderCreatedBy = useSelector(
    s => s?.orders?.byId[orderId]?.orderCreatedBy
  );
  const { CSR_LANDING_PAGE } = links;
  const { search } = useLocation();
  const [csrActive, setCsrActive] = React.useState(null);

  // The feature flag is true because the flag PCC_CustomerSupportCSRBannerMessaging
  // already must be activated to show this component
  const { buyOnBehalfParameters, dealerModalProps, handleConnectToCustomer } =
    useBuyOnBehalf(true);

  const disconnect = async () => {
    const http = getHttpInstance(TIMEOUT_EXTENDED);
    const url = endpoints.CSR_TABLE_RESET_CUSTOMER_SELECTION;
    try {
      const response = await http.get(url);
      let redirectionRoute = JSON.parse(
        response.data.replace('*/', '').replace('/*', '')
      );
      if (response) {
        sessionStorage.removeItem('isGuest');
        if (isDev()) {
          window.location.href = `${LOCALHOST_CONTEXT_PREFIX}${redirectionRoute.url}`;
        } else {
          window.location.href = redirectionRoute.url;
        }
      }
    } catch (e) {
      sessionStorage.removeItem('_userInactive');
    }
  };

  const onBackToOrdersForm = () => {
    const url = replaceTokensInString(
      CSR_LANDING_PAGE,
      catalogId,
      langId,
      storeId
    );

    window.location.href = url;
  };

  const isFromCSROrderSearch = sessionStorage.getItem('fromCSR');
  const isTransactingAsGuest = sessionStorage.getItem('isGuest');
  const isGuestOrder = userType === USER_TYPE_GUEST;

  const showOrderDetailBanner =
    search.includes('OrderDetail') || search.includes('OrderReturnRequest');

  React.useEffect(() => {
    if (showOrderDetailBanner) {
      setCsrActive(!!isFromCSROrderSearch);
    }
  }, [isFromCSROrderSearch, showOrderDetailBanner]);

  React.useEffect(() => {
    if (isBuyOnBehalf) {
      const timer = idleTimer({
        timeout: 900,
        onTimeout: () => disconnect()
      });

      return () => {
        timer.cleanUp();
      };
    }
  }, [isBuyOnBehalf]);

  const onDisconnect = async () => {
    disconnect();
  };

  const handleOptionClick = () => {
    handleConnectToCustomer(orderCreatedBy);
  };

  if (!isBuyOnBehalf && !csrActive) {
    return null;
  }

  if (isBuyOnBehalf) {
    return (
      <CSRBannerStripWhite>
        <div className={cx(styles['CSRBannerStrip__user_info'])}>
          <div className={cx(styles['CSRBannerStrip__user_info__name'])}>
            {isTransactingAsGuest
              ? `${CSR_TEXTS.GUEST_CUSTOMER}`
              : `${firstName ?? ''} ${lastName ?? ''}`}
          </div>
          <div className={cx(styles['CSRBannerStrip__user_info__user_box'])}>
            {!isPCCCheckoutForCSR && (
              <>
                <div
                  className={cx(
                    styles['CSRBannerStrip__user_info__user_alert']
                  )}
                >
                  <CatIconWarningTriangle
                    sm="sm"
                    color="error"
                    slot="before"
                    data-testid="error-icon"
                  />
                  <div>{CSR_TEXTS.CSR_BANNER_RESTRICTIONS_APPLY}</div>
                </div>
                {!isTransactingAsGuest && (
                  <div
                    className={cx(styles['CSRBannerStrip__user_info__devider'])}
                  ></div>
                )}
              </>
            )}
            {!isTransactingAsGuest && (
              <div
                className={cx(styles['CSRBannerStrip__user_info__user_type'])}
              >
                {CSR_USER_TYPE_TEXT}
              </div>
            )}
          </div>
        </div>
        <CatButton variant="danger" size="sm" onClick={onDisconnect}>
          <React.Fragment>
            {!isTransactingAsGuest
              ? CSR_TEXTS.DISCONNECT_FROM_ACCOUNT_BTN
              : CSR_TEXTS.DISCONNECT_FROM_CART_BTN}
          </React.Fragment>
        </CatButton>
      </CSRBannerStripWhite>
    );
  } else if (csrActive) {
    return (
      <>
        <Portal id={MODALS_ROOT}>
          <AssociatedDealerModal
            modal={dealerModalProps}
            buyOnBehalfParameters={buyOnBehalfParameters}
          />
        </Portal>
        <CSRBannerStripWhite>
          <CatButton variant="contrast" onClick={onBackToOrdersForm}>
            <React.Fragment>
              {CSR_TEXTS.ORDER_DETAIL_DISCONNECT_BTN}
            </React.Fragment>
          </CatButton>
          <CatButton
            variant="contrast"
            disabled={isGuestOrder ? 'disabled' : ''}
            onClick={!isGuestOrder ? handleOptionClick : null}
          >
            <React.Fragment>{CSR_TEXTS.CONNECTO_TO_CUSTOMER}</React.Fragment>
          </CatButton>
        </CSRBannerStripWhite>
      </>
    );
  }
};

export default CSRBannerBlocksWhite;
