import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { isEmpty, replaceTokensInString } from '@app/utils';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Drawer,
  MyEquipmentHeader,
  MyEquipmentSearch,
  MyEquipmentButton,
  MyEquipmentList,
  ErrorBanner
} from '@cat-ecom/pcc-components';
import { CatHeading } from 'blocks-react/bedrock/components/Heading';
import { getFilterList } from '@app/utils/commonUtils';
import {
  selectEquipment,
  setEquipmentToast
} from '@app/store/myequipment/actions';
import { capitalizeText } from '@app/utils/stringUtils';
import useEquipmentDrawer from '@app/hooks/useEquipmentDrawer';
import { ALP_HOW_TO_VIDEOS } from '@app/constants/commonConstants';
import Conditional from '@app/components/common/Conditional';
import CapitalizeText from '@app/components/common/Text/CapitalizeText';
import {
  EQUIPMENT_METHOD,
  FORM_FIELD_SERIAL,
  SELECTED_EQUIPMENT_IMAGE_SIZE,
  EQUIPMENT_LIST_LIMIT
} from '../constant';
import LoaderIndicator from '../Loader/LoaderIndicator';
import styles from './SelectEquipmentDrawer.module.scss';
import { CatIconInfo } from 'blocks-react/bedrock/components/Icons/Info';
import ErrorBannerMessage from '../ErrorBanner/ErrorBanner';

const SelectEquipmentDrawer = ({
  drawerProps,
  onDrawerClose,
  myEquipmentList,
  isChild
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const isAlpVideoEnable = pathname.includes('AlpMaintenanceVideosView');

  const closeSelect = useCallback(() => {
    onDrawerClose(isChild);
  }, [isChild, onDrawerClose]);

  const { openAddDrawer, getDrawerComponent } = useEquipmentDrawer({
    onNestedDrawerClose: closeSelect
  });
  const [searchInput, setSearchInput] = useState('');
  const [equipments, setEquipments] = useState(myEquipmentList);
  const selectedEquipment = useSelector(
    s => s.myEquipment.equipments.selectedEquipment
  );
  const seoURL = useSelector(s => s?.dealer?.seoURL);
  const storeId = useSelector(s => s.common.storeId);
  const isLoading = useSelector(state => state.myEquipment.isLoading);

  const hasSelectedEquipment =
    !isEmpty(selectedEquipment) && !!selectedEquipment.model;
  const equipmentList = useMemo(() => {
    return hasSelectedEquipment
      ? [selectedEquipment].concat(
          myEquipmentList.filter(
            equip => equip.uniqueIndex !== selectedEquipment.uniqueIndex
          )
        )
      : myEquipmentList;
  }, [hasSelectedEquipment, myEquipmentList, selectedEquipment]);

  const placeholderText = `${t('MEQ_SEARCH_TEXT')} (${myEquipmentList.length})`;

  useEffect(() => {
    setEquipments(equipmentList);
  }, [equipmentList]);

  const searchEquipment = searchTerm => {
    setSearchInput(searchTerm);
    const filteredList = getFilterList(equipmentList, searchTerm);
    setEquipments(filteredList);
  };
  const drawerTitle = hasSelectedEquipment
    ? t('CHANGE_EQUIPMENT')
    : t('SELECT_EQUIPMENT_FROM_LIST');

  const onClose = useCallback(
    (_e = null, closeParent = false) => {
      onDrawerClose(closeParent);
    },
    [onDrawerClose]
  );

  const handleEquipment = equipment => {
    const { model = '', equipmentFamily = '' } = equipment;
    const partNumber = equipment?.seoUrl?.split('/').pop();
    const url = replaceTokensInString(
      ALP_HOW_TO_VIDEOS.alp_Videos_path,
      seoURL,
      partNumber,
      storeId
    );
    const toastContent = replaceTokensInString(
      t('CURRENTLY_SHOPPING'),
      model,
      capitalizeText(equipmentFamily)
    );
    const selectSuccess = () => {
      dispatch(
        setEquipmentToast({
          method: EQUIPMENT_METHOD.SELECT,
          message: toastContent
        })
      );
    };
    onClose(null, isChild);

    dispatch(
      selectEquipment(
        equipment,
        toastContent,
        selectSuccess,
        null,
        isAlpVideoEnable ? url : `${equipment.seoUrl}`
      )
    );
  };

  const searchSuggestion = searchInput
    ? equipments.length > EQUIPMENT_LIST_LIMIT
    : false;
  return (
    <>
      <div id={styles['select-equipment']}>
        <Drawer {...drawerProps}>
          <MyEquipmentHeader
            variant={'title-sm'}
            className="d-flex"
            onBackButtonClick={isChild && hasSelectedEquipment ? onClose : null}
          >
            <span>{drawerTitle}</span>
          </MyEquipmentHeader>
          <div className="mx-n3">
            <ErrorBannerMessage />
          </div>
          <Conditional
            test={!isLoading}
            fallback={
              <LoaderIndicator
                loaderClassname={`${styles['loader-container']} text-center`}
              />
            }
          >
            {hasSelectedEquipment ? (
              <>
                <CatHeading variant="title-sm">
                  {t('CURRENTLY_SHOPPING_FOR')}:
                </CatHeading>
                <div className="d-flex mt-3 mb-3 pb-1">
                  <figure className="mb-0 me-3">
                    <img
                      src={`${selectedEquipment.imageURL}${SELECTED_EQUIPMENT_IMAGE_SIZE.DESK}`}
                      alt={`${selectedEquipment.model} ${selectedEquipment.equipmentFamily}`}
                    />
                  </figure>
                  <div>
                    <CatHeading variant="title">
                      {`${selectedEquipment.model} `}
                      <CapitalizeText
                        text={selectedEquipment.equipmentFamily}
                      />
                    </CatHeading>
                    <CatHeading variant="body">
                      {selectedEquipment.serialNumber}
                    </CatHeading>
                  </div>
                </div>
              </>
            ) : (
              <div className="mx-n3 mt-n3 mb-3 pb-1">
                <ErrorBanner
                  heading={t('NO_EQUIPMENT_SELECTED')}
                  variant="default"
                >
                  <CatIconInfo slot="before" />
                  {t('SELECT_EQUIPMENT_FROM_LIST_MSG')}
                </ErrorBanner>
              </div>
            )}

            <div className="d-flex justify-content-end">
              <MyEquipmentButton onClick={() => openAddDrawer(null, true)}>
                {t('GA_LABEL_ADD_EQUIPMENT')}
              </MyEquipmentButton>
            </div>
            <div className="mt-3 pt-1">
              <MyEquipmentSearch
                placeholder={placeholderText}
                name={FORM_FIELD_SERIAL}
                onSearch={searchTerm => searchEquipment(searchTerm)}
                dataCsMask
              />
            </div>
            {!isEmpty(equipments) ? (
              <div className="pt-3">
                <MyEquipmentList
                  list={equipments}
                  searchTerm={searchInput}
                  selectedEquipment={selectedEquipment}
                  onEqpuipmentSelect={equip => handleEquipment(equip)}
                />
              </div>
            ) : (
              <div className="mt-3">
                <CatHeading variant="label">
                  {t('MEQ_NO_EXACT_MATCH')}
                </CatHeading>
              </div>
            )}
            <Conditional test={searchSuggestion}>
              <div className="mt-3 text-center">
                <CatHeading variant="label">
                  {t('MEQ_SERIAL_TRY_REFINING')}
                </CatHeading>
              </div>
            </Conditional>
          </Conditional>
        </Drawer>
      </div>
      {getDrawerComponent()}
    </>
  );
};

SelectEquipmentDrawer.propTypes = {
  drawerProps: PropTypes.object,
  myEquipmentList: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.string]))
  ),
  onDrawerClose: PropTypes.func,
  isChild: PropTypes.bool
};

export default SelectEquipmentDrawer;
