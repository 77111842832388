import { isDev } from '@app/utils';
import { replaceTokensInString } from '@app/utils/stringUtils';
const BFF_PREFIX = isDev()
  ? 'http://localhost:4200/'
  : `${window.location.origin}/`;

/**
 * NOTE: replaceTokensInString should be imported from stringUtils directly here
 * instead of '@app/utils'. The reason for this is because endpoints.js is imported
 * by server-handlers.js which is used during 'setupFilesAfterEnv' for setting up MSW.
 * If '@app/utils' is referrenced here instead it causes a bunch of tess to fail. This
 * is because importing a module in a setup file (as specified by setupFilesAfterEnv)
 * will prevent mocking for the module in question, as well as all the modules that it
 * imports.
 *
 * @see server-handlers.js
 * @see https://jestjs.io/docs/jest-object#jestmockmodulename-factory-options
 * @see https://github.com/facebook/jest/issues/2582#issuecomment-951982480
 */

const endpoints = {
  ADD_QO_TO_CART: '/wcs/resources/store/{0}/cart/addItems',
  ADDRESS_SAVE: '/wcs/resources/store/{0}/person/@self/contact',
  ADDRESS_VERIFICATION: '/wcs/resources/store/{0}/avi',
  APPLY_PREPAID_CERTIFICATE: '/wcs/resources/store/{0}/xorder/@self/catPrepaid',
  BEST_SELLER: `${BFF_PREFIX}api/product/best-seller?langId={0}&storeId={1}&storeIdentifier={2}&salesModel={3}&category={4}&serialNumber={5}`,
  PART_DIAGRAM: `${BFF_PREFIX}api/parts-diagram?serialNumberPrefix={0}&partManualMediaNumber={1}&includeInformation={2}&beginRange={3}&endRange={4}&lang={5}`,
  MACHINE_SEARCH: `${BFF_PREFIX}api/machine/search?locale={0}&storeIdentifier={1}&searchTerm={2}&filterBy={3}`,
  CAT_MOBILE_INSPECT: 'catinspect://fleetAsset?serialNumber={0}',
  APPROVE_REJECT_ORDER:
    '/wcs/resources/xorder/v2/calInfo?storeId={0}&catalogId={1}&langId={2}&orderId={3}&approvalStatusId={4}',
  DELETE_PREPAID_CERTIFICATE:
    '/wcs/resources/store/{0}/xorder/@self/catPrepaid/{1}',
  PRODUCT_BEST_SELLERS:
    ' /wcs/resources/store/{0}/v1/products/best-sellers?langId={1}&pageSize={2}',
  PRODUCT_FEATURED:
    ' /wcs/resources/store/{0}/v1/products/featured-products?langId={1}&pageSize={2}',
  LOAD_PAYMENT_METHODS: '/wcs/resources/store/{0}/person/@self/checkoutProfile',
  DELETE_PAYMENT_METHOD: '/webapp/wcs/stores/servlet/removeCreditCard',
  CART_IMPORT: `/wcs/resources/store/{0}/deeplink/@self`,
  CART_IMPORT_BUILD_URL: '/wcs/resources/store/{0}/deeplink/cart/items',
  CART_IMPORT_DATA:
    '/wcs/resources/store/{0}/deeplink/getDeepLinkDataByDeepLinkURL',
  CAT_SSOREDIRECT_URL: `/wcs/resources/store/{0}/dealer-association/ssoRedirect/@self`,
  CAT_CREDITS_APPLY: `/wcs/resources/store/{0}/xorder/@self/catCreditsGlobal`,
  CAT_CARD_PROMOTION:
    '/wcs/resources/store/{0}/xorder/@self/catCard/promotion?langId={1}',
  CAT_CARD_GLOBAL_ORDER_TOTAL:
    '/wcs/resources/store/{0}/xorder/@self/catCardGlobal',
  CAT_SALESFORCE_ACCOUNT_BALANCE:
    '/wcs/resources/store/{0}/xorder/@self/catCreditsGlobal/balance',
  CAT_CREDITS_DELETE_ACCOUNT:
    '/wcs/resources/store/{0}/xorder/@self/catCreditsGlobal/account',
  CATEGORY_URL_TOKEN:
    '/wcs/resources/store/{0}/seo/urltoken/byCategory?token={1}',
  CATEGORY_DETAILS:
    '/wcs/resources/store/{0}/v1/products/byCategory/{1}?langId={2}',
  CSR_AUDIT_SUMMARY: '/wcs/resources/store/{0}/csrAuditSummary/@self',
  CONFIRM_PAYMENT_METHOD: '/webapp/wcs/stores/servlet/AjaxRemoveToken',
  CHECKOUT_PICKUP_AND_DELIVERY_OPTIONS: `/webapp/wcs/stores/servlet/pickupAndDeliveryOptions?storeId={0}&catalogId={1}&langId={2}`,
  CHECKOUT_DELIVERY_OPTIONS: `/wcs/resources/store/{0}/dealer-customer/@self/fulfillment/delivery?langId={1}`,
  CHECKOUT_DROPBOX_OPTIONS: `/wcs/resources/store/{0}/dealer-customer/@self/fulfillment/dropbox?langId={1}`,
  CHECKOUT_PICKUP_OPTIONS: `/wcs/resources/store/{0}/dealer-customer/@self/fulfillment/pickup?langId={1}`,
  CHECKOUT_PICKUP_AND_DELIVERY_SAVE: `/wcs/resources/xorder/v2/@self/fulfillment?storeId={0}&langId={1}&responseFormat=json`,
  SUMMARY_AND_PAYMENT_PAGE: `SingleShipmentOrderSummaryView?langId={0}&storeId={1}&catalogId={2}`,
  CHECKOUT_ORDER_DETAILS:
    '/wcs/resources/store/{0}/xorder/@self?langId={1}&sortBy=desc&cartItems={2}',
  CHECKOUT_SUMMARY_PAYMENT_INFO: 'paymentInfo',
  CHECKOUT_SUPPORTED_PAYMENT_AND_BILLING_INFO: `/wcs/resources/store/{0}/xorder/supportedPaymentAndBillingInfo?langId={1}&catalogId={2}&orderId={3}`,
  CHECKOUT_TAX_AND_FREIGHT: `/wcs/resources/xorder/v2/taxAndFreight?storeId={0}&langId={1}&orderId={2}&responseFormat=json`,
  DEEPLINK_HISTORY_DOWNLOAD_LINK: `/webapp/wcs/stores/servlet/CATCommerceDeepLinkExportHistoryCmd`,
  CHECKOUT_SUMMARY_ADDRESS_FORM_INFO: '/wcs/resources/store/{0}/dealer/@self',
  CHECKOUT_APPLY_PROMO:
    '/wcs/resources/store/{0}/catcommercecart/@self/assigned_promotion_code?langId={1}&responseFormat=json',
  CHECKOUT_REMOVE_PROMO:
    '/wcs/resources/store/{0}/catcommercecart/@self/assigned_promotion_code/{1}',
  CHECKOUT_SUMMARY_UPDATE_ORDER: '/wcs/resources/store/{0}/ordextattr', // ?responseFormat=json gets added in action
  CHECKOUT_SUMMARY_PONUMBER: '/webapp/wcs/stores/servlet/AjaxOrderPurchaseInfo',
  CHECKOUT_MIDTRANS_GETTOKEN: `/wcs/resources/store/{0}/midtrans/token`,
  CHECKOUT_MIDTRANS_FINALIZE_PAYMENT: `/wcs/resources/store/{0}/midtrans/postback`,
  CHECKOUT_PIX_PAYMENT: `/wcs/resources/store/{0}/pixpayment?langId={1}`,
  CITY_STATE_FROM_ZIPCODE: `/wcs/resources/store/{0}/avi/get-city-by-zipcode/{1}?country={2}&responseFormat=json`,
  CONTACT_US_DEALER:
    '/CATContactUsDisplayView?catalogId={0}&langId={1}&storeId={2}',
  CONTACT_US_INQUIRY: '/wcs/resources/store/{0}/contactus',
  CUSTOMER_SEGMENTATION: `/wcs/resources/store/{0}/getCustomerSegmentInfo`,
  CYBERSOURCE_IFRAME: `CATPGRedirectCmd`,
  DEALER_ASSOCIATIONS: `/wcs/resources/store/{0}/dealer-association/@self?langId={1}`,
  DEALER_ASSOCIATIONS_CSR: `/wcs/resources/store/{0}/dealer-association/{1}`,
  DEALER_CUSTOMER_SERVICE: `/wcs/resources/store/{0}/dealer-customer/{1}?langId={2}`,
  DEALER_CUSTOMER_ORG_SET_IN_SESSION: `/webapp/wcs/stores/servlet/dealercustomerOrgSetInSesion?storeId={0}`,
  DEALER_FIELDS_INFO: `/wcs/resources/store/{0}/dealer/@self?langId={1}`,
  DEALER_ORGANIZATION_SET_IN_SESSION:
    '/webapp/wcs/stores/servlet/dealercustomerOrgSetInSesion?storeId={0}',
  DEALER_USER_SEARCH: `/wcs/resources/store/{0}/dealer-customer`,
  DELETE_EXPIRED_CART_LIST: `/wcs/resources/csr/v1/cartLists/expired?csrUserId={0}&storeId={1}`,
  DELETE_PENDING_ORDER:
    '/wcs/resources/xorder/v2/calInfo?storeId={0}&catalogId={1}&langId={2}&orderId={3}&approvalStatusId={4}',
  EQUIPMENT_SEARCH: `/webapp/wcs/stores/servlet/equipmentSearch?searchTerm={0}&catalogId={1}&langId={2}&storeId={3}`,
  EQUIPMENT_SEARCH_REST: `/wcs/resources/store/{0}/equipmentSearch?searchTerm={1}&catalogId={2}&langId={3}&storeId={0}&filter={4}`,
  EXPORT_CORE_TRACKING: `/wcs/resources/store/{0}/export/coreTracking?customerNumber={1}&storeNumber={2}&startDate={3}&endDate={4}&partNumber={5}&documentNumber={6}&serialNumber={7}&orderNumber={8}&sortBy={9}&orderBy={10}&fileType={11}&catalogId={12}&storeId={0}&langId={13}`,
  FEATURE_INFORMATION: `/wcs/resources/store/{0}/catstoreconf/featureInformation?featureNames={1}`,
  //FOOTER LINKS FIND PARTS
  FOOTER_LINK_SHOP_BY_CATEGORY: `{0}/ShopAllCategories`,
  FOOTER_LINK_PART_MANUAL: `{0}/PartLookup`,
  FOOTER_LINK_ABOUT_CAT_PARTS: `{0}/about`,
  FOOTER_LINK_SITE_MAP: `{0}/sitemap`,
  //FOOTER LINKS SUPPORT
  FOOTER_LINK_HELP_CENTER: `{0}/help/faq`,
  FOOTER_LINK_WARRANTY_AND_RETURN: `{0}/warrantyandreturns`,
  //FOOTER LINKS ACCOUNT
  GET_SOS_CODE_URL: `/wcs/resources/store/{0}/getDealerSourceOfSupplyDetails`,
  DEALER_SOS_OPTIONS_URL: `/wcs/resources/store/{0}/dealer/sourceOfSupply?locale={1}`,
  GOOGLE_MAPS_DRIVING_DIRECTIONS:
    'https://www.google.com/maps/dir/?api=1&destination={0}%2C{1}',
  GET_FBT_ITEMS: `${BFF_PREFIX}api/product/fbt`,
  GET_JOBS_RECOMMENDATIONS: `${BFF_PREFIX}api/product/jobs-recommendations`,
  GET_MY_EQUIPMENT: `/wcs/resources/store/{0}/getMyEquipmentData/@self?langId={1}&catalogId=15001`,
  GET_VL_ASSET: `/wcs/resources/store/{0}/getMyEquipmentData/telematicInfo/asset?serialNumber={1}`,
  GET_VL_ASSET_SUMMARY: `/wcs/resources/store/{0}/getMyEquipmentData/telematicinfo/assetSummary?assetId={1}`,
  GET_SOLR_SUGGESTIONS: `/webapp/wcs/stores/servlet/searchSuggestions`,
  GET_LUCID_SUGGESTIONS: `${BFF_PREFIX}api/search/suggestions`,
  GET_PART_DIAGRAM: `${BFF_PREFIX}api/parts-diagram`,
  SUMMARY_API: `${BFF_PREFIX}api/product/summary?locale={0}&storeIdentifier={1}&storeId={2}`,
  SUB_TOTAL: `${BFF_PREFIX}api/subtotal?locale={0}&storeId={1}`,
  SR_USER_STATE_UPDATE: `${BFF_PREFIX}api/srUserStateUpdate?id={0}`,
  GET_LANGUAGES: `/wcs/resources/store/{0}/catcommerce/languages`,
  GET_STORE_CONFIGURATION: '/wcs/resources/store/{0}/catstoreconf/{1}',
  HEADER_INFO_SERVICE:
    '/wcs/resources/store/{0}/header/navigationMenu?langId={1}',
  HEADER_WAFFLE_SERVICE:
    '/wcs/resources/store/{0}/catCommerceWaffle/menuItems?locale={1}',
  HELP_CENTER: 'https://cat-crm.force.com/HelpCenter/s/faqs?language={0}',
  HELP_CENTER_DEV:
    'https://catcrm--crmiuat.sandbox.my.site.com/HelpCenter/s/faqs?language={0}',
  INVOICES_DETAIL_EXPORT_LINK: `/wcs/resources/store/{0}/invoices/invoiceDetails/export/@self?invoiceNumber={1}&langId={2}&format={3}&orderNumber={4}`,
  INVALID_EMAIL_DOMAIN: `/wcs/resources/store/{0}/catstoreconf/B2C_INVALID_EMAIL_DOMAINS?responseFormat=json`,
  KIT_PARTS_DETAILS: `${BFF_PREFIX}api/product/detail?storeIdentifier={0}&locale={1}&partNumber={2}&storeId={3}&langId={4}`,
  EMAIL_OPTIN_SUPPRESSED_COUNTRY_LIST: `/wcs/resources/store/{0}/catstoreconf/EMAIL_OPTIN_SUPPRESSED_COUNTRY_LIST`,
  LOAD_ESPOTS: `/wcs/resources/store/{0}/marketingData?langId={1}&espotName={2}`,
  LOCATION_AUTOSUGGEST: `/wcs/resources/store/{0}/locations/autoSuggest?address={1}`,
  LOCATION_DEALER_INFORMATION: `/wcs/resources/store/{0}/locations/dealer?langId={1}&address={2}&extendedList={3}`,
  GUEST_LOCATION_DEALER_INFORMATION: `/wcs/resources/store/{0}/locations/dealer?langId={1}&latitude={2}&longitude={3}&extendedList={4}`,
  LOGOFF: 'Logoff',
  LOAD_DEALER_REGISTRATION_PAGE:
    '/wcs/resources/store/{0}/dealer-customer/@self/create-redirect-cookie',
  PAYU_RUSSIA_SCRIPT: `https://js.paymentsos.com/v2/latest/secure-fields.min.js`,
  PERSONAL_INFORMATION_SAVE: `/wcs/resources/store/{0}/person/@self?responseFormat=json&action=updateUserRegistration`,
  PLACE_ORDER: '/wcs/resources/store/{0}/placeorder?langId={1}&quote={2}',
  PLACE_PENDING_ORDER:
    '/wcs/resources/store/{0}/placeorder?langId={1}&approvalStatusId={2}',
  PMKIT_EQUIPMENT_DETAILS_URL: `/wcs/resources/store/{0}/pmkitsSoc/equipments?salesModel={1}&langId={2}`,
  PREFERENCES_LOAD:
    '/wcs/resources/store/{0}/userPreferences/@self?responseFormat=json&langId={1}',
  PREFERENCES_MKT_INFO:
    '/wcs/resources/store/{0}/shopPreference/marketingData?langId={1}',
  PREFERENCES_DSN_STORES_LOAD:
    '/wcs/resources/store/{0}/userPreferences/stores?requestType=storeInfo&customerNumber={1}&langId={2}',
  PREFERENCES_UPDATE:
    '/wcs/resources/store/{0}/userPreferences/saveDefaultData?langId={1}',
  UPDATE_LANGUAGE: '/wcs/resources/store/{0}/language?langId={1}',
  PRODUCT_URL_TOKEN:
    ' /wcs/resources/store/{0}/seo/urltoken/byProduct?token={1}',
  PRODUCT_DETAILS: `${BFF_PREFIX}api/product/detail?productId={0}&storeIdentifier={1}&locale={2}&partNumber={3}&storeId={4}&langId={5}`,
  PRODUCT_RECENTLY_VIEWED_DETAILS:
    ' /wcs/resources/store/{0}/v1/products-no-cache/recently-viewed/@self?langId={1}&pageSize={2}',
  PRODUCT_RECENTLY_VIEWED_DETAILS_LUCID: `${BFF_PREFIX}api/product/summary?locale={0}&storeIdentifier={1}&storeId={2}`,
  PRODUCT_RECENTLY_VIEWED_DETAILS_NO_PAGESIZE:
    '/wcs/resources/store/{0}/v1/products-no-cache/recently-viewed/@self?langId={1}',
  MARKETING_PRODUCT_DETAILS:
    '/wcs/resources/store/{0}/v1/products/byPartNumbers?partNumbers={1}&langId={2}&orderBy={3}&pageSize={4}&pageNumber={5}',
  PENDING_QUOTE_APPROVE:
    '/wcs/resources/store/{0}/dealer-customer/pending-quotes/@self/approve-quote?langId={1}',
  PENDING_QUOTES_LIST_URL_DCN:
    '/wcs/resources/store/{0}/dealer-customer/pending-quotes/@self?dcn={1}',
  PENDING_QUOTES_LIST_URL:
    '/wcs/resources/store/{0}/dealer-customer/pending-quotes/@self',
  PENDING_QUOTES_DELETE_URL:
    '/wcs/resources/store/{0}/dealer-customer/pending-quotes/@self/delete-quote/{1}',
  PENDING_QUOTES_EDIT_URL:
    '/wcs/resources/store/{0}/xorder/edit-quote?id={1}&langId={2}',
  PENDING_QUOTES_STOP_EDIT_URL:
    '/wcs/resources/store/{0}/xorder/stop-edit-quote?id={1}&langId={2}',
  REQUIRED_FIELDS: `/wcs/resources/store/{0}/requiredFields/{1}?langId={2}`,
  PLP_URL_TOKEN: '/wcs/resources/store/{0}/seo/urltoken/byCategory?token={1}',
  PLP_DETAILS: `${BFF_PREFIX}api/product/list`,
  ORDER_CONFIRMATION_EMAIL_NOTIFICATION: `/wcs/resources/store/{0}/catemailnotification/sendOrderConfirmationEmail?langId={1}`,
  ORDER_EXPORT_LINK: `/wcs/resources/store/{0}/export/order?langId={1}&fileName={2}&orderId={3}&format={4}&delim=~&fromPage={5}`,
  ORDER_EXPORT_COMPLIANCE: `/wcs/resources/xorder/v2/orderExportCompliance?storeId={0}&langId={1}&shippingCountry={2}&orderId={3}`,
  ORDER_HISTORY_SERVICE_URL: `OrderHisotryCmd`,
  ORDER_HISTORY_FILTER_INFO_URL: `/wcs/resources/store/{0}/orderHistory/filterInfo/@self?langId={1}&catalogId={2}`,
  ORDER_HISTORY_EQUIPMENT_URL: `/wcs/resources/store/{0}/associated_dcn_info`,
  ORDER_HISTORY_REORDER:
    '/wcs/resources/store/{0}/orderhistory?responseFormat=json&langId={1}',
  ORGANIZATIONS_DEALER_CUSTOMER_NUMBER: `/wcs/resources/store/{0}/organization`,
  ORGANIZATIONS_USER_STATUS_CHANGE: `/wcs/resources/store/{0}/person/{1}`,
  ORGANIZATIONS_USERS_SEARCH: `/wcs/resources/store/{0}/xperson`,
  ORGANIZATIONS_USERS_INFO: `/wcs/resources/store/{0}/xperson/{1}`,
  ORGANIZATIONS_USER_ROL_ASSIGN: `/wcs/resources/store/{0}/person/{1}`,
  ORGANIZATIONS_USERS_USER_DETAILS_SEARCH: `/wcs/resources/store/{0}/xperson/{1}?profileName=IBM_User_Registration_Details`,
  QUOTE_PDF_EXPORT_LINK:
    '/wcs/resources/store/{0}/export/quote-pdf?catalogId={1}&orderId={2}&currentTimeZone={3}&langId={4}',
  ORDER_DETAIL: `/wcs/resources/store/{0}/xorder/byOrderId?orderId={1}`,
  ORDERS_TO_APPROVE: `/wcs/resources/store/{0}/cal-approvals`,
  ORDER_CONFIRMATION_EXPORT_LINK: `/wcs/resources/store/{0}/xorder/export?orderId={1}&format={2}&langId={3}`,
  ORDERS_TO_APPROVE_STATUS_CHANGE: `/wcs/resources/store/{0}/cal-approvals/@self/updateOrderApprovalStatus`,
  ORDERS_TO_APPROVE_ORDER_DELETE: `/wcs/resources/store/{0}/cal-approvals/deleteApprovalOrder`,

  ORDER_TOTAL:
    '/wcs/resources/xorder/v2/orderTotals?storeId={0}&langId={1}&orderId={2}',
  ORDERS_TO_APPROVE_PENDING_COUNT: `/wcs/resources/store/{0}/xMyAccountNav/numberPendingOrders`,
  ORDERS_UPDATE_LINE_ITEM_NOTES_URL: `/wcs/resources/store/{0}/xorder/@self/update-line-item-details?langId={1}`,
  ORDERS_USER_INFORMATION: `/wcs/resources/store/{0}/requisition_list/{1}`,

  PRODUCT_FITMENT_CHECK: '/wcs/resources/store/{0}/v1/products/fitment-check',
  PRODUCT_SCHEMATICS:
    '/wcs/resources/store/{0}/v1/products/{1}/diagrams?partNumber={2}&equipmentSerialNumber={3}&langId={4}',
  PRODUCT_SCHEMATICS_IMAGE:
    '/webapp/wcs/stores/servlet/imageProcess?catalogId={0}&storeId={1}&imageURL={2}',
  // REGISTRATION
  REGISTRATION_B2C_URL: `/wcs/resources/store/{0}/xperson/pre-register?langId={1}`,
  DEEPLINK_UPDATE_REGISTRATION_URL: `/wcs/resources/store/{0}/person/@self?responseFormat=json&action=updateUserRegistration`,
  SAVED_LISTS_ACCOUNT_SECTION: `/AjaxLogonForm?myAcctMain=1&route=RequisitionListdisplayView&catalogId={1}&langId={2}&storeId={0}`,
  RETURN_REQUEST_SUBMIT: '/wcs/resources/store/{0}/xorder/returns?langId={1}',
  SAVED_LIST_ADD_ELEMENT_URL: `/wcs/resources/store/{0}/fol/requisition_list?action=addConfiguration`,
  SAVED_LIST_CREATE_NEW_LIST_URL: `/wcs/resources/store/{0}/requisition_list`,
  SAVED_LIST_DETAIL_URL: `/AjaxLogonForm?catalogId={0}&langId={1}&storeId={2}&editable=true&createdBy={3}&requisitionListId={4}&requisitionListName={5}&route={6}`,
  SAVED_LIST_DETAILS_URL: `/webapp/wcs/stores/servlet/RequisitionListsView`,
  SAVED_LIST_URL: `/wcs/resources/store/{0}/fol/requisition_list`,
  // SAVED_LIST_DETAIL_GET endpoint should be replaced with XORDER_BY_ID endpoint when available
  SAVED_LIST_DETAIL_GET: `/wcs/resources/store/{0}/xorder/byOrderId/?langId={1}&orderId={2}`,
  SAVED_LIST_DUPLICATE_LIST_URL: `/webapp/wcs/stores/servlet/AjaxRESTRequisitionListCopy`,
  SAVED_LIST_DELETE_LIST_URL: `/webapp/wcs/stores/servlet/AjaxRESTRequisitionListDelete`,
  SAVED_LIST_ADD_TO_CART_URL: `/webapp/wcs/stores/servlet/AjaxCATSourceOfSupplyUpdate`,
  SAVED_LIST_UPLOAD_LIST_URL: `/webapp/wcs/stores/servlet/AjaxCATCreateRequisitionListUpload`,
  CREATE_SAVED_LIST: `/wcs/resources/store/{0}/fol/requisition_list`,
  SAVED_LIST_ADD_ITEMS: `/wcs/resources/store/{0}/fol/requisition_list/addItem?langId={1}&catalogId={2}`,
  SAVED_LIST_DETAIL_UPDATE_QUANTITY:
    '/wcs/resources/store/{0}/requisition_list?action=updateItem',
  SAVED_LIST_UCID_LIST:
    '/wcs/resources/store/{0}/dealer-customer/ucid/search?pageSize={1}',
  SAVED_LIST_SHARE_UCID_LIST:
    '/wcs/resources/store/{0}/dealer-customer/ucid/sharedList',
  SAVED_LIST_CSR_UPDATE: `${BFF_PREFIX}api/shoppingLists/{0}/copy-csr?storeId={1}&locale={2}`,
  SET_GUEST_IDENTITY:
    '/wcs/resources/store/{0}/guestidentity?updateCookies=true',
  SELECT_DEALER:
    '/wcs/resources/store/{0}/dealer-customer/{1}/entitlements?langId={2}',
  SELECT_STORE:
    '/wcs/resources/store/{0}/locations/dealer/select-store?langId={1}',
  SEND_ORDER_INFO_TO_SALESFORCE:
    '/wcs/resources/xorder/v2/salesForceOrderInfo?storeId={0}&langId={1}',
  SEND_EMAIL_NOTIFICATION_BAR_CODE_PAYMENT:
    '/wcs/resources/store/{0}/xorder/emailPaymentCode?orderId={1}',
  SERIAL_VALIDATION_URL:
    'equipmentValidation?searchTerm={0}&nickName={1}&salesModel={2}&storeId={3}&machineFamily={4}&uniqueIndex={5}&isSolrSkip={6}',
  LUCID_SERIAL_PREFIX_SUGGESTIONS: `${BFF_PREFIX}api/equipment/serial-prefix-by-modal?locale={0}&storeIdentifier={1}&salesModel={2}`,
  SERIAL_PREFIX_SUGGESTIONS:
    '/wcs/resources/store/{0}/equipment/serial-prefix-by-model?salesModel={1}&productId={2}&langId={3}&storeId={4}&responseFormat=json',
  STORE_LOCATIONS_URL:
    '/wcs/resources/store/{0}/dealer/storeLocations?langId={1}',
  STORE_FEATURE_FLAGS: '/wcs/resources/store/{0}/storefeatures?t=2',
  TELEPHONYINFO_COUNTRY_CODE_URL: `/wcs/resources/store/{0}/locations/getCustCareTelephonyInfo?countryCode={1}`,
  TRACK_ORDER_INFO_URL:
    '/OrderShipmentDetailsView?fromOrderHistorySearchPage=false&storeId={0}&langId={1}&orderId={2}&dbsOrderId={3}',
  LOAD_MACHINE_DETAILS: '/webapp/wcs/stores/servlet/machineDetails',
  MACHINE_DETAILS_LUCID: '/wcs/resources/store/{0}/machineDetails',
  LOAD_MACHINE_CATEGORIES: '/webapp/wcs/stores/servlet/machineCategories',
  LOAD_SSRO_URLFORMODEL:
    '/wcs/resources/store/{0}/selfServiceOptions/bySalesModel?salesModel={1}',
  LOAD_SSRO_URLFORSERIALNUMBER:
    '/wcs/resources/store/{0}/selfServiceOptions/bySerialNumber',
  STORE_LOCATION_INFO: `/wcs/resources/store/{0}/locations/{1}?langId={2}&includeCutoffTime={3}`,
  EMAIL_CART: `/wcs/resources/store/{0}/xorder/@self/email-cart?langId={1}`,
  EMAIL_NOTIFICATION: `/wcs/resources/store/{0}/catemailnotification?responseFormat=json&langId={1}`,
  RECENT_ORDER_HISTORY_URL: `/webapp/wcs/stores/servlet/OrderHisotryCmd?storeId={0}&catalogId={1}&langId={2}&showOnlyMyOrders=on&ORDER_HISTORY_TAB_RADIO1=PCC&orderHistoryStatus=ALL&orderHistoryStartDate=&orderHistoryEndDate=&pageSize=8&pageNumber=1`,
  PRICE_AVAILABILITY_URL: `/wcs/resources/store/{0}/getDealerPriceAndAvailability?langId={1}`,
  PRICE_AVAILABILITY_DEALER_URL: `/wcs/resources/store/{0}/dealer/priceAndAvailability?locale={1}&isQuickOrder=true`,
  TOTAL_PRICE__URL: `/wcs/resources/store/{0}/subtotal`,
  BUNDLES_ADD_TO_CART_URL: `/wcs/resources/store/{0}/cart?langId={1}`,
  BUNDLES_LOAD_CART_ITEMS_URL: `/wcs/resources/store/{0}/cart/@self`,
  WARRANTY_RETURN_POLICY_URL: `/webapp/wcs/stores/servlet/warrantyReturnJson?catalogId={0}&langId={1}&storeId={2}`,
  RO_GET_INFO_URL: `/wcs/resources/store/{0}/selfServiceOptions/bySelfServiceOptionId?ssId={1}&serialNumber={2}&model={3}&serialNoBegin={4}&serialNoEnd={5}&unit={6}&isPM={7}&isCATKit={8}&hash={9}`,
  SHOPPING_CART_IMPORT_SUMMARY_MODAL:
    '/wcs/resources/store/{0}/xorder/@self?langId={1}',
  USER_INFO_URL: `/wcs/resources/store/{0}/getUserInfo?langId={1}`,
  USER_GET: `/wcs/resources/store/{0}/person/@self`,
  USER_UPDATE: `/wcs/resources/store/{0}/userPreferences?requestType=json&responseFormat=json`,
  CMC_MARKETING_LAYOUT_DATA_URL: `/wcs/resources/store/{0}/page_design?q=byObjectIdentifier&objectIdentifier={1}&pageGroup=Content&deviceClass=web`,
  WESTPAC_SUPPORT_SCRIPT:
    'https://api.quickstream.support.qvalent.com/rest/v1/quickstream-api-1.0.min.js',
  WESTPAC_SCRIPT:
    'https://api.quickstream.westpac.com.au/rest/v1/quickstream-api-1.0.min.js',
  PAYZEN_SCRIPT:
    'https://static.payzen.eu/static/js/krypton-client/V4.0/stable/kr-payment-form.min.js',
  XORDER_PRICE_AVAILABILITY_URL:
    '/wcs/resources/store/{0}/xorder/@self/price-availability?langId={1}',
  UPDATE_ORDER_LINE_ITEM:
    '/wcs/resources/store/{0}/xorder/@self/update-order-item?langId={1}',
  UPDATE_ORDER_TOTAL:
    '/wcs/resources/store/{0}/xorder/@self/update-order-totals?langId={1}',
  UPDATE_USER_INFO: '/wcs/resources/store/{0}/updateUserInfo',
  USER_APPROVAL_AMOUNT_BY_ORGANIZATION: `/wcs/resources/store/{0}/cal-approvals/approval_limit/{1}/byMemberId/{2}`,
  SET_USER_APPROVAL_AMOUNT_BY_ORGANIZATION: `/wcs/resources/store/{0}/cal-approvals/approval_limit/{1}/byMemberId/{2}`,
  XORDER_DELETE_CART: `/wcs/resources/store/{0}/xorder/@self/delete?langId={1}`,
  XORDER_BY_ID: `/wcs/resources/store/{0}/xorder/byOrderId`,
  XORDER_INVOICE_DETAIL: `/wcs/resources/store/{0}/invoices/invoiceList/byId/{1}?orderNumber={2}`,
  OFFLINE_ORDER_DETAIL: `/wcs/resources/store/{0}/xorder/byOfflineOrderId`,
  XORDER_HEADER_INFO:
    '/wcs/resources/xorder/v2/orderHeaderInfo?storeId={0}&langId={1}&orderId={2}',
  PROCEED_TO_CHECKOUT: `/wcs/resources/store/{0}/xorder/@self/checkout?langId={1}`,
  SUPPORT_OPTIONS_EMAIL_US_LINK:
    'https://cat-crm.force.com/HelpCenter/s/contactsupport?language={0}',
  SUPPORT_OPTIONS_EMAIL_US_LINK_DEV:
    'https://catcrm--crmiuat.sandbox.my.site.com/HelpCenter/s/contactsupport?language={0}',
  DELETE_LINE_ITEM: `/wcs/resources/store/{0}/cart/@self/delete_order_item?langId={1}`,
  DELETE_LINE_ITEM_BUNDLES: `/wcs/resources/store/{0}/xorder/@self/delete-bundles`,
  VALIDATE_CART_ITEMS: `/wcs/resources/store/{0}/xorder/@self/validate-cart-items?langId={1}`,
  LOGIN_B2C_URL: `/wcs/resources/store/{0}/b2c/logonurl?langId={1}&pageRedirectUrl={2}&loginFromHomePage={3}`,
  DEALER_CUSTOMER_DCN: `/wcs/resources/store/{0}/dealer-customer/dcn-assoc-customers/@self`,
  CATEGORIES_RECENTLY_PURCHASED: `/wcs/resources/store/{0}/v1/categories/recently-purchased-categories/@self?langId={1}&responseFormat=json`,
  CORE_TRACKING_RESULTS: `/wcs/resources/store/{0}/coreTracking/getSearchResult?langId={1}&catalogId={2}`,
  XORDER_RETURN_HISTORY_BY_ID: `/wcs/resources/store/{0}/xorder/returns`,
  CSR_CUSTOMER_SEARCH: `/wcs/resources/store/{0}/csr/users`,
  CSR_ORDERS_SEARCH: `/wcs/resources/store/{0}/csr/orders`,
  CSR_TABLE_SELECT_ENDPOINT:
    '/webapp/wcs/stores/servlet/userSetInSession?newUserId={0}&storeId={1}',
  CSR_SET_USER_IN_SESSION: `/wcs/resources/store/{0}/person/@self?forUserId={1}`,
  CSR_TABLE_RESET_CUSTOMER_SELECTION:
    '/webapp/wcs/stores/servlet/userResetInSession',
  ORDER_TRACKING_INFO: `/wcs/resources/store/{0}/xorder/trackingInfo/byOrderId?langId={1}&orderId={2}&responseFormat=json&sortBy=asc`,
  CONTROL_CENTER_GET_NOTIFICATIONS:
    '/wcs/resources/store/{0}/notifications/@self',

  GET_NOTIFICATIONS_WITH_DCN: `/wcs/resources/store/{0}/notifications/@self?pageNumber={1}&pageSize={2}&dcn={3}`,
  GET_NOTIFICATIONS:
    '/wcs/resources/store/{0}/notifications/@self?pageNumber={1}&pageSize={2}&langId={3}',
  PATCH_NOTIFICATIONS: '/wcs/resources/store/{0}/user/notifications',
  GET_XNOTIFICATIONS: '/wcs/resources/store/{0}/user/notifications/@self',
  GET_USER_NOTIFICATIONS:
    '/wcs/resources/store/{0}/user/notifications/@self?langId={1}&timeOffset={2}',
  GET_LOCATION_LIST_API: 'wcs/resources/store/{0}/locations/{1}?langId={2}',
  LEAVE_FEEDBACK_NO_CTRL_CENTER_START_PAGE:
    'https://caterpillar.co1.qualtrics.com/jfe/form/SV_8B3sTWhXDAtL5VI',
  LEAVE_FEEDBACK_CTRL_CENTER_START_PAGE:
    'https://caterpillar.co1.qualtrics.com/jfe/form/SV_eFjtLNwnkyYIkSy',
  PRODUCT_SEARCH: `${BFF_PREFIX}api/search`,
  XORDER_FULFILLMENT: `/wcs/resources/xorder/v2/fulfillmentInfo?storeId={0}&langId={1}&orderId={2}`,
  XORDER_ATTRIBUTES:
    '/wcs/resources/xorder/v2/orderAttributes?storeId={0}&langId={1}&orderId={2}',
  XORDER_EDIT_ORDER:
    '/wcs/resources/xorder/v2/calInfo?storeId={0}&catalogId={1}&langId={2}&orderId={3}',
  XORDER_ITEMS_HEADER:
    '/wcs/resources/xorder/v2/orderItemHeaderInfo?storeId={0}&langId={1}&orderId={2}',
  XORDER_CAL_INFO:
    '/wcs/resources/xorder/v2/calInfo?storeId={0}&langId={1}&orderId={2}',
  XORDER_BILLING: `/wcs/resources/xorder/v2/paymentInfo?storeId={0}&langId={1}&orderId={2}`,
  MEQ_AUTO_SAVE: '/wcs/resources/store/{0}/equipmentAutoSave',
  FITMENT_CHECK: `${BFF_PREFIX}api/product/fitment/check`,
  CSR_TRACKING_INFO_LINK:
    '/AjaxLogonForm?langId={0}&storeId={1}&catalogId={2}&orderId={3}&route=OrderStatus',
  CART_IMPORT_ENABLED:
    '/wcs/resources/store/21801/deeplink/cart-import-page-access-control',

  XORDER_ITEM_INFO:
    '/wcs/resources/xorder/v2/orderItemInfo?storeId={0}&langId={1}&orderId={2}',
  XORDER_SAVE_PAYMENT_METHOD: `/wcs/resources/xorder/v2/paymentInfo/paymentMethod?storeId={0}&langId={1}&orderId={2}`,
  XORDER_PRICE_AVAILABILITY_TOTALS: `/wcs/resources/xorder/v2/orderItemPriceAvailabilityTotals?storeId={0}&langId={1}&orderId={2}&refreshPrices={3}`,
  PAY_U_TERMS_AND_CONDITIONS:
    'https://brazil.payu.com/wp-content/uploads/sites/3/2020/04/termos-e-condicoes-gerais-de-uso-para-compradores-payu.pdf',
  PRODUCT_DISPLAY_PDP_LINK: '/webapp/wcs/stores/servlet',
  VERIFY_DEALER_ASSOCIATION:
    '/wcs/resources/store/{0}/csr/verify-dealer-association?userId={1}',
  GET_OMM_CATALOG: '/api/omm/catalogs',
  GET_OMM_CONTENT: '/api/omm/contents',
  GET_LUCID_PRODUCT_CATEGORIES: '/api/product/categories',
  GET_SR_COMMERCIAL_OFFERS_DETAILS: `${BFF_PREFIX}api/commercialOffers/{0}?langId={1}&storeId={2}`,
  EQUIPMENT_SPECIFIC_PART_CATEGORIES:
    '/wcs/resources/store/{0}/alp/categories/bySelectedEquipment',
  INVOICES_SEARCH: `/wcs/resources/store/{0}/invoices/invoiceList/@self?dateFrom={1}&dateTo={2}&storeNumber={3}&partNumber={4}&langId={5}`,
  PIX_PAYMENT_STATUS:
    '/wcs/resources/store/{0}/pixpayment/paymentStatus?orderId={1}&langId={2}',
  ORDER_STATUS_INQUIRY: '{0}/OrderStatusInquiry',
  XORDER_PO_ATTACHMENT: '/wcs/resources/xorder/v2/purchaseOrderAttachment',
  ORDER_TO_APPROVE_EXPORT_LINK: `/wcs/resources/store/{0}/export/ordersApprove?langId={1}&fileName={2}`,
  PENDING_QUOTES_EXPORT_LINK: `/wcs/resources/store/{0}/export/quote?langId={1}&fileName={2}&dcn={3}`,
  GET_SOCIAL_LINKS:
    '/wcs/resources/store/{0}/footer/socialMediaLinks?langId={1}&responseFormat=json',
  GET_TAX_DETAIL:
    '/wcs/resources/store/{0}/dealer-customer/getUserTaxInfo?userId={1}',

  GET_ON_BEHALF_USER_INFO: '/wcs/resources/store/{0}/csr/onBehalfUserInfo/{1}',
  GUEST_ORDER_NUM_EMAIL:
    '/wcs/resources/store/{0}/xorder/lookupByDocNumAndEmail?catalogId={1}&langId={2}&docNum={3}&email={4}',
  GUEST_XORDER_BY_ID:
    '/wcs/resources/store/{0}/xorder/byDocNumAndEmail?&docNum={1}&email={2}',
  GUEST_ORDER_TRACKING_INFO:
    ' /wcs/resources/store/{0}/xorder/trackingInfo/byDocNumAndEmail?langId={1}&docNum={2}&email={3}',
  GET_MAINTENANCE_YOUTUBE_VIDEOS_META:
    'https://youtube.com/oembed?url={0}&format=json',
  SERVICE_RECOMMENDATION_OFFERS_SEARCH: `${BFF_PREFIX}api/commercialOffers?langId={0}&storeId={1}`,

  GET_USER_CVR_ENROLLMENT_STATUS:
    '/wcs/resources/store/{0}/catVantage/enrollmentStatus',
  CVR_ENROLL_USER: '/wcs/resources/store/{0}/catVantage/enroll?langId={1}',
  GET_CAT_VANTAGE_REWARDS_BALANCE:
    '/wcs/resources/store/{0}/catVantage/rewardsBalance',
  APPLY_CAT_CREDITS_TO_ORDER:
    '/wcs/resources/store/{0}/catVantage/applyRewards?langId={1}',
  REQUEST_SERVICE_DRAFT_URL:
    '/wcs/resources/store/{0}/pccServiceRequests/draftRequestService',
  REQUEST_SERVICE_SUBMIT_URL:
    '/wcs/resources/store/{0}/pccServiceRequests/submitRequestService',
  GET_EXISTING_REQUEST_SERVICE:
    '/wcs/resources/store/{0}/pccServiceRequests/searchRequestService',
  ECT_MESSAGE_API: `${BFF_PREFIX}api/ect/v1/dealers/{0}/messages?type={1}&locale={2}&storeId={3}&langId={4}&espotName={5}`,
  PRIVACY_POLICY_HEADLESS_URL: `${BFF_PREFIX}{0}/legal/privacy-policy`,
  TERMS_CONDITIONS_HEADLESS_PAGE_URL: `${BFF_PREFIX}{0}/legal/terms-and-conditions`,
  PRIVACY_NOTICE_HEADLESS_URL: `${BFF_PREFIX}{0}/legal/legal-notices#privacy`,
  TERMS_OF_USE_HEADLESS_URL: `${BFF_PREFIX}{0}/legal/legal-notices#legal`,
  HELIOS_FALLBACK_LANGUAGE: `${BFF_PREFIX}api/heliosFallBackLanguage?langId={0}&languageIdBFF=null`,
  SERVICE_RECOMMENDATION_INSTANCE_SEARCH: `${BFF_PREFIX}api/commercialOffers/equipmentInstanceSearch?langId={0}&storeId={1}`
};

export default endpoints;

export const getMockEndpoint = (url = '') => {
  // Get just the path and throw away the query params. MSW doesn't like
  // mock routes to contain query params
  const [path] = url.split('?');
  // using named params to replace all url params without context.
  // Using 3 as it's overkill and we don't have any URLs with this many in the url path
  return replaceTokensInString(path, ':param1', ':param2', ':param3');
};
