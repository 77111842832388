import cx from 'classnames';
import declarations from '../address.proptypes';
import { useSelector } from 'react-redux';
import {
  SHOP_ONLINE_TYPE,
  USER_TYPE_GUEST
} from '@app/constants/commonConstants';
import PropTypes from 'prop-types';
const Address = ({
  isFullAddress = true,
  address1 = '',
  address2 = '',
  city = '',
  className,
  country = '',
  email = '',
  firstName = '',
  lastName = '',
  locale,
  middleName = '',
  phone = '',
  state = '',
  zipCode = '',
  shipmentType
}) => {
  const textClassName = cx('exclude-global mb-0', className);
  const spacingForLastName = !!firstName || !!middleName ? ' ' : '';
  const spacingForMiddleName = !!firstName ? ' ' : '';
  const firstNameDisplay = firstName ? firstName : '';
  const middleNameDisplay = middleName
    ? `${spacingForMiddleName}${middleName}`
    : '';
  const lastNameDisplay = lastName ? `${spacingForLastName}${lastName}` : '';

  const nameDisplayJsx = (firstName || middleName || lastName) && (
    <p
      className={textClassName}
      data-cs-mask
    >{`${firstNameDisplay}${middleNameDisplay}${lastNameDisplay}`}</p>
  );
  const userType = useSelector(s => s.common?.userType);
  const isGuestUser = userType === USER_TYPE_GUEST;
  const isAddressDisplay = !(shipmentType === SHOP_ONLINE_TYPE && isGuestUser);

  const getArabicFormat = () => (
    <>
      {nameDisplayJsx}
      {isAddressDisplay && address1 && (
        <p className={textClassName} data-cs-mask>
          {address1}
        </p>
      )}
      {isAddressDisplay && address2 && (
        <p className={textClassName} data-cs-mask>
          {address2}
        </p>
      )}
      {city && (
        <p className={cx(textClassName, 'text-capitalize')} data-cs-mask>
          {city}
        </p>
      )}
      {state && (
        <p className={cx(textClassName, 'text-capitalize')} data-cs-mask>
          {state}
        </p>
      )}
      {zipCode && (
        <p className={textClassName} data-cs-mask>
          {zipCode}
        </p>
      )}
      {country && (
        <p className={cx(textClassName, 'text-capitalize')} data-cs-mask>
          {country}
        </p>
      )}
      {phone && isFullAddress && (
        <p className={textClassName} data-cs-mask>
          {phone}
        </p>
      )}
      {!isGuestUser && email && isFullAddress && (
        <p className={textClassName} data-cs-mask>
          {email}
        </p>
      )}
    </>
  );
  const getEastAsiaFormat = () => {
    const eaSpacingForFirstName = !!lastName ? ' ' : '';
    const eaFirstNameDisplay = firstName
      ? `${eaSpacingForFirstName}${firstName}`
      : '';
    const eaLastNameDisplay = lastName ? `${lastName}` : '';
    return (
      <>
        {(firstName || lastName) && (
          <p
            className={textClassName}
            data-cs-mask
          >{`${eaLastNameDisplay}${eaFirstNameDisplay}`}</p>
        )}
        {(country || zipCode) && (
          <p className={cx(textClassName, 'text-capitalize')} data-cs-mask>{`${
            country || ''
          } ${zipCode || ''}`}</p>
        )}
        {(state || city) && (
          <p className={cx(textClassName, 'text-capitalize')} data-cs-mask>{`${
            state ? `${state} ` : ''
          }${city || ''}`}</p>
        )}
        {isAddressDisplay && address1 && (
          <p className={textClassName} data-cs-mask>
            {address1}
          </p>
        )}
        {isAddressDisplay && address2 && (
          <p className={textClassName} data-cs-mask>
            {address2}
          </p>
        )}
        {phone && isFullAddress && (
          <p className={textClassName} data-cs-mask>
            {phone}
          </p>
        )}
        {!isGuestUser && email && isFullAddress && (
          <p className={textClassName} data-cs-mask>
            {email}
          </p>
        )}
      </>
    );
  };
  const getEuropeanFormat = () => (
    <>
      {nameDisplayJsx}
      {isAddressDisplay && address1 && (
        <p className={textClassName} data-cs-mask>
          {address1}
        </p>
      )}
      {isAddressDisplay && address2 && (
        <p className={textClassName} data-cs-mask>
          {address2}
        </p>
      )}
      {(city || zipCode) && (
        <p className={cx(textClassName, 'text-capitalize')} data-cs-mask>{`${
          city || ''
        }${zipCode ? ` ${zipCode}` : ''}`}</p>
      )}
      {state && (
        <p className={cx(textClassName, 'text-capitalize')} data-cs-mask>
          {state}
        </p>
      )}
      {country && (
        <p className={cx(textClassName, 'text-capitalize')} data-cs-mask>
          {country}
        </p>
      )}
      {phone && isFullAddress && (
        <p className={textClassName} data-cs-mask>
          {phone}
        </p>
      )}
      {!isGuestUser && email && isFullAddress && (
        <p className={textClassName} data-cs-mask>
          {email}
        </p>
      )}
    </>
  );

  const getDefaultLocaleAddressFormat = () => (
    <>
      {nameDisplayJsx}
      {isAddressDisplay && address1 && (
        <p className={textClassName} data-cs-mask>
          {address1}
        </p>
      )}
      {isAddressDisplay && address2 && (
        <p className={textClassName} data-cs-mask>
          {address2}
        </p>
      )}
      {(city || state || zipCode) && (
        <p className={cx(textClassName, 'text-capitalize')} data-cs-mask>{`${
          city || ''
        }${state ? `, ${state}` : ''}${zipCode ? ` ${zipCode}` : ''}`}</p>
      )}
      {country && (
        <p className={cx(textClassName, 'text-capitalize')} data-cs-mask>
          {country}
        </p>
      )}
      {phone && isFullAddress && (
        <p className={textClassName} data-cs-mask>
          {phone}
        </p>
      )}
      {!isGuestUser && email && isFullAddress && (
        <p className={textClassName} data-cs-mask>
          {email}
        </p>
      )}
    </>
  );

  const localeFormatMap = {
    ar_EG: getArabicFormat,
    ja_JP: getEastAsiaFormat,
    ko_KR: getEastAsiaFormat,
    zh_CN: getEastAsiaFormat,
    zh_TW: getEastAsiaFormat,
    de_DE: getEuropeanFormat,
    es_ES: getEuropeanFormat,
    fr_FR: getEuropeanFormat,
    it_IT: getEuropeanFormat,
    pl_PL: getEuropeanFormat,
    ro_RO: getEuropeanFormat,
    ru_RU: getEuropeanFormat
  };

  const getFormatForLocale = () => {
    const getFormat = localeFormatMap[locale];
    return getFormat ? getFormat() : getDefaultLocaleAddressFormat();
  };

  return getFormatForLocale(locale);
};

Address.propTypes = {
  ...declarations.addressShape,
  shipmentType: PropTypes.string
};
export default Address;
